import { Component, OnInit } from '@angular/core';
import { LottoService } from '../../../_services/lotto.service';
import { ClubService } from '../../../_services/club.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-create-lotto',
  templateUrl: './create-lotto.component.html',
  styleUrls: ['./create-lotto.component.scss']
})
export class CreateLottoComponent implements OnInit {

  drawDay;
  drawTime;
  prizeAmount;
  numDrawNumbers = '24';
  prizeAmountIncreasePerDraw;
  numPairsOfNumbers = '4';
  maxPrizeAmount = '20000';
  maxNumDraws = '1';
  linePrice;
  numLuckyDips;
  luckyDipPrizeAmount;
  clubId;
  errorMessage = [];

  constructor(
    private lottoService: LottoService,
    private dialogRef: MatDialogRef<CreateLottoComponent>,
    private clubService: ClubService) { }

  ngOnInit() {
    this.clubId = this.clubService.getClub().id;
  }

  createLotto() {
    this.errorMessage = [];
    this.lottoService.createLotto({
      clubId: this.clubId,
      drawDay: this.drawDay,
      drawTime: this.drawTime,
      prizeAmount: this.prizeAmount,
      prizeAmountIncreasePerDraw: this.prizeAmountIncreasePerDraw,
      numDrawNumbers: this.numDrawNumbers,
      numPairsOfNumbers: this.numPairsOfNumbers,
      linePrice: this.linePrice,
      maxNumDraws: this.maxNumDraws,
      maxPrizeAmount: this.maxPrizeAmount,
      numLuckyDips: this.numLuckyDips,
      luckyDipPrizeAmount: this.luckyDipPrizeAmount
    }).subscribe(res => {

      if (res.errors) {

        Object.values(res.errors).forEach(value => {
          this.errorMessage.push(value[0]);
        });
      } else {
        this.dialogRef.close();
      }
    });
  }

}
