import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { ClubService } from '../../../_services/club.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Inject } from '@angular/core';
import { PaymentService } from '../../../_services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipService } from '../../../_services/membership.service';
import { PayAmountComponent } from '../../member-components/pay-amount/pay-amount.component';



@Component({
  selector: 'app-pay-monthly-platform-fee',
  templateUrl: './pay-monthly-platform-fee.component.html',
  styleUrls: ['./pay-monthly-platform-fee.component.scss']
})
export class PayMonthlyPlatformFeeComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('cardInfo', {static: true}) cardInfo: ElementRef;

  bankAccountEnabled;
  errorMessage;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  clubCurrency: string;
  basicPaymentAmount = 0;
  totalPaymentAmount = 0;
  calculatedFee = 0;
  stripeFeeTaken = 0;
  vatOnStripeFee = 0;
  displayFee = false;

  stripePercentage = 0.014;
  platformAndStripePercentage = 0.025; // default it to UK & Ire rate
  platformPercentage = 0.011; // 1.1%
  stripePercentageEuroUK = 0.014; // 1.4% - VAT (21% or 23% in IRE) must then be calculated on this for irish clubs
  stripePercentageNonEuroUK = 0.029;
  platformAndStripePercentageEuroUK = 0.025; // 2.5% (1.1% + 1.4%) - eg. £100 / 0.97 = £103.09
  platformAndStripePercentageNonEuroUK = 0.039; // 3.9% (1% + 2.9%) - eg. £100 / 0.97 = £103.09
  transactionFee = 0.25; // 25 cents - In Ireland VAT must then be calculated on this for irish clubs
  irishVatRate = 0.21; // 21% or 23% - current irish vat

  isProcessing = false;
  displayPaymentForm = true;
  purchaseDetails;
  paymentIntent = { client_secret: '' };

  discountAmount = 0;
  discountName = '';

  selectedPaymentTimeframe = '';
  numPayments = '1';

  constructor(
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private clubService: ClubService,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<PayAmountComponent>,
    private spinner: NgxSpinnerService,
    private membershipService: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.clubCurrency = this.clubService.getClubCurrency(this.data.currentClub.country);
    this.basicPaymentAmount = this.data.paymentAmountSelected;
    this.selectedPaymentTimeframe = this.data.selectedPaymentTimeframe;

    if (this.data.currentClub.country === 'Ireland' || this.data.currentClub.country === 'Ireland') {
      this.platformAndStripePercentage = this.platformAndStripePercentageEuroUK;
      this.stripePercentage = this.stripePercentageEuroUK;
    } else {
      this.platformAndStripePercentage = this.platformAndStripePercentageNonEuroUK;
      this.stripePercentage = this.stripePercentageNonEuroUK;
    }


    this.stripeFeeTaken = ((this.basicPaymentAmount / (1 - this.stripePercentage))
      + this.transactionFee) - this.basicPaymentAmount;

    if (this.data.currentClub.country === 'Ireland') {
      this.vatOnStripeFee = this.stripeFeeTaken * this.irishVatRate;
    }

    // Useful for debugging...
    // console.log('basicPaymentAmount: ' + this.basicPaymentAmount);
    // console.log('(1 - this.stripePercentage): ' + (1 - this.stripePercentage));
    // console.log('transactionFee: ' + this.transactionFee);

    // console.log('Stripe Percentage: ' + this.stripePercentage);
    // console.log('Stripe Fee taken: ' + this.transactionFee);

    // console.log('Stripe Fee taken: ' + this.stripeFeeTaken);
    // console.log('VAT: ' + this.vatOnStripeFee);
    // console.log('platformAndStripePercentage: ' + this.platformAndStripePercentage);

    // Only calculate fees for amounts requested (events)
    this.calculatedFee =  ((this.basicPaymentAmount / (1 - this.platformAndStripePercentage))
                            + this.transactionFee + this.vatOnStripeFee)
                            - this.basicPaymentAmount;

    this.calculateMembershipDiscounts();

  }

  ngAfterViewInit() {
    this.clubService.getClubFromApi(this.data.currentClub.id).subscribe(res => {
      if (this.data.paymentType === 'amount_requested') {

        if (res.club.payment_request_fee_added === 1) {
          this.displayFee = true;
          this.totalPaymentAmount = this.basicPaymentAmount + this.calculatedFee;
        } else {
          this.totalPaymentAmount = this.basicPaymentAmount;
        }

      } else {
        this.totalPaymentAmount = this.basicPaymentAmount;
      }

      this.checkIfBankAccountConnected(res.club);

      if (this.bankAccountEnabled) {
        this.createPurchaseDetails();
        this.createCard();
      }
    });
  }

  createCard() {
    this.card = this.paymentService.elements.create('card', {hidePostalCode: true});
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  // card validation
  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }


  onSubmit(form: NgForm) {

    this.purchaseDetails.selectedPaymentTimeframe = this.selectedPaymentTimeframe;
    this.purchaseDetails.numPayments = this.numPayments;

    this.spinner.show();
    this.isProcessing = true;
    this.errorMessage = '';
    this.paymentService.stripe.createPaymentMethod('card', this.card).then(result => {
      if (result.error) {
        this.spinner.hide();
        // Display error.message in your UI.
        this.errorMessage = result.error.message;
      } else {
        // Otherwise send paymentMethod.id to your server (see Step 2)
        this.paymentService.createPaymentIntent({ payment_method_id: result.paymentMethod.id }, this.purchaseDetails)
        .subscribe(response => {
          this.handleServerResponse(response);
        });
      }
    });
    this.isProcessing = false;
  }


  handleServerResponse(response) {

    if (response.error) {
      // Show error from server on payment form
      this.spinner.hide();
      // standard error - like card declined etc
      this.errorMessage = response.error;
    } else if (response.requires_action) {
      this.spinner.hide();
      // Use Stripe.js to handle required card action
      this.paymentService.stripe.handleCardAction(
        response.payment_intent_client_secret
      ).then(function(result) {
        if (result.error) {
          // Show error from stripe handleCardAction in payment form
          this.errorMessage = result.error.message;
        } else {
          this.spinner.show();
          this.paymentService.createPaymentIntent({ payment_intent_id: result.paymentIntent.id }, this.purchaseDetails)
          .subscribe(res => {
            this.handleServerResponse(res);
          });
        }
      }.bind(this));
    } else {
        this.spinner.hide();
        this.dialogRef.close('paymentSuccess');
      // The payment has succeeded. Display a success message.
      this.snackBar.open('Payment Completed Sucessfully', 'OK', {duration: 4000});
    }
  }


  createPurchaseDetails() {
    this.purchaseDetails = {
      amount: this.basicPaymentAmount,
      clubId: this.data.currentClub.id,
      paymentType: this.data.paymentType
    };
    if (this.data.paymentType === 'amount_requested') {
     this.purchaseDetails.eventId = 'all';

    } else if (this.data.paymentType === 'shop_purchase') {
      this.purchaseDetails.items_purchased = this.data.cartItems;
      this.purchaseDetails.shippingAddress = this.data.shippingAddress;
      this.purchaseDetails.shopType = this.data.shopType;

      this.purchaseDetails.shippingSelected = this.data.shippingSelected;
      this.purchaseDetails.shopType = this.data.shopType;

    } else if (this.data.paymentType === 'membership') {
      this.purchaseDetails.membersMemberships = this.data.membersMemberships;

    } else if (this.data.paymentType === 'lotto') {
      this.purchaseDetails.lottoPaymentDetails = this.data.lottoPaymentDetails;
    }
  }


  calculateMembershipDiscounts() {
    if (this.data.paymentType === 'membership') {
      this.membershipService.getMembershipDiscounts().subscribe(result => {

        const memberMemberships = this.data.membersMemberships;
        const membershipsCountArray = [];

        memberMemberships.forEach(member => {

          // loop through all the memberships and count them all up
          member.memberGroups.forEach(membershipGroup => {
            if (membershipsCountArray[membershipGroup.membership.id] === undefined) {
              membershipsCountArray[membershipGroup.membership.id] = {
                'count' : 1
              };
            } else {
              membershipsCountArray[membershipGroup.membership.id].count =
                membershipsCountArray[membershipGroup.membership.id].count + 1;
            }
          });
        });

        const validMembershipDiscountArray = [];

        // loop through all the membership discounts and see which ones apply
        // apply the largest discount which was seen
        // record the discount on the reciept
        result.membershipDiscounts.forEach(membershipDiscount => {
          const membershipDiscountItemArray = [];
          let membershipDiscountSumOfItemAmounts = 0;
          let isValidDiscount = true;

            for (const discountItem of membershipDiscount.membership_discount_items) {

              if (typeof membershipsCountArray[discountItem.membership_id] !== 'undefined') {
                // then check if the count is at least 1 or equal to the count of memberships to be purchased
                if (membershipsCountArray[discountItem.membership_id]['count'] > 0 &&
                  discountItem.count <= membershipsCountArray[discountItem.membership_id]['count']) {

                    // then its a valid discount item
                    membershipDiscountSumOfItemAmounts += discountItem.membership.amount * discountItem.count;
                } else {
                    isValidDiscount = false;
                    break;
                }
              } else {
                  isValidDiscount = false;
                  break;
              }
            }

            // check if it can be applied
            // if it can be applied then add it to the valid discounts array
            if (isValidDiscount === true) {
              // Only apply the discount only to the selected items in the membershipDiscount array!!
              const discountAmount = membershipDiscountSumOfItemAmounts - membershipDiscount.amount;
              const validDiscount = {
                discountId: membershipDiscount.id,
                discountAmount,
                discountName : membershipDiscount.name
              };
              validMembershipDiscountArray[validDiscount.discountAmount] = validDiscount;
            }
        });

          // sort the valid discounts array by discount amount
          Object.keys(validMembershipDiscountArray).reduce((accumulator, currentValue) => {
            accumulator[currentValue] = validMembershipDiscountArray[currentValue];
            return accumulator;
          }, {});

          // apply the largest discount possible
          const largestDiscountAvailable = validMembershipDiscountArray.pop();
          if (largestDiscountAvailable) {
            this.discountAmount = largestDiscountAvailable.discountAmount;
            this.discountName = largestDiscountAvailable.discountName;
          }

          // apply any calculated membership discounts
          if (this.discountAmount > 0) {
            this.basicPaymentAmount = this.basicPaymentAmount - this.discountAmount;

            // need to update this at some point
            this.totalPaymentAmount = this.totalPaymentAmount - this.discountAmount;
            this.purchaseDetails.amount = this.basicPaymentAmount;
          }
      });
    }
  }


  checkIfBankAccountConnected(club) {
    if (club.stripe_user_id === null) {
      this.bankAccountEnabled = false;
    } else {
      this.bankAccountEnabled = true;
    }
  }


  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }
}
