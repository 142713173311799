import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatDialog } from '@angular/material';
import { PayAmountComponent } from '../pay-amount/pay-amount.component';
import { MembershipService } from '../../../_services/membership.service';
import { MembershipFormsComponent } from '../membership-forms/membership-forms.component';
import { MiscService } from '../../../_services/misc.service';
import * as moment from 'moment';
import { ClubShopComponent } from '../club-shop/club-shop.component';
import { MemberService } from '../../../_services/member.service';
import { MembershipPaymentOptionsComponent } from '../membership-payment-options/membership-payment-options.component';
import { RequestCancellationComponent } from '../request-cancellation/request-cancellation.component';

@Component({
  selector: 'app-member-home',
  templateUrl: './member-home.component.html',
  styleUrls: ['./member-home.component.scss']
})
export class MemberHomeComponent implements OnInit {

  memberDetails = {
    membersPaymentRequestsAmounts : [],
    membershipsTotalOutstanding: 0,
    paymentRequestsTotalOutstanding: 0,
    payments: [],
    membersOutstandingMembershipGroups: [],
    membersMemberships: [],
    userMemberAdminGroups: Array(Array()),
    emailNotificationsEnabled: true};
  currentClub = { name: '', currency: '', id: 0, pivot: { role: '' }, logo_link: null};
  clubCurrency;
  emailNotificationsEnabled;
  currentUserRole;
  sectionToShow;

  showAccount = true;
  showReceipts = false;
  showShop = false;
  showBookings = false;


  isAdmin;
  isMobile;


  vcPaidMembershipIndex = 0;



  constructor(
    private clubService: ClubService,
    private memberService: MemberService,
    private authService: AuthenticationService,
    private membershipService: MembershipService,
    private miscService: MiscService,
    private dialog: MatDialog) { }

  ngOnInit() {

    this.isMobile = window.matchMedia('only screen and (max-width: 760px)');

    this.currentClub = this.clubService.getClub();
    this.clubCurrency = this.clubService.getClubCurrency();
    this.getMemberDetails();
    this.currentUserRole = this.currentClub.pivot.role;

    this.authService.isAdmin.subscribe( result => {
      this.isAdmin = result;
    });
  }

  payEventAmounts(event) {

    let paymentAmountSelected = 0;

    if (event === 'all') {
      paymentAmountSelected = this.memberDetails.paymentRequestsTotalOutstanding;
    } else {
      paymentAmountSelected = event.amountOutstanding;
    }

    this.payWithModal(paymentAmountSelected, 'amount_requested');
  }


  payWithModal(paymentAmountSelected, paymentType, paymentDetails?) {
    const dialogRef = this.dialog.open(PayAmountComponent, {
      data: {
        currentClub: this.currentClub,
        paymentAmountSelected: paymentAmountSelected,
        paymentType,
        paymentDetails
      },
      id: 'payment-modal',
      maxWidth: '95vw',
      minWidth: '40vw'

    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMemberDetails();
    });
  }


  payOutstandingMemberships() {

    // check the paymentOptions first and display them to the user if there is
    // more than one - eg. pay monthly, split payment, single payment

    // get the current payment options for the outstanding memberships
    this.membershipService.getMembershipPaymentOptions(this.currentClub.id).subscribe( results => {
      if (results['numPaymentOptions'] > 1) {

        const dialogRef = this.dialog.open(MembershipPaymentOptionsComponent, {
          data: {
            currentClub: this.currentClub,
            paymentOptions: results['paymentOptions'],
            maxNumSplitPayments: results['paymentOptions'][0]['maxNumSplitPayments'] != null ? results['paymentOptions'][0]['maxNumSplitPayments'] : 1
          },
          maxWidth: '95vw',
          minWidth: '50vw',
          maxHeight: '95vh'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.getMemberDetails();
        });

      } else {

        // check if any memberships have membership forms to fill out first and then pay
        const dialogRef = this.dialog.open(MembershipFormsComponent, {
          disableClose: true,
          data: {
            currentClub: this.currentClub,
            paymentAmount: this.memberDetails.membershipsTotalOutstanding,
            membersGroups: this.memberDetails.membersOutstandingMembershipGroups,
            selectedPaymentTimeframe: results['paymentOptions'][0]['shortName'],
            maxNumSplitPayments: results['paymentOptions'][0]['maxNumSplitPayments']
          },
          maxWidth: '95vw',
          minWidth: '50vw',
          maxHeight: '95vh'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.getMemberDetails();
        });
      }
    });

  }

  getMemberDetails() {
    this.memberService.getUserMembersDetails().subscribe( result => {
      this.memberDetails = result;
      this.emailNotificationsEnabled = this.memberDetails.emailNotificationsEnabled;
    });
  }

  toggleEmailNotifications() {
    this.miscService.toggleEmailNotifications(this.currentClub.id).subscribe();
  }


  showSection(sectionToShow) {
    if (sectionToShow === 'account') {
      this.showAccount = true;
      this.showReceipts = false;
      this.showShop = false;
      this.showBookings = false;
    } else if (sectionToShow === 'receipts') {
      this.showAccount = false;
      this.showReceipts = true;
      this.showShop = false;
      this.showBookings = false;
    } else if (sectionToShow === 'shop') {
      this.showAccount = false;
      this.showReceipts = false;
      this.showShop = true;
      this.showBookings = false;
    } else if (sectionToShow === 'bookings') {
      this.showAccount = false;
      this.showReceipts = false;
      this.showShop = false;
      this.showBookings = true;
    }
  }


  convertDateToString(dateToBeConverted: string) {
    // 'MMMM d, yyyy'
    return moment(dateToBeConverted, 'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY');
  }


  requestMembershipCancelation() {
    const dialogRef = this.dialog.open(RequestCancellationComponent, {
      data: {
        memberMembershipsList: this.memberDetails.membersMemberships
      },
      id: 'payment-modal',
      maxWidth: '95vw',
      minWidth: '40vw'

    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMemberDetails();
    });
  }
}
