import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AnsweredMembershipFormComponent } from '../answered-membership-form/answered-membership-form.component';
import { MembershipService } from '../../../_services/membership.service';

@Component({
  selector: 'app-select-answered-membership-form',
  templateUrl: './select-answered-membership-form.component.html',
  styleUrls: ['./select-answered-membership-form.component.scss']
})
export class SelectAnsweredMembershipFormComponent implements OnInit {

  memberId;
  answeredMembershipForms = [];

  constructor(
    private dialog: MatDialog,
    private membershipServices: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.memberId = this.data.memberId;
    this.membershipServices.getAnsweredMembershipForms(this.memberId).subscribe(result => {
      this.answeredMembershipForms = result.answeredMembershipForms;
    });
  }

  viewAnsweredMembershipForm(answeredMembershipFormId) {

    // open modal to view answered membership form
    this.dialog.open(AnsweredMembershipFormComponent, {
      data: {
        answeredMembershipFormId
      },
      id: 'answered-membership-form',
      minWidth: '40vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }
}
