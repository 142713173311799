import { Component, OnInit, Inject } from '@angular/core';
import { PaymentService } from '../../../_services/payment.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {

  paymentData = {payment_items: [], specificRefundAmount: 0};
  refundType = 'item';
  errorMessage = '';
  clubCurrency = '';

  constructor(
    private paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RefundComponent>,
    private spinner: NgxSpinnerService,
    private clubService: ClubService,
    private matSnackbar: MatSnackBar) { }

  ngOnInit() {
    this.paymentData = this.data.paymentData;
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  completeRefund() {
    this.spinner.show();


    if (this.refundType === 'amount') {
      if (!this.paymentData.specificRefundAmount || this.paymentData.specificRefundAmount <= 0) {
        this.errorMessage = 'The amount to refund must be greater than 0.';
        this.spinner.hide();
      } else {

        this.paymentService.refundSpecificAmount(this.paymentData).subscribe(result => {
          this.displayErrorOrClose(result);
        });
      }
    } else {
      const selectedPaymentItems = this.paymentData.payment_items.filter( paymentItem => paymentItem.selected_for_refund );
      const paymentDataUsed = this.paymentData;
      paymentDataUsed.payment_items = selectedPaymentItems;

      this.paymentService.refundPaymentItems(paymentDataUsed).subscribe(result => {
        this.displayErrorOrClose(result);
      });
    }
  }

  closeRefund() {
    // display a popup and close the dialog box
    this.spinner.hide();
    this.dialogRef.close();
  }

  displayErrorOrClose(result) {
    if (result.error) {
      this.spinner.hide();
      this.matSnackbar.open(result.error, 'OK', {
        duration: 4000,
      });
    } else {
      this.closeRefund();
    }
  }
}
