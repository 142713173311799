import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class LottoService {

  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createLotto(lottoDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/lottos', lottoDetails)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getLottoDraws(clubId): Observable<any> {
    return this.http.get(this.apiUrl + '/lottos/club-lotto-draws/' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getLottoFutureEntries(lottoId): Observable<any> {
    return this.http.get(this.apiUrl + '/lottos/club-lotto-future-entries/' + lottoId).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getLottoDraw(lottoDrawId): Observable<any> {
    return this.http.get(this.apiUrl + '/lotto-draws/' + lottoDrawId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getLottoDrawEntries(lottoDrawId): Observable<any> {
    return this.http.get(this.apiUrl + '/lottos/club-lotto-draw-entries/' + lottoDrawId).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getLottoSettings(clubId): Observable<any> {
    return this.http.get(this.apiUrl + '/lotto-details/' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateLottoSettings(clubId, updatedLottoSettings): Observable<any> {
    return this.http.put(this.apiUrl + '/lottos/' + clubId, updatedLottoSettings)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

}
