import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../_services/stats.service';
import { ClubService } from '../../../_services/club.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-shop-reports',
  templateUrl: './shop-reports.component.html',
  styleUrls: ['./shop-reports.component.scss']
})
export class ShopReportsComponent implements OnInit {

  // date range measured in days
  dateRange = 90;
  clubCurrency = '';
  shopItems: Observable<Object>;

  constructor(
    private spinner: NgxSpinnerService,
    private statsService: StatsService,
    private clubService: ClubService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getReport();
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  getReport() {
    this.spinner.show();
    this.statsService.getShopStats(this.dateRange).subscribe(result => {
      this.shopItems = result;
      this.spinner.hide();
    });
  }

  viewShopItem(itemName) {
    const encodedItemName =  encodeURIComponent(itemName);
    this.router.navigate(['/shop-item-report/' + encodedItemName]);
  }

}
