import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddBankAccountComponent } from '../add-bank-account/add-bank-account.component';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-bank-account-warning',
  templateUrl: './bank-account-warning.component.html',
  styleUrls: ['./bank-account-warning.component.scss']
})
export class BankAccountWarningComponent implements OnInit {

  constructor(
    private matDialog: MatDialog,
    private clubService: ClubService
    ) { }

  noBankAccount = false;

  ngOnInit() {

    if (this.clubService.getClub().stripe_user_id === null) {
      this.noBankAccount = true;
    }
  }

  addBankAccount() {
    this.matDialog.open(AddBankAccountComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }

}
