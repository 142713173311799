import { Component, OnInit } from '@angular/core';
import { GroupsService } from '../../../_services/groups.service';
import { ClubService } from '../../../_services/club.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AddMembershipComponent } from '../add-membership/add-membership.component';
import { MembershipService } from '../../../_services/membership.service';
import { MembershipFormOptionsComponent } from '../membership-form-options/membership-form-options.component';
import { EditMembershipComponent } from '../edit-membership/edit-membership.component';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { MemberService } from '../../../_services/member.service';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-membership-groups',
  templateUrl: './membership-groups.component.html',
  styleUrls: ['./membership-groups.component.scss']
})
export class MembershipGroupsComponent implements OnInit {

  memberships = [];
  displayedMemberships = [];
  searchedMemberMemberships = [];
  club;
  clubCurrency;

  myControl = new FormControl();
  members = [];
  filteredMembers;

  constructor(
    private groupsService: GroupsService,
    private membershipService: MembershipService,
    private clubService: ClubService,
    private dialog: MatDialog,
    private memberService: MemberService,
    private snackBar: MatSnackBar,
    private aysService: AreYouSureService) { }

  ngOnInit() {
    this.club =  this.clubService.getClub();
    this.clubCurrency =  this.clubService.getClubCurrency();
    this.getMemberships();
    this.getAllMembers();
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
        '<h3>Membership Help</h3>' +
        '<h4>Adding A Membership</h4>' +
        '<ul id="admin-instructions">' +
        '<li>To add a new membership just click on the Create Membership button and follow the instructions.</li>' +
        '<li>Once you add a member to a membership the member will see the membership when the login to ClubFlow.</li>' +
        '<li>They will then be able to fill out the membership form and pay their membership.</li>' +
        '<li>The membership will only appear to the members if todays date is within the membership start and end date.</li>' +
        '</ul>' +
        '<h4>Reset A Membership</h4>' +
        '<ul id="admin-instructions">' +
        '<li>To reset a membership for a new season you can simply change the start and end date for that membership.</li>' +
        '</ul>',
      },
      minWidth: '50vw',
      maxHeight: '95vh',
    });
  }

  getAllMembers() {
    this.memberService.getAllMembers().subscribe(results => {
      this.members = results.allClubMembers;

      this.filteredMembers = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.first_name),
        map(first_name => first_name ? this._filter(first_name) : this.members.slice())
      );

      this.myControl.valueChanges.subscribe( result => {
          // reset the displayed memberships list back to normal if user is not selected
          if ( !result.id ) {
            this.displayedMemberships = this.memberships;
            this.searchedMemberMemberships = [];
          }
        });
    });
  }


  displayFn(member?): string | undefined {

    // find which membership_groups the member is in
    if (member) {
      this.memberships.forEach(membership => {
        const foundMemberInMembership = membership.group_members.find( group_member => group_member.id === member.id);
        if (foundMemberInMembership) {
          this.searchedMemberMemberships.push(membership);
        }
      });
      this.displayedMemberships = this.searchedMemberMemberships;
    }

    // display the member
    return member ? member.first_name + ' ' + member.surname : undefined;
  }

  private _filter(first_name: String): string[] {

    let filterValue;
    if (first_name === '') {
      filterValue = '';
    } else {
      filterValue = first_name.toLowerCase();
    }
    return this.members.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }


  getMemberships() {
    this.membershipService.getMemberships().subscribe(result => {
      this.memberships = result.memberships;
      this.displayedMemberships = this.memberships;
    });
  }

  createMembershipGroup() {
    const dialogRef = this.dialog.open(AddMembershipComponent, {
      data: {
        currentClub: this.club,
        action: 'create'
      },
      id: 'membership-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMemberships();
    });
  }

  editMembershipGroup(selectedMembership) {
    const dialogRef = this.dialog.open(EditMembershipComponent, {
      data: {
        currentClub: this.club,
        membership: selectedMembership,
      },
      id: 'membership-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMemberships();
    });
  }

  deleteMembershipGroup(selectedMembership) {
    // delete the membership & then the group
    this.membershipService.deleteMembership(selectedMembership.id).subscribe(result => {
      this.getMemberships();
    });
  }

  sendAllMembershipPaymentRequest() {
    this.membershipService.sendAllMembershipReminder(this.club.id).subscribe( result => {
      this.snackBar.open('Reminder emails sent!', 'OK', {
        duration: 4000,
      });
    });
  }
}
