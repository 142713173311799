import { Component, OnInit } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { PaymentService } from '../../../_services/payment.service';

@Component({
  selector: 'app-log-cash-payment',
  templateUrl: './log-cash-payment.component.html',
  styleUrls: ['./log-cash-payment.component.scss']
})
export class LogCashPaymentComponent implements OnInit {

  amountOwed = 0;
  clubId = 0;
  minCashAmount = 0;
  errorMessage = '';
  paymentFor = '';
  clubCurrency = '';
  cashPaymentType = 'paymentRequest';
  memberName = '';

  constructor(private clubService: ClubService,
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<LogCashPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.clubId = this.clubService.getClub().id;
    this.clubCurrency = this.clubService.getClubCurrency();
    this.paymentFor = this.data.paymentFor;
    this.amountOwed = this.data.amountOwed;
    this.memberName = this.data.memberName;
    this.cashPaymentType = this.data.cashPaymentType; // membership/single payment request etc

    if (this.cashPaymentType === 'membershipPayment') {
      this.minCashAmount = this.amountOwed;
    }
  }

  logCashPayment(cashAmount) {

    this.errorMessage = '';

    // validation
    if (cashAmount > this.amountOwed) {
      this.errorMessage = 'Error: Amount input is larger than amount owed.';
    } else if (this.amountOwed !== 0 && cashAmount <= 0) {
      this.errorMessage = 'Error: Amount input must be larger than 0';
    }

    if (this.cashPaymentType === 'membershipPayment' && cashAmount != this.minCashAmount) {
      this.errorMessage = 'Error: Amount input must be the same as the membership amount of: ' + this.minCashAmount;
    }

    // if no error messages continue on
    if (this.errorMessage === '') {

      const paymentDetails = {
        cashPaymentType: this.cashPaymentType, // membershipPayment or paymentRequest
        payableItemId: this.cashPaymentType === 'membershipPayment' ? this.data.membershipId : this.data.paymentRequestId,
        clubId: this.clubId,
        memberId: this.data.memberId,
        paymentFor: this.paymentFor,
        cashAmount
      };

      this.paymentService.logCashPayment(paymentDetails).subscribe(res => {
        if (res.error) {
          this.errorMessage = res.error;
        }
        this.dialogRef.close();
      });
    }
  }
}
