import { Component, OnInit, Input } from '@angular/core';
import { GroupsService } from '../../../_services/groups.service';
import { ActivatedRoute } from '@angular/router';
import { ClubService } from '../../../_services/club.service';
import { MatAutocompleteTrigger, MatSnackBar, MatDialog } from '@angular/material';
import { ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AnsweredMembershipFormComponent } from '../../admin-components/answered-membership-form/answered-membership-form.component';


@Component({
  selector: 'app-coach-member-group',
  templateUrl: './member-group.component.html',
  styleUrls: ['./member-group.component.scss']
})
export class MemberGroupComponent implements OnInit {

  @Input() groupId: string;
  @Input() groupType: string;

  groupMembers = [];
  filteredMembers;
  users;
  selectedUsers;
  groupAdmins;

  groupName: String;
  @ViewChild('userDetails', {static: true}) userDetails;
  myControl = new FormControl();


  constructor(
    private groupsService: GroupsService,
    private route: ActivatedRoute,
    private clubService: ClubService,
    private aysService: AreYouSureService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.groupId = params['groupId'];
      this.getGroupDetails();
    });
  }


  private _filter(first_name: String): string[] {

    let filterValue;
    if (first_name === '') {
      filterValue = '';
    } else {
      filterValue = first_name.toLowerCase();
    }
    return this.users.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }


  displayFn(user?): string | undefined {
    return user ? user.first_name : undefined;
  }


  getGroupDetails() {
    this.groupsService.getGroupMembers(this.groupId, 'contact').subscribe(results => {
      this.groupMembers = results.members;
      this.groupName = results.groupName;
    });

    this.groupsService.getGroupMembers(this.groupId, 'contact', 'admin').subscribe(results => {
      this.groupAdmins = results.members;
    });
  }


  viewAnsweredMembershipForm(answeredMembershipFormId) {

    // open modal to view answered membership form
    this.dialog.open(AnsweredMembershipFormComponent, {
      data: {
        answeredMembershipFormId
      },
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }
}
