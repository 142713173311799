import { Component, OnInit, HostListener } from '@angular/core';
import { MiscService } from '../../_services/misc.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ContactComponent } from '../contact/contact.component';
import { ViewVideoComponent } from '../view-video/view-video.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public sendingMessage = false;
  errorMessage = '';
  paymentPercentOrMonthly = 'PAYG';
  floatingNavActive = false;
  sourceClubLogos = 'url(../../../assets/landing/images/shandonLogo.jpg)';

  constructor(
    private miscService: MiscService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.rotateLogos(0);
  }


  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const numberOfPx = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (numberOfPx > 850) {
        this.floatingNavActive = true;
    } else {
      this.floatingNavActive = false;
    }
  }


  openFAQ(event) {

    let lastQuestionStyle = '';
    let element;

    if (event.target.parentElement.getElementsByClassName('paragraph').length > 0) {
      lastQuestionStyle = event.target.parentElement.getElementsByClassName('paragraph')[0].style.display;
      element = event.target.parentElement.getElementsByClassName('paragraph')[0];
    } else {
      // covering the down arrow click
      lastQuestionStyle = event.target.parentElement.parentElement.getElementsByClassName('paragraph')[0].style.display;
      element = event.target.parentElement.parentElement.getElementsByClassName('paragraph')[0];
    }

    // hide any other open answers
    const answersList = event.target.parentElement.parentElement.parentElement.getElementsByClassName('paragraph');

    for (let i = 0; i < answersList.length; i++) {
      answersList[i].style.display = 'none';
    }

    // display the clicked on question's answer
    if (lastQuestionStyle === 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }

  contactUs() {
    const dialogRef = this.dialog.open(ContactComponent, {
      width: '450px',
      maxWidth: '95vw',
      id: 'feedback-modal'
    });
  }


  viewDemoVideo() {
    const dialogRef = this.dialog.open(ViewVideoComponent, {
    });
  }


  public onSubmit(values: any) {

    if (values.email === '') {
      this.errorMessage = 'Please add an email address!';
    } else if (values.clubName === '') {
      this.errorMessage = 'Please add a club name';
    } else {
      this.sendingMessage = true;

      this.miscService.postSetupMessage(values).subscribe(
        res => {
          this.snackBar.open('Your message has been sent to us! We will be in touch shortly.', '', {
            duration: 3000,
          });
          this.errorMessage = '';
          this.sendingMessage = false;
        },
        error => {
          this.sendingMessage = false;
          this.errorMessage = error._body;
        });
    }
  }


  rotateLogos(index) {
    const logosArray = [
      `url(../../../assets/landing/images/corkBCLogo.png)`,
      `url(../../../assets/landing/images/clonmelRowingLogo.jpg)`,
      `url(../../../assets/landing/images/galwayLogo.jpeg)`,
      `url(../../../assets/landing/images/shandonLogo.jpg)`
    ];

    if (index > 3) {
      index = 0;
    }

    setTimeout(() => {
      this.sourceClubLogos = logosArray[index];
      this.rotateLogos(index + 1);
    }, 4000);
  }

}
