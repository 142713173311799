import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { MembershipFormsComponent } from '../membership-forms/membership-forms.component';

@Component({
  selector: 'app-membership-payment-options',
  templateUrl: './membership-payment-options.component.html',
  styleUrls: ['./membership-payment-options.component.scss']
})
export class MembershipPaymentOptionsComponent implements OnInit {


  paymentOptions = [];
  selectedPaymentTimeframe = '';
  clubCurrency = '';

  constructor(
    private clubService: ClubService,
    private dialog: MatDialog,
    private currentDialogRef: MatDialogRef<MembershipPaymentOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.clubCurrency = this.clubService.getClubCurrency(this.data.currentClub.country);
    this.paymentOptions = this.data.paymentOptions;
  }


  paySelectedOption(selectdMembersOutstandingMembershipGroups, selectedMembershipsTotalOutstanding, paymentOptionSelected) {

    if (paymentOptionSelected === 'Monthly Payment') {
      this.selectedPaymentTimeframe = 'monthlyPayment';
    } else if (paymentOptionSelected === 'Split Payment') {
      this.selectedPaymentTimeframe = 'splitPayment';
    } else {
      this.selectedPaymentTimeframe = 'singlePayment';
    }

    const dialogRef = this.dialog.open(MembershipFormsComponent, {
      disableClose: true,
      data: {
        currentClub: this.data.currentClub,
        paymentAmount: selectedMembershipsTotalOutstanding,
        membersGroups: selectdMembersOutstandingMembershipGroups,
        selectedPaymentTimeframe: this.selectedPaymentTimeframe,
        maxNumSplitPayments: this.data.maxNumSplitPayments
      },
      maxWidth: '95vw',
      minWidth: '50vw',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.currentDialogRef.close();
    });
  }
}
