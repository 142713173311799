import { Component, OnInit, Inject } from '@angular/core';
import { ClubAssetService } from '../../../_services/club-asset.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-add-edit-club-property',
  templateUrl: './add-edit-club-property.component.html',
  styleUrls: ['./add-edit-club-property.component.scss']
})
export class AddEditClubPropertyComponent implements OnInit {

  clubAsset = { 
    id: 0, 
    clubId: '', 
    name: '', 
    description: '', 
    booking_option: 'none', 
    bookable_slots: [],
    booking_delay: 0 
  };
  
  displayAddSlotSection = false;
  num_spaces;
  day_of_week;
  start_time;
  duration;
  price;
  clubCurrency;
  booking_delay;

  warning = '';

  constructor(private clubAssetsService: ClubAssetService,
    public dialogRef: MatDialogRef<AddEditClubPropertyComponent>,
    private clubService: ClubService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.clubAsset.clubId = this.clubService.getClub().id;
    this.clubCurrency = this.clubService.getClubCurrency();

    if (this.data.clubAsset) {

      this.clubAsset = this.data.clubAsset;
    }
  }


  createSlot() {

    if( typeof this.num_spaces === 'undefined' ){
      this.warning = 'Please select a number of spaces';
    }
    else if( typeof this.day_of_week === 'undefined' ){
      this.warning = 'Please select a booking day';
    }
    else if( typeof this.start_time === 'undefined' ){
      this.warning = 'Please select a start time';
    }
    else if( typeof this.duration  === 'undefined' ){
      this.warning = 'Please select a duration day';
    }
    else if( typeof this.price  === 'undefined' ){
      this.warning = 'Please select a price';
    }
    else if( typeof this.booking_delay  === 'undefined' ){
      this.warning = 'Please select a booking delay';
    
    }else{
      this.clubAsset.bookable_slots.push({
        num_spaces : this.num_spaces,
        day_of_week : this.day_of_week,
        start_time: this.start_time,
        duration: this.duration,
        price: this.price,
        note: '',
        booking_delay: this.booking_delay
      });

      this.displayAddSlotSection = false;
    }

  }


  removeSlot(slotIndex) {
    this.clubAsset.bookable_slots.splice(slotIndex, 1);
  }


  onSubmit() {

    if (this.clubAsset.id !== 0) {
      this.clubAssetsService.updateClubAssets(this.clubAsset).subscribe(result => {
        if (result.status === 'ok') {
          this.dialogRef.close();
        }
      });
    } else {
      this.clubAssetsService.createClubAsset(this.clubAsset).subscribe(response => {
        if (response.status === 'ok')  {
          this.dialogRef.close();
        }
      });
    }
  }

}
