import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-member-register',
  templateUrl: './member-register.component.html',
  styleUrls: ['./member-register.component.scss']
})
export class MemberRegisterComponent implements OnInit {


  email: String = '';
  password: String = '';
  confirmedPassword: String = '';
  warningMessage: String = '';
  termsAndConditions = false;
  privacyPolicy = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {}

  onRegister() {
    this.warningMessage = '';

    if (this.password !== this.confirmedPassword) {
      this.warningMessage = 'Password does not match the confirm password!';
      return;
    } else if (this.password === '') {
      this.warningMessage = 'You can\'t use empty passwords!';
      return;
    }  else if (this.email === '') {
      this.warningMessage = 'You must add an email!';
      return;
    } else {

    this.authService.registerMember(this.email, this.password, this.termsAndConditions, this.privacyPolicy)
      .subscribe(res => {
        // check for errors
        this.warningMessage = '';
        if (Array.isArray(res.email)) {
          this.warningMessage += res.email[0];
        }
        // if (Array.isArray(res.name)) {
        //   this.warningMessage += res.name[0];
        // }
        if (Array.isArray(res.password)) {
          this.warningMessage += res.password[0];
        }
        if (Array.isArray(res.terms_and_conditions)) {
          this.warningMessage += res.terms_and_conditions[0];
        }
        if (Array.isArray(res.privacy_policy)) {
          this.warningMessage += res.privacy_policy[0];
        }
        if (res.error) {
          this.warningMessage += res.error;
        }

        // if no errors - navigate to home
        if (!this.warningMessage) {
            this.router.navigate(['member-home']);
        }
      }, error => {
        this.warningMessage = 'Invalid Credentials!';
        console.error(error);
      });
    }
  }
}
