import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
// import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(public auth: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // this will be passed from the route config
    // on the data property
    const expectedRole = next.data.expectedRole;
    const club = JSON.parse(localStorage.club);

    // check user is an admin or user in the club
    if (expectedRole.indexOf( club.pivot.role ) === -1) {
      return false;
    }
    return true;
  }
}
