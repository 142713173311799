import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { MatAutocompleteTrigger, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { GroupsService } from '../../../_services/groups.service';
import { Inject } from '@angular/core';
import { MembershipService } from '../../../_services/membership.service';
import { AddEditMembershipFormComponent } from '../add-edit-membership-form/add-edit-membership-form.component';
import { AreYouSureService } from '../../../_services/are-you-sure.service';

@Component({
  selector: 'app-membership-form-options',
  templateUrl: './membership-form-options.component.html',
  styleUrls: ['./membership-form-options.component.scss']
})
export class MembershipFormOptionsComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {static: true}) autocompleteTrigger: MatAutocompleteTrigger;


  constructor(
    private membershipsService: MembershipService,
    private dialog: MatDialog,
    private aysService: AreYouSureService,
    public dialogRef: MatDialogRef<MembershipFormOptionsComponent>,
    public dialogRefMembershipFormOptions: MatDialogRef<MembershipFormOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  membershipForms = [];
  membershipDetails = {
    membership_form_id: 0,
    membership_form : {},
  };

  ngOnInit() {

    if (this.data.membership) {
      this.membershipDetails = this.data.membership;
    }

    this.getMembershipForms();
  }

  createMembershipForm() {
    const dialogRef = this.dialog.open(AddEditMembershipFormComponent, {
      data: {
        membership: this.data.membership
      },
      id: 'create-membership-form-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMembershipForms();
    });
  }

  selectMembershipForm(selectedMembershipForm) {
    this.membershipDetails.membership_form = selectedMembershipForm;

    this.membershipsService.updateMembership(this.membershipDetails).subscribe(result => {
      this.membershipDetails.membership_form_id = selectedMembershipForm.id;
      // pass the membership form details back to the form that called this modal
      this.dialogRef.close(this.membershipDetails.membership_form);
    });
  }

  getMembershipForms() {
    this.membershipsService.getMembershipForms().subscribe( results => {
      this.membershipForms = results.membershipForms;
    });
  }

  editMembershipForm(membershipForm) {
    const dialogRef = this.dialog.open(AddEditMembershipFormComponent, {
      data: {
        // currentClub: this.club,
        membershipForm
      },
      id: 'create-membership-form-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMembershipForms();
    });
  }


  deleteMembershipForm(membershipForm) {
    this.membershipsService.deleteMembershipForm(membershipForm.id).subscribe( results => {
      this.getMembershipForms();
    });
  }
}
