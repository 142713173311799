import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { MatAutocompleteTrigger, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { Inject } from '@angular/core';
import { MembershipService } from '../../../_services/membership.service';
import { AreYouSureService } from '../../../_services/are-you-sure.service';

@Component({
  selector: 'app-add-edit-membership-form',
  templateUrl: './add-edit-membership-form.component.html',
  styleUrls: ['./add-edit-membership-form.component.scss']
})
export class AddEditMembershipFormComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {static: true}) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private clubService: ClubService,
    private membershipsService: MembershipService,
    private aysService: AreYouSureService,
    public dialogRef: MatDialogRef<AddEditMembershipFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  membershipForm = {
      id: 0,
      name: '',
      membership_form_questions: []
  };
  customQuestion;
  clubCurrency;
  groups = [];
  filteredUsers: Observable<string[]>;
  myControl = new FormControl();
  selectedGroupId = [];
  yearlyOption = false;
  monthlyOption = true;
  errorMessages = [];


  ngOnInit() {
    if (this.data.membershipForm) {
      this.membershipForm = this.data.membershipForm;

      // get the full membership form if one already created
      this.membershipsService.getMembershipForm(this.membershipForm.id).subscribe(result => {
        this.membershipForm = result.membershipForm;
      });
    }
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  addQuestion() {
    this.membershipForm.membership_form_questions.push({ text: '', answer_type: '', optional: 0});
  }

  deleteQuestion(index) {
    this.membershipForm.membership_form_questions.splice(index, 1);
  }

  onSubmit() {
    this.errorMessages = [];

    if (this.membershipForm.id !== 0) {
      this.membershipsService.updateMembershipForm(this.membershipForm).subscribe(result => {

        if (result.status === 'error') {
          this.errorMessages = result.errorMessages;
        } else {
          this.dialogRef.close();
        }
      });
    } else {
      this.membershipsService.createMembershipForm(this.membershipForm).subscribe(result => {

        if (result.status === 'error') {
          this.errorMessages = result.errorMessages;
        } else {
          // pass the membership form details back to the form that called this modal
          this.dialogRef.close(result.membershipForm);
        }
      });
    }
  }
}
