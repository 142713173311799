import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = '';
  password: string = '';
  warningMessage: string;

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  ngOnInit() {
  }

  onLogIn() {
    this.authService.login(this.email, this.password)
    .subscribe(res => {
      // check for errors
      this.warningMessage = '';
      if (Array.isArray(res)) {
        this.warningMessage += res[0];
      }
      // if no errors - check if the user is an admin or a standard user
      // and then navigate to correct homepage
      if (!this.warningMessage) {
        if (res.clubs[0].pivot.role === 'admin' || res.clubs[0].pivot.role === 'super_admin') {

          // check if the club onboarding has been completed
          if (res.clubs[0].clubOnboardingCompleted === true) {
            this.router.navigate(['admin-home']);
          } else {
            this.router.navigate(['onboarding']);
          }

        } else {
          // on first login - get the user to reset the password to something proper
          if (res.first_login && res.first_login === true) {
            this.router.navigate(['reset-password/' + res.reset_token ]);
          } else {
            this.router.navigate(['member-home']);
          }
        }
      }
    }, error => {
      this.warningMessage = 'Email or password is incorrect!';
      console.error(error);
    } );
  }
}
