import { Component, OnInit } from '@angular/core';
import { MembershipFormService } from '../../../_services/membership-form.service';
import { ClubService } from '../../../_services/club.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-membership-form-reports',
  templateUrl: './membership-form-reports.component.html',
  styleUrls: ['./membership-form-reports.component.scss']
})
export class MembershipFormReportsComponent implements OnInit {

  membersAndAnswers = [];
  formQuestions = [];
  answeredMembershipForms = [];
  fromDate = '';
  toDate = '';
  selectedFormId = 0;

  graphData = [];
  graphQuestions = [];
  allGraphData = [];

    // graph options
    gradient  = false;
    showLegend = true;
    showLabels = true;
    isDoughnut = false;
    legendPosition = 'below';

    colorScheme = {
      domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };

  constructor(
    private membershipFormService: MembershipFormService,
    private clubService: ClubService) { }

  ngOnInit() {
  }

  selectForm($event) {
    this.selectedFormId =  $event.value;
    this.membershipFormService.getAllMembershipFormAnswers(
      this.clubService.getClub().id,
      this.selectedFormId,
      this.fromDate,
      this.toDate).subscribe(
        result => {
          this.membersAndAnswers = result.answeredMembershipFormsAndAnswers;
          this.formQuestions = result.answeredMembershipFormsAndAnswers[0].answered_membership_form_questions;
          this.graphQuestions = Object.keys(result.graphData);
          this.allGraphData = result.graphData;
      });
  }

  selectGraphOption($event) {
    this.graphData = this.allGraphData[$event.value];
  }

  onChangeDate($event, toOrFrom) {

    if (toOrFrom === 'from') {
      this.fromDate = $event.format('YYYY-MM-DD 00:00:00');
    } else {
      this.toDate = $event.format('YYYY-MM-DD 00:00:00');
    }

    if (this.fromDate !== '' && this.toDate !== '') {
      this.getAvailableForms();
    }
  }

  getAvailableForms() {
    this.membershipFormService.getAllAnsweredMembershipForms(this.clubService.getClub().id, this.fromDate, this.toDate)
    .subscribe(result => {
      this.answeredMembershipForms = result;
      // this.answeredMembershipForms.push({'membership_form_name' : 'All Memberships', 'membership_form_id' : 0});
    });
  }

  exportAnsweredMembershipForms() {
    this.membershipFormService.exportAnsweredMembershipForms(
      this.clubService.getClub().id,
      this.selectedFormId,
      this.fromDate,
      this.toDate).subscribe(result => {
          const data = result.csv;

          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          const csvArray = csv.join('\r\n');

          const blob = new Blob([csvArray], {type: 'text/csv' });
          saveAs(blob, 'formData.csv');
        });
  }
}
