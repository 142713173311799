import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MembershipService } from '../../../_services/membership.service';
import { AddEditMembershipFormComponent } from '../add-edit-membership-form/add-edit-membership-form.component';
import { MembershipFormOptionsComponent } from '../membership-form-options/membership-form-options.component';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-edit-membership',
  templateUrl: './edit-membership.component.html',
  styleUrls: ['./edit-membership.component.scss']
})
export class EditMembershipComponent implements OnInit {

  // error messages
  nameErrorMessage = '';
  amountErrorMessage = '';
  startDateErrorMessage = '';
  endDateErrorMessage = '';

  membership = {
    id: 0,
    name: '',
    amount: 0,
    start_date: '',
    end_date: '',
    membership_form: {name: ''},
    membership_form_id: 0,
    payment_option: ''
  };

  selectedMembershipForm = 'No Form Selected';

  constructor(
    private dialog: MatDialog,
    private membershipsService: MembershipService,
    private clubService: ClubService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
      this.membership = this.data.membership;
      this.membership.name = this.data.membership.group.name;

      if (this.membership.membership_form) {
        this.selectedMembershipForm = this.membership.membership_form.name;
      }
  }


  updateMembership() {

      this.membershipsService.updateMembership(this.membership).subscribe(result => {

        if (result.errors) {
          if (result.errors.name) {
            this.nameErrorMessage = result.errors.name;
          }
          if (result.errors.amount) {
            this.amountErrorMessage = result.errors.amount;
          }
          if (result.errors.start_date) {
            this.startDateErrorMessage = result.errors.start_date;
          }
          if (result.errors.end_date) {
            this.endDateErrorMessage = result.errors.end_date;
          }
        } else {
          this.dialog.closeAll();
        }
      });
  }


  createMembershipForm() {
    const dialogRef = this.dialog.open(AddEditMembershipFormComponent, {
      data: {
        membership: this.membership
      },
      id: 'create-membership-form-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(membershipForm => {
      this.selectedMembershipForm = membershipForm.name;
      this.membership.membership_form = membershipForm;
    });
  }



  selectExistingMembershipForm() {
    const dialogRef = this.dialog.open(MembershipFormOptionsComponent, {
      data: {
        currentClub: this.clubService.getClub(),
        membership: this.membership
      },
      id: 'membership-form-options-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(membershipForm => {
      this.selectedMembershipForm = membershipForm.name;
    });
  }

}
