import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { PaymentRequestService } from '../../../_services/payment-request.service';

@Component({
  selector: 'app-manage-broe-event-upload',
  templateUrl: './manage-broe-event-upload.component.html',
  styleUrls: ['./manage-broe-event-upload.component.scss']
})
export class ManageBROEEventUploadComponent implements OnInit {

  paymentRequestDetails = [];
  clubCurrency = '';
  paymentRequestName = '';
  clubId;
  extraFee = 0;
  missingEmailsCount = 0;
  addingClubFee = false;
  selectedRadioItem = 'per_member';

  constructor(public dialogRef: MatDialogRef<ManageBROEEventUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentRequestService: PaymentRequestService,
    private clubService: ClubService) { }

  displayedHeaders = [ 'memberName', 'membershipNumber', 'memberEmail', 'events', 'amountOwed'];
  displayedColumns = [ 'memberName', 'membershipNumber', 'memberEmail', 'events', 'amountOwed'];
  tableHeaders = [
    {title: 'Member', getName: 'memberName'},
    {title: 'Membership Number', getName: 'membershipNumber'},
    {title: 'Email', getName: 'memberEmail'},
    {title: 'Events', getName: 'events'},
    {title: 'Total Owed', getName: 'amountOwed'},
  ];

  ngOnInit() {
    this.paymentRequestDetails = this.data.paymentRequestData.paymentRequestDetails;
    this.paymentRequestName = this.data.paymentRequestData.paymentRequestName;
    this.clubCurrency = this.clubService.getClubCurrency();
    this.clubId = this.clubService.getClub().id;

    this.updateMissingEmailCount();
  }


  createEvent() {

    const clubFeeDetails = { extraFee: this.extraFee };
    const paymentRequestDetails = { 
      name: this.paymentRequestName, 
      paymentRequestDate: this.data.paymentRequestData.paymentRequestDate,
      paymentRequestType: 'broe' 
    };

    this.paymentRequestService.createPaymentRequest(paymentRequestDetails, this.clubId, this.paymentRequestDetails, clubFeeDetails).subscribe( result => {
      
      if (result.status === 'ok') {
        this.dialogRef.close(result.paymentRequest.id);
      }
    });
  }

  updateEmail(row, newEmailAddress) {
    // check that the format is correct and that the field is not empty
    if (newEmailAddress !== '' && this.validateEmail(newEmailAddress)) {
      row.hasEmail = true;
      this.updateMissingEmailCount();
    }
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

  updateMissingEmailCount() {
    this.missingEmailsCount = 0;

    this.paymentRequestDetails.forEach( row => {
      if ( !row.hasEmail  ) {
          this.missingEmailsCount += 1;
      }
    });
  }

  updateExtraFee(inputExtraFee, selectedRadioBtn?) {
    this.extraFee = inputExtraFee;

    if (selectedRadioBtn) {
      this.selectedRadioItem = selectedRadioBtn;
    }

    this.paymentRequestDetails.forEach( row => {
      let eventMultiplier = 0;
      // the first time store the eventTotal as oldEventTotal
      if (!row.oldAmountOwed) {
        row.oldAmountOwed = row.amountOwed;
      }

      // revert the event total to what it was before
      row.amountOwed = row.oldAmountOwed;

      row.events.forEach( event => {
        eventMultiplier++;
      });

      if (this.selectedRadioItem === 'per_race') {
        row.amountOwed = row.amountOwed + (Number(inputExtraFee) * eventMultiplier);
      } else {
        row.amountOwed = row.amountOwed + Number(inputExtraFee);
      }

    });
  }

}
