import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-club-register',
  templateUrl: './club-register.component.html',
  styleUrls: ['./club-register.component.scss']
})
export class ClubRegisterComponent implements OnInit {

  clubName = '';
  password = '';
  email = '';
  firstName = '';
  surname = '';
  type = '';
  country = '';
  termsAndConditions = false;
  privacyPolicy = false;
  warningMessage: string;

  constructor(private authService: AuthenticationService, private router: Router) { }

  ngOnInit() {
  }

  onSelectionChange(selectType, event) {

    if (selectType === 'country') {
      this.country = event.value;
    }

    if (selectType === 'type') {
      this.type = event.value;
    }
  }

  onRegister() {

    const clubData = {
      email: this.email,
      first_name: this.firstName,
      surname: this.surname,
      password: this.password,
      clubName: this.clubName,
      country: this.country,
      type: this.type,
      terms_and_conditions: this.termsAndConditions,
      privacy_policy: this.privacyPolicy
    };

    this.authService.registerClub(clubData).subscribe(res => {
        // check for errors
        this.warningMessage = '';
        if (Array.isArray(res.clubName)) {
          this.warningMessage += res.clubName[0];
        }
        if (Array.isArray(res.country)) {
          this.warningMessage += res.country[0];
        }
        if (Array.isArray(res.type)) {
          this.warningMessage += res.type[0];
        }
        if (Array.isArray(res.email)) {
          this.warningMessage += res.email[0];
        }
        if (Array.isArray(res.first_name)) {
          this.warningMessage += res.first_name[0];
        }
        if (Array.isArray(res.surname)) {
          this.warningMessage += res.surname[0];
        }
        if (Array.isArray(res.password)) {
          this.warningMessage += res.password[0];
        }
        if (Array.isArray(res.terms_and_conditions)) {
          this.warningMessage += res.terms_and_conditions[0];
        }
        if (Array.isArray(res.privacy_policy)) {
          this.warningMessage += res.privacy_policy[0];
        }
        // if not errors - navigate to the onboarding
        if (!this.warningMessage) {
          this.router.navigate(['onboarding']);
        }
      }, error => {
        this.warningMessage = 'Error during registration - please try again or contact our support!';
        console.error(error);
      });
  }

}
