import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { GroupsService } from '../../../_services/groups.service';

@Component({
  selector: 'app-manage-group-upload',
  templateUrl: './manage-group-upload.component.html',
  styleUrls: ['./manage-group-upload.component.scss']
})
export class ManageGroupUploadComponent implements OnInit {

  groupDetails = [];
  clubId;
  groupName;

  constructor(public dialogRef: MatDialogRef<ManageGroupUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private clubService: ClubService,
    private groupService: GroupsService) { }

  displayedColumns = [ 'groupName', 'memberName', 'memberEmail', 'memberDOB'];
  tableHeaders = [
    {title: 'Group Name', getName: 'groupName'},
    {title: 'Member Name', getName: 'memberName'},
    {title: 'Member Email', getName: 'memberEmail'},
    {title: 'Date Of Birth', getName: 'memberDOB'},
  ];

  ngOnInit() {
    this.groupDetails = this.data.groupData.groupDetails;
    this.groupName = this.data.groupData.groupName;
    this.clubId = this.clubService.getClub().id;
  }

  completeMembersUpload() {
    this.groupService.completeGroupUpload(this.clubId, this.groupDetails).subscribe( result => {
      if (result.status === 'ok') {
        this.snackBar.open('New group created!', 'OK', {
          duration: 4000,
        });
        this.dialogRef.close();
      }
    });
  }
}
