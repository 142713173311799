import { Component, OnInit, Input } from '@angular/core';
import { GroupsService } from '../../../_services/groups.service';
import { ActivatedRoute } from '@angular/router';
import { ClubService } from '../../../_services/club.service';
import { MatAutocompleteTrigger, MatSnackBar, MatDialog } from '@angular/material';
import { ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AnsweredMembershipFormComponent } from '../answered-membership-form/answered-membership-form.component';
import { MemberService } from '../../../_services/member.service';

@Component({
  selector: 'app-member-group',
  templateUrl: './admin-member-group.component.html',
  styleUrls: ['./admin-member-group.component.scss']
})
export class AdminMemberGroupComponent implements OnInit {

  @Input() groupId: string;
  @Input() groupType: string;

  groupMembers = [];
  filteredMembers;
  users;
  selectedUsers;
  groupAdmins;

  groupName: String;
  @ViewChild(MatAutocompleteTrigger, {static: true}) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('userDetails', {static: true}) userDetails;
  myControl = new FormControl();


  constructor(
    private groupsService: GroupsService,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private clubService: ClubService,
    private aysService: AreYouSureService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.groupId = params['groupId'];
      this.getGroupDetails();
    });

    this.getAllMembers();
  }


  private _filter(first_name: String): string[] {

    let filterValue;
    if (first_name === '') {
      filterValue = '';
    } else {
      filterValue = first_name.toLowerCase();
    }
    return this.users.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }


  displayFn(user?): string | undefined {
    return user ? user.first_name + ' ' + user.surname : undefined;
  }


  getGroupDetails() {
    this.groupsService.getGroupMembers(this.groupId, 'contact').subscribe(results => {
      this.groupMembers = results.members;
      this.groupName = results.groupName;
    });

    this.groupsService.getGroupMembers(this.groupId, 'contact', 'admin').subscribe(results => {
      this.groupAdmins = results.members;
    });
  }


  getAllMembers() {
    this.memberService.getAllMembers().subscribe(results => {
      this.users = results.allClubMembers;

      this.filteredMembers = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.first_name),
        map(first_name => first_name ? this._filter(first_name) : this.users.slice())
      );
    });
  }

  addUserToGroup(memberType) {
    this.groupsService.addGroupMember(this.groupId, this.myControl.value.id, memberType).subscribe(result => {
      if (result.status === 'exists') {
        this.snackBar.open('Member is already in this group', 'OK', {
          duration: 4000,
        });
      }
      // Reset the value of the input
      this.myControl.setValue('');
      this.getGroupDetails();
    });
  }

  removeUserFromGroup(memberId) {

    this.groupsService.deleteGroupMember(this.groupId, memberId).subscribe(result => {
      // update the list
      this.getGroupDetails();
    });
  }

  viewAnsweredMembershipForm(answeredMembershipFormId) {

    // open modal to view answered membership form
    this.dialog.open(AnsweredMembershipFormComponent, {
      data: {
        answeredMembershipFormId
      },
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }
}
