import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { ClubService } from '../../../_services/club.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Inject } from '@angular/core';
import { PaymentService } from '../../../_services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipService } from '../../../_services/membership.service';



@Component({
  selector: 'app-pay-amount',
  templateUrl: './pay-amount.component.html',
  styleUrls: ['./pay-amount.component.scss']
})
export class PayAmountComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('cardInfo', {static: true}) cardInfo: ElementRef;
  @ViewChild('ibanInfo', {static: true}) ibanInfo: ElementRef;

  saveForLater = false;
  selectedPaymentOption = 'newCard';
  prevCardDetails;

  bankAccountEnabled;
  errorMessage;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  iban: any;
  clubCurrency: string;
  basicPaymentAmount = 0;
  totalPaymentAmount = 0;
  calculatedFee = 0;
  stripeFeeTaken = 0;
  vatOnStripeFee = 0;
  displayFee = false;

  stripePercentage = 0.014;
  platformAndStripePercentage = 0.025; // default it to UK & Ire rate
  platformPercentage = 0.011; // 1.1%
  stripePercentageEuroUK = 0.014; // 1.4% - VAT (21% or 23% in IRE) must then be calculated on this for irish clubs
  stripePercentageNonEuroUK = 0.029;
  platformAndStripePercentageEuroUK = 0.025; // 2.5% (1.1% + 1.4%) - eg. £100 / 0.97 = £103.09
  platformAndStripePercentageNonEuroUK = 0.039; // 3.9% (1% + 2.9%) - eg. £100 / 0.97 = £103.09
  transactionFee = 0.25; // 25 cents - In Ireland VAT must then be calculated on this for irish clubs
  irishVatRate = 0.23; // 21% or 23% - current irish vat

  isProcessing = false;
  displayPaymentForm = true;
  purchaseDetails;
  paymentIntent = { client_secret: '' };

  discountAmount = 0;
  discountName = '';
  discountCode = '';
  discountPercentOff = 0;

  selectedPaymentTimeframe = '';
  numPayments = 1;
  maxNumSplitPayments = 2;

  constructor(
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private clubService: ClubService,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<PayAmountComponent>,
    private spinner: NgxSpinnerService,
    private membershipService: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.clubCurrency = this.clubService.getClubCurrency(this.data.currentClub.country);
    this.basicPaymentAmount = this.data.paymentAmountSelected;
    this.selectedPaymentTimeframe = this.data.selectedPaymentTimeframe;

    //check if user has a specific stripe_customer and a payment method setup
    this.paymentService.getStripeCustomerDetails(this.data.currentClub.id).subscribe((res:any) => {
      if(res.customerPaymentMethods.data && res.customerPaymentMethods.data.length > 0){
        this.prevCardDetails = res.customerPaymentMethods.data[0];
        this.purchaseDetails.prevCardDetails = this.prevCardDetails;
      }
    });

    if(this.selectedPaymentTimeframe === 'splitPayment'){
      //number of payments that are recorded
      this.numPayments = 2;
      //max for the options to display
      this.maxNumSplitPayments = this.data.maxNumSplitPayments;
    }

    if (this.data.currentClub.country === 'Ireland' || this.data.currentClub.country === 'Ireland') {
      this.platformAndStripePercentage = this.platformAndStripePercentageEuroUK;
      this.stripePercentage = this.stripePercentageEuroUK;
    } else {
      this.platformAndStripePercentage = this.platformAndStripePercentageNonEuroUK;
      this.stripePercentage = this.stripePercentageNonEuroUK;
    }


    this.stripeFeeTaken = ((this.basicPaymentAmount / (1 - this.stripePercentage))
      + this.transactionFee) - this.basicPaymentAmount;

    if (this.data.currentClub.country === 'Ireland') {
      this.vatOnStripeFee = this.stripeFeeTaken * this.irishVatRate;
    }

    // Useful for debugging...
    // console.log('basicPaymentAmount: ' + this.basicPaymentAmount);
    // console.log('(1 - this.stripePercentage): ' + (1 - this.stripePercentage));
    // console.log('transactionFee: ' + this.transactionFee);

    // console.log('Stripe Percentage: ' + this.stripePercentage);
    // console.log('Stripe Fee taken: ' + this.transactionFee);

    // console.log('Stripe Fee taken: ' + this.stripeFeeTaken);
    // console.log('VAT: ' + this.vatOnStripeFee);
    // console.log('platformAndStripePercentage: ' + this.platformAndStripePercentage);

    // Only calculate fees for amounts requested (events)
    this.calculatedFee =  ((this.basicPaymentAmount / (1 - this.platformAndStripePercentage))
                            + this.transactionFee + this.vatOnStripeFee)
                            - this.basicPaymentAmount;

    this.calculateMembershipDiscounts();

  }

  ngAfterViewInit() {
    this.clubService.getClubFromApi(this.data.currentClub.id).subscribe(res => {
      if (this.data.paymentType === 'amount_requested') {

        if (res.club.payment_request_fee_added === 1) {
          this.displayFee = true;
          this.totalPaymentAmount = this.basicPaymentAmount + this.calculatedFee;
        } else {
          this.totalPaymentAmount = this.basicPaymentAmount;
        }

      } else {
        this.totalPaymentAmount = this.basicPaymentAmount;
      }

      this.checkIfBankAccountConnected(res.club);

      if (this.bankAccountEnabled) {
        this.createPurchaseDetails();
        this.createCard();
      }
    });
  }

  createCard() {
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '16px',
        color: '#32325d',
      },
    };

    const options = {
      style,
      supportedCountries: ['SEPA'],
      // If you know the country of the customer, you can optionally pass it to
      // the Element as placeholderCountry. The example IBAN that is being used
      // as placeholder will reflect the IBAN format of that country.
      placeholderCountry: 'DE',
    };

    // IBAN details - TO FINISH
    // this.iban = this.paymentService.elements.create('iban', options);
    // this.iban.mount(this.ibanInfo.nativeElement);
    // this.iban.addEventListener('change', this.cardHandler);

    // Card details
    this.card = this.paymentService.elements.create('card', {hidePostalCode: true});
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  // card validation
  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }


  onSubmit(form: NgForm) {

    this.purchaseDetails.selectedPaymentTimeframe = this.selectedPaymentTimeframe;
    this.purchaseDetails.numPayments = this.numPayments;
    this.purchaseDetails.discountCode = this.discountCode;

    this.spinner.show();
    this.isProcessing = true;
    this.errorMessage = '';
    
    if(this.selectedPaymentOption === 'prevCard' && this.prevCardDetails && this.prevCardDetails.id){
      this.paymentService.createPaymentIntent({ payment_method_id: this.prevCardDetails.id }, this.purchaseDetails)
      .subscribe(response => {
        this.handleServerResponse(response);
      });
    }else{
      this.paymentService.stripe.createPaymentMethod('card', this.card).then(result => {
        if (result.error) {
          this.spinner.hide();
          // Display error.message in your UI.
          this.errorMessage = result.error.message;
        } else {
          // Otherwise send paymentMethod.id to your server (see Step 2)
          if (this.data.paymentType === 'clubflow_subscription') {

            this.paymentService.createPaymentIntentCFSubscription({ payment_method_id: result.paymentMethod.id }, this.purchaseDetails)
            .subscribe(response => {
              this.handleServerResponse(response);
            });
          } else {
            this.paymentService.createPaymentIntent({ payment_method_id: result.paymentMethod.id }, this.purchaseDetails)
            .subscribe(response => {
              this.handleServerResponse(response);
            });
          }
        }
      });
    }
    this.isProcessing = false;
  }


  handleServerResponse(response) {

    if (response.error) {
      // Show error from server on payment form
      this.spinner.hide();
      // standard error - like card declined etc
      this.errorMessage = response.error;
    } else if (response.requires_action) {
       this.spinner.show();
      // Use Stripe.js to handle required card action

      // the following is for monthly/split payments SCA flow
      if (this.selectedPaymentTimeframe === 'monthlyPayment' || this.selectedPaymentTimeframe === 'splitPayment') {
        this.paymentService.stripe.confirmCardPayment(
          response.payment_intent_client_secret
        ).then(function(result) {

          if (result.error) {
            // Show error from stripe confirmCardPayment in payment form
            this.errorMessage = result.error.message;
          } else if (result.last_payment_error) {
            this.errorMessage = result.last_payment_error.message;

            // try to update the subscription with a different card
            // this.handleServerResponse(result);
            // debugger;
          } else {
            this.dialogRef.close('paymentSuccess');
            // The payment has succeeded. Display a success message.
            this.snackBar.open('Payment Completed Sucessfully', 'OK', {duration: 4000});
          }
          this.spinner.hide();
        }.bind(this));
      } else {
        // the following is for standard payments
        this.paymentService.stripe.handleCardAction(
          response.payment_intent_client_secret
        ).then(function(result) {
          if (result.error) {
            // Show error from stripe handleCardAction in payment form
            this.errorMessage = result.error.message;
          } else {
            this.spinner.show();
            this.paymentService.createPaymentIntent({ payment_intent_id: result.paymentIntent.id }, this.purchaseDetails)
            .subscribe(res => {
              this.handleServerResponse(res);
            });
          }
        }.bind(this));
      }
    } else {
        this.spinner.hide();
        this.dialogRef.close('paymentSuccess');
      // The payment has succeeded. Display a success message.
      this.snackBar.open('Payment Completed Sucessfully', 'OK', {duration: 4000});
    }
  }


  createPurchaseDetails() {
    this.purchaseDetails = {
      amount: this.basicPaymentAmount,
      clubId: this.data.currentClub.id,
      paymentType: this.data.paymentType,
      selectedPaymentOption: this.selectedPaymentOption,
    };
    if (this.data.paymentType === 'amount_requested') {
     this.purchaseDetails.eventId = 'all';

    } else if (this.data.paymentType === 'shop_purchase') {
      this.purchaseDetails.items_purchased = this.data.cartItems;
      this.purchaseDetails.shippingAddress = this.data.shippingAddress;
      this.purchaseDetails.shopType = this.data.shopType;

      this.purchaseDetails.shippingSelected = this.data.shippingSelected;
      this.purchaseDetails.shopType = this.data.shopType;

    } else if (this.data.paymentType === 'membership') {
      this.purchaseDetails.membersMemberships = this.data.membersMemberships;

    } else if (this.data.paymentType === 'lotto') {
      this.purchaseDetails.lottoPaymentDetails = this.data.lottoPaymentDetails;

    } else if (this.data.paymentType === 'booking') {
      this.purchaseDetails.bookingPaymentDetails = this.data.bookingPaymentDetails;
      this.purchaseDetails.saveForLater = this.saveForLater;
    }
  }


  calculateMembershipDiscounts() {
    if (this.data.paymentType === 'membership') {
      this.membershipService.getMembershipDiscounts().subscribe(result => {

        const memberMemberships = this.data.membersMemberships;
        const membershipsCountArray = [];

        memberMemberships.forEach(member => {

          // loop through all the memberships and count them all up
          member.memberGroups.forEach(membershipGroup => {
            if (membershipsCountArray[membershipGroup.membership.id] === undefined) {
              membershipsCountArray[membershipGroup.membership.id] = {
                'count' : 1
              };
            } else {
              membershipsCountArray[membershipGroup.membership.id].count =
                membershipsCountArray[membershipGroup.membership.id].count + 1;
            }
          });
        });

        const validMembershipDiscountArray = [];

        // loop through all the membership discounts and see which ones apply
        // apply the largest discount which was seen
        // record the discount on the reciept
        result.membershipDiscounts.forEach(membershipDiscount => {
          const membershipDiscountItemArray = [];
          let membershipDiscountSumOfItemAmounts = 0;
          let isValidDiscount = true;

            for (const discountItem of membershipDiscount.membership_discount_items) {

              if (typeof membershipsCountArray[discountItem.membership_id] !== 'undefined') {
                // then check if the count is at least 1 or equal to the count of memberships to be purchased
                if (membershipsCountArray[discountItem.membership_id]['count'] > 0 &&
                  discountItem.count <= membershipsCountArray[discountItem.membership_id]['count']) {

                    // then its a valid discount item
                    membershipDiscountSumOfItemAmounts += discountItem.membership.amount * discountItem.count;
                } else {
                    isValidDiscount = false;
                    break;
                }
              } else {
                  isValidDiscount = false;
                  break;
              }
            }

            // check if it can be applied
            // if it can be applied then add it to the valid discounts array
            if (isValidDiscount === true) {
              // Only apply the discount only to the selected items in the membershipDiscount array!!
              const discountAmount = membershipDiscountSumOfItemAmounts - membershipDiscount.amount;
              const validDiscount = {
                discountId: membershipDiscount.id,
                discountAmount,
                discountName : membershipDiscount.name
              };
              validMembershipDiscountArray[validDiscount.discountAmount] = validDiscount;
            }
        });

          // sort the valid discounts array by discount amount
          Object.keys(validMembershipDiscountArray).reduce((accumulator, currentValue) => {
            accumulator[currentValue] = validMembershipDiscountArray[currentValue];
            return accumulator;
          }, {});

          // apply the largest discount possible
          const largestDiscountAvailable = validMembershipDiscountArray.pop();
          if (largestDiscountAvailable) {
            this.discountAmount = largestDiscountAvailable.discountAmount;
            this.discountName = largestDiscountAvailable.discountName;
          }

          // apply any calculated membership discounts
          if (this.discountAmount > 0) {
            this.basicPaymentAmount = this.basicPaymentAmount - this.discountAmount;

            // need to update this at some point
            this.totalPaymentAmount = this.totalPaymentAmount - this.discountAmount;
            this.purchaseDetails.amount = this.basicPaymentAmount;
          }
      });
    }
  }


  checkIfBankAccountConnected(club) {
    if (club.stripe_user_id === null) {
      this.bankAccountEnabled = false;
    } else {
      this.bankAccountEnabled = true;
    }
  }


  getDiscountCodeDetails(discountCode) {
    this.paymentService.getDiscountCodeDetails(discountCode).subscribe(result => {

      if (result['percent_off'] && this.discountPercentOff === 0) {
        this.discountPercentOff = result['percent_off'] / 100;
        this.totalPaymentAmount = this.totalPaymentAmount * this.discountPercentOff;
      } else {
        this.discountCode = '';
      }
    });
  }



  paymentOptionSelected(cardType){
    this.selectedPaymentOption = cardType;
    this.purchaseDetails.selectedPaymentOption = cardType;
  }


  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }
}
