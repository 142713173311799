import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private url: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  sendNewsletter(newsletterData) {
    return this.http.post(`${this.url}/newsletters`, newsletterData)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
