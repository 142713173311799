import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  constructor() { }

  @Input() placeholderText: string;
  @Input() datePicked;
  @Input() isRequired: boolean;
  @Output() selectedDate = new EventEmitter();

  ngOnInit() {
  }

  dateSelected(newDate) {
    this.selectedDate.emit(newDate);
  }

}
