import { Component, ViewChild, AfterViewInit, Output, OnInit, ElementRef } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialog, SimpleSnackBar, MatSnackBar, MatPaginator, MatSort } from '@angular/material';
import { AddMemberComponent } from '../add-member/add-member.component';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { StatsService } from '../../../_services/stats.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { MemberService } from '../../../_services/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MemberDetailsComponent } from '../member-details/member-details.component';
import { merge, of as observableOf, Observable} from 'rxjs';
import { switchMap, startWith, map, catchError } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-member-lookup',
  templateUrl: './member-lookup.component.html',
  styleUrls: ['./member-lookup.component.scss']
})
export class MemberLookupComponent  implements AfterViewInit, OnInit {

  @ViewChild("searchInput", { static: false}) inputEl: ElementRef;


  club: any = {};
  allMembers;
  membersDisplayed;
  searchField;

  clubMemberStats = {
    allClubMembersCount: 0,
    activeClubMembersCount: 0,
    inactiveClubMembersCount: 0,
    activeLast24Hours: 0,
    activeLast7days: 0
  };


  memberName = '';
  currentMemberships = {membersOutstandingMembershipGroups: [], membersMembershipGroups:[]};
  userPayments = [];
  noMembershipSetup = false;
  noMembershipPaymentOutstanding = false;
  membershipPaymentOutstanding = false;

  clubCurrency;
  reminderBeingSent = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  resultsLength = 0;


  constructor(
    private clubService: ClubService,
    private memberService: MemberService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private aysService: AreYouSureService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private statsService: StatsService) { }

  ngOnInit() {}



  ngAfterViewInit() {
    this.club = this.clubService.getClub();
    this.clubCurrency = this.clubService.getClubCurrency();


    this.inputEl.nativeElement.focus();
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
        '<h3>Member Help</h3>' +
        '<ul id="admin-instructions">' +
        '<li>To add a member just click on the Add Member button below.</li>' +
        '<li>The member will automatically be sent an email informing them that your club is asking them to sign up.</li>' +
        '<li>If they register their account you will be able to see when they last logged in below.</li>' +
        '<li>You can send them a reminder email by clicking the "Signup Reminder" button if they forget/lose' +
        'their registration email.</li>' +
        '</ul>',
      },
      minWidth: '50vw',
      maxHeight: '95vh',
    });
  }
  

  searchCardId($event){
    const membershipCardId = $event.target.value;

    this.noMembershipSetup = false;
    this.noMembershipPaymentOutstanding = false;
    this.membershipPaymentOutstanding = false;

    this.memberService.getMemberDetailsByCardId(membershipCardId).subscribe( (result : any) => {

      this.memberName = result.first_name + ' ' + result.surname;
      this.currentMemberships = result.currentMemberships;
      this.userPayments = result.userPayments;

      //first check do we have the member in any membership groups
      if(this.currentMemberships.membersOutstandingMembershipGroups.length === 0 && 
        this.currentMemberships.membersMembershipGroups.length === 0){
          this.noMembershipSetup = true;
      }

      //then we check if there are any outstanding membership payments due
      if(this.currentMemberships.membersOutstandingMembershipGroups.length > 0 ){
        
        this.currentMemberships.membersOutstandingMembershipGroups.forEach(memberMembershipGroup => {

          if(memberMembershipGroup.memberGroups.length > 0){
            this.membershipPaymentOutstanding = true;
          }
        });
      }


      if(this.currentMemberships.membersMembershipGroups.length > 0 &&  this.membershipPaymentOutstanding === false){
        this.noMembershipPaymentOutstanding = true;
      }

    },error => {
      //no member found!
      this.memberName = '';
      this.currentMemberships = {membersOutstandingMembershipGroups: [], membersMembershipGroups:[]};
      this.userPayments = [];

      this.snackBar.open('No member found!', 'OK', {
        duration: 3000,
      });
    });

    $event.target.value = '';
    this.inputEl.nativeElement.focus();
  }





}
