import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../../_services/shop.service';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-shop-orders',
  templateUrl: './shop-orders.component.html',
  styleUrls: ['./shop-orders.component.scss']
})
export class ShopOrdersComponent implements OnInit {

  // date range measured in days
  dateRange = 90;

  displayedColumns = ['actions', 'user_name', 'email', 'amount_paid', 'created_at'];
  tableHeaders = [
    {title: 'Actions', getName: 'actions'},
    {title: 'Name', getName: 'user_name'},
    {title: 'Email', getName: 'email'},
    {title: 'Amount Paid', getName: 'amount_paid'},
    {title: 'Date Purchased', getName: 'created_at'}
  ];
  clubCurrency;
  retrievedShopOrders = false;

  constructor(private shopService: ShopService, private clubService: ClubService) { }
  shopOrders;

  ngOnInit() {
    this.getShopOrders();
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  getShopOrders() {
    this.shopOrders = this.shopService.getShopOrders(this.dateRange);
    this.retrievedShopOrders = true;
  }
}
