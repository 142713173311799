import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../_services/stats.service';
import { ClubService } from '../../../_services/club.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-event-reports',
  templateUrl: './event-reports.component.html',
  styleUrls: ['./event-reports.component.scss']
})
export class EventReportsComponent implements OnInit {

  constructor(
    private statsService: StatsService,
    private clubService: ClubService,
    private spinner: NgxSpinnerService
    ) { }
  totalAmountCollected = 0;
  totalAmountOutstanding = 0;
  debtorsList = [];
  combinedList = [];
  combinedListCSV = [];
  clubCurrency;

  displayedColumns = ['first_name', 'surname', 'member_amount_outstanding','outstanding_payment_requests'];
  tableHeaders = [
    {title: 'First Name', getName: 'first_name'},
    {title: 'Surname', getName: 'surname'},
    {title: 'Amount Outstanding', getName: 'member_amount_outstanding'},
    {title: 'Breakdown', getName: 'outstanding_payment_requests'}
  ];

  ngOnInit() {
    this.spinner.show();
    this.statsService.getPaymentRequestStats().subscribe( result => {
      this.totalAmountOutstanding = result.totalAmountOutstanding;

      this.combinedList = result.combinedList;
      this.combinedListCSV = result.combinedListCSV;
      this.spinner.hide();
    });

    this.clubCurrency = this.clubService.getClubCurrency();
  }

  printDebtors() {
    window.print();
  }

  downloadDebtors() {

      const data = this.combinedListCSV;

      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');

      const blob = new Blob([csvArray], {type: 'text/csv' });
      saveAs(blob, 'debtors.csv');
  }

}
