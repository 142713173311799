import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  MatButtonModule,
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatToolbarModule,
  MatSelectModule,
  MatDialogModule,
  MatTableModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatRadioModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSortModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatButtonToggleModule
} from '@angular/material';


import 'hammerjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';


import { routing } from './app-routing.module';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services/authentication.service';
import { AreYouSureService } from './_services/are-you-sure.service';
import { AppComponent } from './_components/app.component';
import { ClubRegisterComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        MemberRegisterComponent } from './_components/auth/index';
import { NavigationComponent } from './_components/navigation/navigation.component';
import { ClubService } from './_services/club.service';
import { AddMemberComponent } from './_components/admin-components/add-member/add-member.component';
import { AreYouSureComponent } from './_components/are-you-sure/are-you-sure.component';
import { OutstandingAmountsComponent } from './_components/admin-components/outstanding-amounts/outstanding-amounts.component';
import { RoleGuard } from './_guards/role.guard';
import { MemberHomeComponent } from './_components/member-components/member-home/member-home.component';
import { PayAmountComponent } from './_components/member-components/pay-amount/pay-amount.component';
import { LandingComponent } from './_components/landing/landing.component';
import { PaymentsComponent } from './_components/admin-components/payments/payments.component';
import { SimpleHttpInterceptor } from './http.interceptor';
import { ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MembersComponent } from './_components/admin-components/members/members.component';
import { PaymentService } from './_services/payment.service';
import { ContactComponent } from './_components/contact/contact.component';
import { LogCashPaymentComponent } from './_components/admin-components/log-cash-payment/log-cash-payment.component';
import { GroupsService } from './_services/groups.service';
import { AddEditGroupComponent } from './_components/admin-components/add-edit-group/add-edit-group.component';
import { MembershipService } from './_services/membership.service';
import { AddMembershipComponent } from './_components/admin-components/add-membership/add-membership.component';
import { OnboardingComponent } from './_components/admin-components/onboarding/onboarding.component';
import { AdminSideNavComponent } from './_components/admin-components/admin-side-nav/admin-side-nav.component';
import { StatsService } from './_services/stats.service';
import { PlatformDashboardComponent } from './_components/platform-admin-components/platform-dashboard/platform-dashboard.component';
import { GoogleAnalyticsService } from './_services/google-analytics.service';
import { ClubsComponent } from './_components/platform-admin-components/clubs/clubs.component';
import { ClubSettingsComponent } from './_components/admin-components/club-settings/club-settings.component';
import { ManageMemberUploadComponent } from './_components/admin-components/manage-member-upload/manage-member-upload.component';
import { ManageBROEEventUploadComponent } from './_components/admin-components/manage-broe-event-upload/manage-broe-event-upload.component';
import { ManageGroupUploadComponent } from './_components/admin-components/manage-group-upload/manage-group-upload.component';
import { DatePickerComponent } from './_components/common-components/date-picker/date-picker.component';
import { ShopItemsComponent } from './_components/admin-components/shop-items/shop-items.component';
import { AddEditShopItemComponent } from './_components/admin-components/add-edit-shop-item/add-edit-shop-item.component';
import { ClubShopComponent } from './_components/member-components/club-shop/club-shop.component';
import { ViewShopItemComponent } from './_components/member-components/view-shop-item/view-shop-item.component';
import { ViewCartComponent } from './_components/member-components/view-cart/view-cart.component';
import { MembershipFormsComponent } from './_components/member-components/membership-forms/membership-forms.component';
import { ShopSettingsComponent } from './_components/admin-components/shop-settings/shop-settings.component';
import { ShopOrdersComponent } from './_components/admin-components/shop-orders/shop-orders.component';
import { ShopOrderComponent } from './_components/admin-components/shop-order/shop-order.component';
import { MembershipGroupsComponent } from './_components/admin-components/membership-groups/membership-groups.component';
import { AddEditMembershipFormComponent } from './_components/admin-components/add-edit-membership-form/add-edit-membership-form.component';
import { MembershipFormOptionsComponent } from './_components/admin-components/membership-form-options/membership-form-options.component';
// tslint:disable-next-line:max-line-length
import { MembershipGroupMembersComponent } from './_components/admin-components/membership-group-members/membership-group-members.component';
// tslint:disable-next-line:max-line-length
import { AnsweredMembershipFormComponent } from './_components/admin-components/answered-membership-form/answered-membership-form.component';
import { MemberGroupsComponent } from './_components/admin-components/member-groups/member-groups.component';
import { AdminRolesComponent } from './_components/admin-components/admin-roles/admin-roles.component';
import { SelectMemberComponent } from './_components/admin-components/select-member/select-member.component';
import { MembershipDiscountsComponent } from './_components/admin-components/membership-discounts/membership-discounts.component';
// tslint:disable-next-line:max-line-length
import { AddEditMembershipDiscountComponent } from './_components/admin-components/add-edit-membership-discount/add-edit-membership-discount.component';
// tslint:disable-next-line:max-line-length
import { ManageMembershipUploadComponent } from './_components/admin-components/manage-membership-upload./manage-membership-upload.component';
// tslint:disable-next-line:max-line-length
import { SelectAnsweredMembershipFormComponent } from './_components/admin-components/select-answered-membership-form/select-answered-membership-form.component';
import { EventReportsComponent } from './_components/admin-components/event-reports/event-reports.component';
import { MembershipReportsComponent } from './_components/admin-components/membership-reports/membership-reports.component';
import { ReportsSummaryComponent } from './_components/admin-components/reports-summary/reports-summary.component';
import { NewsletterComponent } from './_components/admin-components/newsletter/newsletter.component';
// tslint:disable-next-line:max-line-length
import { MdcFormFieldModule, MdcTextFieldModule, MDCDataTableModule, MdcCardModule, MdcSelectModule, MdcListItem, MdcListModule, MdcCheckboxModule, MdcTabModule, MdcTabBarModule } from '@angular-mdc/web';
import { HelpModalComponent } from './_components/admin-components/help-modal/help-modal.component';
import { PrivacyPolicyComponent } from './_components/footer-pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './_components/footer-pages/terms-and-conditions/terms-and-conditions.component';
import { ShopReportsComponent } from './_components/admin-components/shop-reports/shop-reports.component';
import { ShopItemReportComponent } from './_components/admin-components/shop-item-report/shop-item-report.component';
import { MemberGroupComponent } from './_components/coach-components/member-group/member-group.component';
import { AdminMemberGroupComponent } from './_components/admin-components/admin-member-group/admin-member-group.component';
import { RefundComponent } from './_components/admin-components/refund/refund.component';
import { AddEditPaymentRequestComponent } from './_components/admin-components/add-edit-payment-request/add-edit-payment-request.component';
import { PaymentRequestsComponent } from './_components/admin-components/payment-requests/payment-requests.component';
import { PaymentRequestService } from './_services/payment-request.service';
// tslint:disable-next-line:max-line-length
import { ManagePaymentRequestUploadComponent } from './_components/admin-components/manage-payment-request-upload/manage-payment-request-upload.component';
import { AdminHomeComponent } from './_components/admin-components/admin-home/admin-home.component';
import { AddBankAccountComponent } from './_components/admin-components/add-bank-account/add-bank-account.component';
import { RegisterRoleComponent } from './_components/auth/register-role/register-role.component';
import { EditMembershipComponent } from './_components/admin-components/edit-membership/edit-membership.component';
import { MemberService } from './_services/member.service';
import { LinkInviteRegisterComponent } from './_components/auth/link-invite-register/link-invite-register.component';
import { AddInviteLinkComponent } from './_components/admin-components/add-invite-link/add-invite-link.component';
import { InvalidInviteComponent } from './_components/auth/invalid-invite/invalid-invite.component';
import { MemberRegisterInfoComponent } from './_components/auth/member-register-info/member-register-info.component';
import { LottoSettingsComponent } from './_components/admin-components/lotto-settings/lotto-settings.component';
import { LottoDrawsComponent } from './_components/admin-components/lotto-draws/lotto-draws.component';
import { PlayLottoComponent } from './_components/member-components/play-lotto/play-lotto.component';
import { CreateLottoComponent } from './_components/admin-components/create-lotto/create-lotto.component';
import { LottoEntriesComponent } from './_components/admin-components/lotto-entries/lotto-entries.component';
import { LottoDrawEntriesComponent } from './_components/admin-components/lotto-draw-entries/lotto-draw-entries.component';
import { ClubOnboardingDetailsComponent } from './_components/platform-admin-components/club-onboarding-details/club-onboarding-details.component';
import { MemberDetailsComponent } from './_components/admin-components/member-details/member-details.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ViewVideoComponent } from './_components/view-video/view-video.component';
import { BankAccountWarningComponent } from './_components/admin-components/bank-account-warning/bank-account-warning.component';
import { MembershipFormReportsComponent } from './_components/admin-components/membership-form-reports/membership-form-reports.component';
import { AffiliatesProgramComponent } from './_components/footer-pages/affiliates-program/affiliates-program.component';
import { MembershipPaymentOptionsComponent } from './_components/member-components/membership-payment-options/membership-payment-options.component';
import { PayMonthlyPlatformFeeComponent } from './_components/admin-components/pay-monthly-platform-fee/pay-monthly-platform-fee.component';
import { OngoingPaymentsComponent } from './_components/admin-components/ongoing-payments/ongoing-payments.component';
import { ClubPropertyComponent } from './_components/admin-components/club-property/club-property.component';
import { AddEditClubPropertyComponent } from './_components/admin-components/add-edit-club-property/add-edit-club-property.component';
import { ClubAssetBookingsComponent } from './_components/member-components/club-asset-bookings/club-asset-bookings.component';
import { SimpleTextFormComponent } from './_components/common-components/simple-text-form/simple-text-form.component';
import { RequestCancellationComponent } from './_components/member-components/request-cancellation/request-cancellation.component';
import { MemberLookupComponent } from './_components/admin-components/member-lookup/member-lookup.component';


@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    LoginComponent,
    ClubRegisterComponent,
    MemberRegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NavigationComponent,
    OutstandingAmountsComponent,
    AddEditPaymentRequestComponent,
    AddMemberComponent,
    AreYouSureComponent,
    MemberHomeComponent,
    PayAmountComponent,
    LandingComponent,
    PaymentsComponent,
    AdminSideNavComponent,
    MembersComponent,
    ContactComponent,
    LogCashPaymentComponent,
    ManagePaymentRequestUploadComponent,
    AddEditGroupComponent,
    PaymentRequestsComponent,
    MembershipGroupsComponent,
    AddMembershipComponent,
    EditMembershipComponent,
    OnboardingComponent,
    PlatformDashboardComponent,
    ClubsComponent,
    ClubSettingsComponent,
    MembershipGroupMembersComponent,
    ManageMemberUploadComponent,
    ManageBROEEventUploadComponent,
    ManageGroupUploadComponent,
    DatePickerComponent,
    ShopItemsComponent,
    ClubShopComponent,
    AddEditShopItemComponent,
    ViewShopItemComponent,
    ViewCartComponent,
    MembershipFormsComponent,
    ShopSettingsComponent,
    ShopOrdersComponent,
    ShopOrderComponent,
    AddEditMembershipFormComponent,
    MembershipFormOptionsComponent,
    AnsweredMembershipFormComponent,
    MemberGroupsComponent,
    AdminRolesComponent,
    SelectMemberComponent,
    MembershipDiscountsComponent,
    AddEditMembershipDiscountComponent,
    ManageMembershipUploadComponent,
    SelectAnsweredMembershipFormComponent,
    ReportsSummaryComponent,
    EventReportsComponent,
    MembershipReportsComponent,
    NewsletterComponent,
    HelpModalComponent,
    ShopReportsComponent,
    ShopItemReportComponent,
    MemberGroupComponent,
    AdminMemberGroupComponent,
    RefundComponent,
    AdminHomeComponent,
    AddBankAccountComponent,
    RegisterRoleComponent,
    LinkInviteRegisterComponent,
    AddInviteLinkComponent,
    InvalidInviteComponent,
    MemberRegisterInfoComponent,
    LottoSettingsComponent,
    LottoDrawsComponent,
    PlayLottoComponent,
    CreateLottoComponent,
    LottoEntriesComponent,
    LottoDrawEntriesComponent,
    ClubOnboardingDetailsComponent,
    MemberDetailsComponent,
    ViewVideoComponent,
    BankAccountWarningComponent,
    MembershipFormReportsComponent,
    AffiliatesProgramComponent,
    MembershipPaymentOptionsComponent,
    PayMonthlyPlatformFeeComponent,
    OngoingPaymentsComponent,
    ClubPropertyComponent,
    AddEditClubPropertyComponent,
    ClubAssetBookingsComponent,
    SimpleTextFormComponent,
    RequestCancellationComponent,
    MemberLookupComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FileUploadModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    MdcFormFieldModule,
    MdcTextFieldModule,
    MDCDataTableModule,
    MdcCardModule,
    MdcSelectModule,
    MdcListModule,
    MdcCheckboxModule,
    MatStepperModule,
    MdcTabModule,
    MdcTabBarModule,
    MomentTimezonePickerModule,
    NgxChartsModule,
    EditorModule,
    MatButtonToggleModule
  ],
  exports: [
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    ClubService,
    MemberService,
    AreYouSureService,
    PaymentService,
    GroupsService,
    MembershipService,
    StatsService,
    RoleGuard,
    GoogleAnalyticsService,
    PaymentRequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SimpleHttpInterceptor,
      multi: true
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ManagePaymentRequestUploadComponent,
    AddEditPaymentRequestComponent,
    AddMemberComponent,
    AreYouSureComponent,
    PayAmountComponent,
    LogCashPaymentComponent,
    AddEditGroupComponent,
    ContactComponent,
    AddMembershipComponent,
    ManageMemberUploadComponent,
    ManageBROEEventUploadComponent,
    ManageGroupUploadComponent,
    AddEditShopItemComponent,
    ViewShopItemComponent,
    ViewCartComponent,
    MembershipFormsComponent,
    AddEditMembershipFormComponent,
    MembershipFormOptionsComponent,
    AnsweredMembershipFormComponent,
    AddEditMembershipDiscountComponent,
    ManageMembershipUploadComponent,
    SelectAnsweredMembershipFormComponent,
    HelpModalComponent,
    RefundComponent,
    EditMembershipComponent,
    LottoSettingsComponent,
    CreateLottoComponent,
    ClubOnboardingDetailsComponent,
    MemberDetailsComponent,
    ViewVideoComponent,
    AddBankAccountComponent,
    MembershipPaymentOptionsComponent,
    PayMonthlyPlatformFeeComponent,
    AddEditClubPropertyComponent,
    SimpleTextFormComponent,
    RequestCancellationComponent]
})
export class AppModule {
  constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }
}
