import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { ManageMemberUploadComponent } from '../manage-member-upload/manage-member-upload.component';
import { ManageBROEEventUploadComponent } from '../manage-broe-event-upload/manage-broe-event-upload.component';
import { PaymentRequestService } from '../../../_services/payment-request.service';
import { ManagePaymentRequestUploadComponent } from '../manage-payment-request-upload/manage-payment-request-upload.component';

@Component({
  selector: 'app-add-edit-payment-request',
  templateUrl: './add-edit-payment-request.component.html',
  styleUrls: ['./add-edit-payment-request.component.scss']
})
export class AddEditPaymentRequestComponent implements OnInit {

  private readonly apiUrl = environment.apiUrl;
  uploader: FileUploader = new FileUploader({
    url : this.apiUrl + '/payment-requests/upload-payment-request-spreadsheet',
    additionalParameter: { 'clubId': this.data.currentClub.id}
  });
  action: String;
  clubId: number;
  addAllMembers = false;
  defaultAmount = 0;
  paymentRequestDate = '';
  warningMessage = '';
  errorMessage: String;
  missingMembersMessage =
  'The following members in the payment request you uploaded do not exist. ' +
  'Please create these members first with this form and then try to re-upload the payment request.';
  selectedItem = 'standard';
  loadingPaymentRequest = false;

  constructor(private clubService: ClubService,
              private paymentRequestService: PaymentRequestService,
              public dialogRef: MatDialogRef<AddEditPaymentRequestComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.action = this.data.action ? this.data.action : 'create';

    // file upload
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploader.onBeforeUploadItem = () => {
      this.loadingPaymentRequest = true;
    };

    this.uploader.onSuccessItem = (fileItem, response, status) => {
      this.loadingPaymentRequest = false;

      const parsedResponse = JSON.parse(response);

      if (parsedResponse.status === 'error') {
        this.errorMessage = parsedResponse.errorMessage;
      } else {

        // check if we need to create new users first before doing the event creation
        if (JSON.parse(response).membersDetails.length > 0) {
          // display uploaded file in a modal for admin to check
          const dialogRefMemberUpload = this.dialog.open(ManageMemberUploadComponent, {
            data: {
              memberData: parsedResponse,
              missingMembersMessage: this.missingMembersMessage
            },
            id: 'upload-members-modal',
            width: '90vw',
            maxHeight: '95vh'
          });

          dialogRefMemberUpload.afterClosed().subscribe( () => {
            // close the second dialog box
            this.dialogRef.close();
          });

        } else {

          let dialogRefPaymentRequestUpload;
          if (parsedResponse.paymentRequestType === 'broe') {
            // display uploaded file in a modal for admin to check
            dialogRefPaymentRequestUpload = this.dialog.open(ManageBROEEventUploadComponent, {
              data: {
                paymentRequestData: parsedResponse,
              },
              minWidth: '70vw',
              maxWidth: '95vw',
              maxHeight: '95vh',
            });
          } else {
            dialogRefPaymentRequestUpload = this.dialog.open(ManagePaymentRequestUploadComponent, {
              data: {
                paymentRequestData: parsedResponse,
              },
              id: 'upload-event-modal',
              width: '90vw',
              maxHeight: '95vh'
            });
          }
          dialogRefPaymentRequestUpload.afterClosed().subscribe( (paymentRequestId) => {

            // close the second dialog box
            this.dialogRef.close(paymentRequestId);
          });
        }
      }
    };
  }

  addPaymentRequest(paymentRequestName, paymentRequestDate) {
      const clubId = this.data.currentClub.id;

      this.paymentRequestService.createPaymentRequest(
        {
          name: paymentRequestName,
          paymentRequestDate: paymentRequestDate,
          addAllMembers: this.addAllMembers,
          defaultAmount: this.defaultAmount,
          paymentRequestType: 'standardManual'
        },
         clubId
        ).subscribe(result => {
        this.validate(result);

        if (this.warningMessage === '') {
          this.dialogRef.close(result.paymentRequest.id);
        }
      });
  }

  editPaymentRequest(paymentRequestName, paymentRequestDate) {
      this.data.paymentRequestDetails.name = paymentRequestName;

      if (paymentRequestDate instanceof Date) {
        this.data.paymentRequestDetails.date = paymentRequestDate.toISOString().slice(0, 10);
      }

      this.paymentRequestService.updatePaymentRequest(this.data.paymentRequestDetails).subscribe(result => {

        this.validate(result);

        if (this.warningMessage === '') {
          this.dialogRef.close();
        }
      });
  }


  validate(res) {
    // check for errors
    this.warningMessage = '';

    if (Array.isArray(res.name)) {
      this.warningMessage += res.name[0];
    } else if (Array.isArray(res.payment_request_date)) {
      this.warningMessage += res.payment_request_date[0];
    }
  }
}
