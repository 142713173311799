import { Injectable } from '@angular/core';
import { AreYouSureComponent } from '../_components/are-you-sure/are-you-sure.component';
import { MatDialog } from '@angular/material';

@Injectable()
export class AreYouSureService {

  constructor(private dialog: MatDialog) {}


  areYouSure(callBackFunction) {

    const areYouSure = this.dialog.open(AreYouSureComponent, {
      minWidth: '25vw',
      maxHeight: '95vh',
      maxWidth: '95vw',
    });


    areYouSure.afterClosed().subscribe(result => {
      if (result === true) {
        callBackFunction();
      }
    });
  }

}
