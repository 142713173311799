import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isAdmin$: Observable<Boolean>;
  userClubs$: Observable<any>;
  selectedClubId$: Observable<any>;
  selectedClub;
  isOnboarding = true;

  constructor(
    private authService: AuthenticationService,
    public router: Router
  ) { }

  ngOnInit() {
    this.isAdmin$ = this.authService.isAdmin;
    this.userClubs$ = this.authService.userClubs;
    this.selectedClubId$ = this.authService.selectedClubId;
  }

  changeClub(selectedClubId) {

    const newSelectedClubId = selectedClubId.value;
    this.authService.userSelectedClubId.next(newSelectedClubId);

    // update the selected details in local storage
    this.userClubs$.subscribe(clubsList => {

      clubsList.forEach(club => {
        if (parseInt(newSelectedClubId, 10) === club.id) {
          localStorage.setItem('club', JSON.stringify(club));

          // set if admin/super admin or not
          this.authService.checkAndUpdateUserRole(JSON.parse(localStorage.getItem('club')).pivot.role);

          // refresh current page
          location.reload();
        }
      });
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
