import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from './_guards/role.guard';

import { LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ClubRegisterComponent,
        MemberRegisterComponent } from './_components/auth/index';
import { AuthGuard } from './_guards/auth.guard';
import { OutstandingAmountsComponent } from './_components/admin-components/outstanding-amounts/outstanding-amounts.component';
import { MemberHomeComponent } from './_components/member-components/member-home/member-home.component';
import { LandingComponent } from './_components/landing/landing.component';
import { PaymentsComponent } from './_components/admin-components/payments/payments.component';
import { MembersComponent } from './_components/admin-components/members/members.component';
import { PlatformDashboardComponent } from './_components/platform-admin-components/platform-dashboard/platform-dashboard.component';
import { ClubsComponent } from './_components/platform-admin-components/clubs/clubs.component';
import { ClubSettingsComponent } from './_components/admin-components/club-settings/club-settings.component';
import { ShopItemsComponent } from './_components/admin-components/shop-items/shop-items.component';
import { ClubShopComponent } from './_components/member-components/club-shop/club-shop.component';
import { ShopSettingsComponent } from './_components/admin-components/shop-settings/shop-settings.component';
import { ShopOrdersComponent } from './_components/admin-components/shop-orders/shop-orders.component';
import { ShopOrderComponent } from './_components/admin-components/shop-order/shop-order.component';
import { MembershipGroupsComponent } from './_components/admin-components/membership-groups/membership-groups.component';
import { AdminRolesComponent } from './_components/admin-components/admin-roles/admin-roles.component';
import { MemberGroupsComponent } from './_components/admin-components/member-groups/member-groups.component';
import { AdminMemberGroupComponent } from './_components/admin-components/admin-member-group/admin-member-group.component';
import { MembershipDiscountsComponent } from './_components/admin-components/membership-discounts/membership-discounts.component';
import { EventReportsComponent } from './_components/admin-components/event-reports/event-reports.component';
import { MembershipReportsComponent } from './_components/admin-components/membership-reports/membership-reports.component';
import { NewsletterComponent } from './_components/admin-components/newsletter/newsletter.component';
import { PrivacyPolicyComponent } from './_components/footer-pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './_components/footer-pages/terms-and-conditions/terms-and-conditions.component';
import { ShopReportsComponent } from './_components/admin-components/shop-reports/shop-reports.component';
import { ShopItemReportComponent } from './_components/admin-components/shop-item-report/shop-item-report.component';
import { MemberGroupComponent } from './_components/coach-components/member-group/member-group.component';
import { PaymentRequestsComponent } from './_components/admin-components/payment-requests/payment-requests.component';
import { AdminHomeComponent } from './_components/admin-components/admin-home/admin-home.component';
import { OnboardingComponent } from './_components/admin-components/onboarding/onboarding.component';
import { RegisterRoleComponent } from './_components/auth/register-role/register-role.component';
// tslint:disable-next-line:max-line-length
import { MembershipGroupMembersComponent } from './_components/admin-components/membership-group-members/membership-group-members.component';
import { LinkInviteRegisterComponent } from './_components/auth/link-invite-register/link-invite-register.component';
import { InvalidInviteComponent } from './_components/auth/invalid-invite/invalid-invite.component';
import { MemberRegisterInfoComponent } from './_components/auth/member-register-info/member-register-info.component';
import { LottoSettingsComponent } from './_components/admin-components/lotto-settings/lotto-settings.component';
import { LottoDrawsComponent } from './_components/admin-components/lotto-draws/lotto-draws.component';
import { PlayLottoComponent } from './_components/member-components/play-lotto/play-lotto.component';
import { LottoEntriesComponent } from './_components/admin-components/lotto-entries/lotto-entries.component';
import { LottoDrawEntriesComponent } from './_components/admin-components/lotto-draw-entries/lotto-draw-entries.component';
import { MembershipFormReportsComponent } from './_components/admin-components/membership-form-reports/membership-form-reports.component';
import { AffiliatesProgramComponent } from './_components/footer-pages/affiliates-program/affiliates-program.component';
import { OngoingPaymentsComponent } from './_components/admin-components/ongoing-payments/ongoing-payments.component';
import { ClubPropertyComponent } from './_components/admin-components/club-property/club-property.component';
import { MemberLookupComponent } from './_components/admin-components/member-lookup/member-lookup.component';

const appRoutes: Routes = [
    { path: '', component: LandingComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register-role', component: RegisterRoleComponent },
    { path: 'member-register', component: MemberRegisterComponent },
    { path: 'invalid-invite', component: InvalidInviteComponent },
    { path: 'member-register-info', component: MemberRegisterInfoComponent },
    { path: ':club-name/member-signup-invite/:uuid', component: LinkInviteRegisterComponent },
    { path: 'club-register', component: ClubRegisterComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password/:token', component: ResetPasswordComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    { path: 'affiliates-program', component: AffiliatesProgramComponent },
    { path: ':club-name/:club-id/shop/:shop-type', component: ClubShopComponent},
    { path: ':club-id/shop/:shop-type/:club-name', component: ClubShopComponent},
    { path: 'play-lotto/:clubId/:clubName', component: PlayLottoComponent },
    { path: 'member-home', component: MemberHomeComponent, canActivate: [AuthGuard]},
    { path: ':club-name/:club-id/shop/:shop-type', component: ClubShopComponent, canActivate: [AuthGuard]},
    {   path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin', 'request_funds_admin', 'membership_admin']}
    },
    {   path: 'members',
        component: MembersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'request_funds_admin', 'membership_admin']}
    },
    {   path: 'newsletter',
        component: NewsletterComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin']}
    },
    {   path: 'payment-requests',
        component: PaymentRequestsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'request_funds_admin']}
    },
    {   path: 'shop-items',
        component: ShopItemsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'shop-order/:orderId',
        component: ShopOrderComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'shop-orders',
        component: ShopOrdersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'shop-settings',
        component: ShopSettingsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'outstanding-amounts/:paymentRequestId',
        component: OutstandingAmountsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'request_funds_admin']}
    },
    {   path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin', 'request_funds_admin', 'membership_admin']}
    },
    {   path: 'memberships',
        component: MembershipGroupsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'memberships/:membershipId',
        component: MembershipGroupMembersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'membership-discounts',
        component: MembershipDiscountsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'member-groups',
        component: MemberGroupsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['super_admin', 'platform_admin', 'admin', 'membership_admin']}
    },
    {   path: 'coach-group-view/:groupId',
        component: MemberGroupComponent,
        canActivate: [AuthGuard],
    },
    {   path: 'admin-group-view/:groupId',
        component: AdminMemberGroupComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'admin-home',
        component: AdminHomeComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin', 'request_funds_admin', 'membership_admin']}
    },
    {   path: 'club-property',
        component: ClubPropertyComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin']}
    },
    {   path: 'club-settings',
        component: ClubSettingsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['super_admin', 'platform_admin']}
    },
    {   path: 'admin-roles',
        component: AdminRolesComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['super_admin', 'platform_admin']}
    },
    {   path: 'payment-request-reports',
        component: EventReportsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'request_funds_admin']}
    },
    {   path: 'ongoing-payments',
        component: OngoingPaymentsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'membership-reports',
        component: MembershipReportsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'membership-form-reports',
        component: MembershipFormReportsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'membership_admin']}
    },
    {   path: 'shop-reports',
        component: ShopReportsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'shop-item-report/:shopItemName',
        component: ShopItemReportComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'lotto-administration',
        component: LottoDrawsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'lotto-entries/:lottoId',
        component: LottoEntriesComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'lotto-draw-entries/:lottoDrawId',
        component: LottoDrawEntriesComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin', 'shop_admin']}
    },
    {   path: 'member-lookup',
        component: MemberLookupComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['admin', 'super_admin', 'platform_admin']}
    },
    {   path: 'platform-dashboard',
        component: PlatformDashboardComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['platform_admin']}
    },
    {   path: 'platform-clubs',
        component: ClubsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRole: ['platform_admin']}
    },
    { path: '**', redirectTo: 'login' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' });
