import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatAutocompleteTrigger, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-member',
  templateUrl: './select-member.component.html',
  styleUrls: ['./select-member.component.scss']
})
export class SelectMemberComponent implements OnInit {

  groupMembers = [];
  filteredMembers;
  users;
  selectedUsers;

  groupName: String;
  @ViewChild(MatAutocompleteTrigger, {static: true}) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('userDetails', {static: true}) userDetails;
  myControl = new FormControl();
  @Input() inputList;
  @Output() listChanged = new EventEmitter();
  memberList;


  constructor(
    private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.inputList.subscribe(result => {
      this.memberList = result;
      this.getAllMembers();
    });
  }


  private _filter(first_name: String): string[] {

    let filterValue;
    if (first_name === '') {
      filterValue = '';
    } else {
      filterValue = first_name.toLowerCase();
    }
    return this.memberList.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }


  displayFn(user?): string | undefined {
    return user ? user.first_name : undefined;
  }


  getAllMembers() {
    this.filteredMembers = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.first_name),
      map(first_name => first_name ? this._filter(first_name) : this.memberList.slice())
    );
  }

  addMemberToList() {
    this.listChanged.emit(this.myControl.value);
  }
}
