import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { ManageMemberUploadComponent } from '../manage-member-upload/manage-member-upload.component';
import { MemberService } from '../../../_services/member.service';
import { MiscService } from '../../../_services/misc.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  action: String;
  emailType: String = 'member-email';
  memberAdditionSelection: String = 'single';
  parentName: String = '';
  affiliation_id: String = '';
  first_name: String;
  surname: String;
  dob;
  memberEmail: String;
  warningMessage: string;
  errorMessage: String;
  addMemberType: String;
  datePickerText = 'Member Date Of Birth';
  users = [];
  sameEmailAddressOwner = true;

  private readonly apiUrl = environment.apiUrl;
  uploader: FileUploader = new FileUploader({
    url : this.apiUrl + '/members/upload-members-spreadsheet',
    additionalParameter: { 'clubId': this.data.currentClub.id}
  });


  constructor(
    private clubService: ClubService,
    private miscService: MiscService,
    private memberService: MemberService,
    private dialogRef: MatDialogRef<AddMemberComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.action = this.data.action ? this.data.action : 'create';
    this.addMemberType =  this.data.createType ? this.data.createType : 'noneSelected';

    // Members file upload
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onSuccessItem = (fileItem, response, status) => {

      const parsedResponse = JSON.parse(response);
      if (parsedResponse.status === 'error') {
        this.errorMessage = parsedResponse.errorMessage;
      } else {

          // display uploaded file in a modal for admin to check
          const dialogRefMemberUpload = this.dialog.open(ManageMemberUploadComponent, {
            data: {
              memberData: parsedResponse,
            },
            id: 'upload-members-modal',
            minWidth: '70vw',
            maxWidth: '95vw',
            maxHeight: '95vh'
          });

          dialogRefMemberUpload.afterClosed().subscribe( () => {
            // close the second dialog box
            this.dialogRef.close();
          });
        }
    };
  }


  addMember() {
    const clubId = this.data.currentClub.id;
    this.warningMessage = '';

    // front end validation
    if (!this.first_name) {
      this.warningMessage += 'A member first name is required. ';
    }
    if (!this.surname) {
      this.warningMessage += 'A member surname is required. ';
    }
    if (this.users.length < 1) {
      this.warningMessage += 'An email is required. ';
    }

    this.checkUsersDetails(this.users);

    if (!this.warningMessage) {
      this.memberService.createMember({
          first_name: this.first_name,
          surname: this.surname,
          dob: this.dob,
          users: this.users,
          clubId: clubId,
          affiliation_id: this.affiliation_id,
        }).subscribe( result => {

          this.checkForErrorMessages(result, 'addMember');
      });
    }
  }

  checkForErrorMessages(result, type) {
    this.warningMessage = '';

    if (result.status === 'error') {
      // displaying of back end validation
      if (Array.isArray(result.errorMessages.email)) {
        this.warningMessage += ' ' + result.errorMessages.email[0];
      }
      if (Array.isArray(result.errorMessages.name)) {
        this.warningMessage += ' ' + result.errorMessages.name[0];
      }
    }

    if (!this.warningMessage) {
      if (type === 'addMember') {
        this.snackBar.open('An email has been sent to invite the member!', 'OK', {
          duration: 4000,
        });
      }

      this.dialogRef.close();
    }
  }


  editMember(memberDetails) {
    this.warningMessage = '';

    // front end validation
    if (memberDetails.first_name === '') {
      this.warningMessage += 'A member first name is required. ';
    }
    if (memberDetails.surname === '') {
      this.warningMessage += 'A member surname is required. ';
    }

    this.checkUsersDetails(memberDetails.users);

    if (!this.warningMessage) {
      this.memberService.updateMember(memberDetails).subscribe(result => {
        // displaying the backend validation
        this.checkForErrorMessages(result, 'editMember');
      });
    }
  }


  checkUsersDetails(users) {
    users.forEach( user => {

      if (user.email === '') {
        this.warningMessage += 'An email address is missing.';
      }
      if (user.first_name === '') {
        this.warningMessage += 'An email owner first name is required. ';
      }
      if (user.surname === '') {
        this.warningMessage += 'An email owner surname is required. ';
      }
    });
  }


  removeUser(usersArray, index) {

    // first check that at least one user is present
    if (usersArray.length > 1) {
      usersArray.splice(index, 1);
    } else {
      this.warningMessage = '';
      this.warningMessage += 'The member must have at least one email address at all times. ' +
                              'Please add another email address before deleting this one.';
    }
  }


  addUser() {
    let userOBj;

    if (this.action === 'create' && this.sameEmailAddressOwner && this.users.length < 1) {
       userOBj = {email: '', first_name: this.first_name  , surname: this.surname };
    } else {
       userOBj = {email: '', first_name: '', surname: ''};
    }

    if (this.action === 'create') {
      this.users.push(userOBj);
    } else {
      this.data.memberDetails.users.push(userOBj);
    }
  }

}
