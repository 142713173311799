import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MembershipService } from '../../../_services/membership.service';

@Component({
  selector: 'app-answered-membership-form',
  templateUrl: './answered-membership-form.component.html',
  styleUrls: ['./answered-membership-form.component.scss']
})
export class AnsweredMembershipFormComponent implements OnInit {

  constructor(
    private membershipServices: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  answeredFormDetails = {
    membership_form_name: '',
    created_at: '',
    answered_membership_form_questions: [],
    admin_created: 0
  };

  ngOnInit() {
    this.membershipServices.getAnsweredMembershipForm(this.data.answeredMembershipFormId).subscribe(result => {
      this.answeredFormDetails = result.answeredMembershipForm;
    });
  }

}
