import { Component, OnInit, Input } from '@angular/core';
import { GroupsService } from '../../../_services/groups.service';
import { ActivatedRoute } from '@angular/router';
import { ClubService } from '../../../_services/club.service';
import { MatAutocompleteTrigger, MatSnackBar, MatDialog } from '@angular/material';
import { ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { LogCashPaymentComponent } from '../log-cash-payment/log-cash-payment.component';
import { MembershipFormsComponent } from '../../member-components/membership-forms/membership-forms.component';
import { SelectAnsweredMembershipFormComponent } from '../select-answered-membership-form/select-answered-membership-form.component';
import { MembershipService } from '../../../_services/membership.service';
import { AddMemberComponent } from '../add-member/add-member.component';
import { MemberService } from '../../../_services/member.service';
import { SimpleTextFormComponent } from '../../common-components/simple-text-form/simple-text-form.component';

@Component({
  selector: 'app-membership-group-members',
  templateUrl: './membership-group-members.component.html',
  styleUrls: ['./membership-group-members.component.scss']
})
export class MembershipGroupMembersComponent implements OnInit {

  groupId: string;
  membershipAmount: number;
  membershipId: number;
  membershipFormId;
  reminderBeingSent = false;
  paymentOption;

  groupMembers = [];
  filteredMembers;
  users;
  selectedUsers;
  numPaid = 0;

  groupName: String;
  @ViewChild(MatAutocompleteTrigger, {static: true}) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('userDetails', {static: true}) userDetails;
  myControl = new FormControl();


  constructor(
    private membershipService: MembershipService,
    private memberService: MemberService,
    private groupsService: GroupsService,
    private route: ActivatedRoute,
    private clubService: ClubService,
    private aysService: AreYouSureService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.membershipId = params.membershipId;

      this.membershipService.getMembership(this.membershipId).subscribe(result => {
        this.groupId = result.membershipDetails.group_id;
        this.membershipAmount = result.membershipDetails.amount;
        this.membershipFormId = result.membershipDetails.membership_form_id;
        this.paymentOption = result.membershipDetails.payment_option;
        this.getGroupDetails();
      });
    });
    this.getAllMembers();
  }


  private _filter(first_name: String): string[] {

    let filterValue;
    if (first_name === '') {
      filterValue = '';
    } else {
      filterValue = first_name.toLowerCase();
    }
    return this.users.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }


  displayFn(user?): string | undefined {
    return user ? user.first_name + ' ' + user.surname : undefined;
  }


  getGroupDetails() {
    this.groupsService.getGroupMembers(this.groupId, 'membership').subscribe(results => {
      this.groupMembers = results.members;
      this.groupName = results.groupName;
      this.numPaid = results.numPaid;
    });
  }


  getAllMembers() {
    this.memberService.getAllMembers().subscribe(results => {
      this.users = results.allClubMembers;

      this.filteredMembers = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.first_name),
        map(first_name => first_name ? this._filter(first_name) : this.users.slice())
      );
    });
  }

  addUserToGroup() {
    this.groupsService.addGroupMember(this.groupId, this.myControl.value.id, 'member').subscribe(result => {
      if (result.status === 'exists') {
        this.snackBar.open('Member is already in this group', 'OK', {
          duration: 4000,
        });
      }
      // Reset the value of the input
      this.myControl.setValue('');
      this.getGroupDetails();
    });
  }

  removeUserFromGroup(memberId) {
    this.groupsService.deleteGroupMember(this.groupId, memberId).subscribe(result => {
      // update the list
      this.getGroupDetails();
    });
  }

  viewAnsweredMembershipForms(memberId) {

    // open modal to view answered membership form
    this.dialog.open(SelectAnsweredMembershipFormComponent, {
      data: {
        memberId
      },
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }


  fillMembershipForm(memberDetails) {
    const dialogRef = this.dialog.open(MembershipFormsComponent, {
      data: {
        membershipFormFiller: 'admin',
        membershipFormId: this.membershipFormId,
        paymentAmount: 0,
        memberId: memberDetails.id,
        membershipGroupName: this.groupName
      },
      maxWidth: '95vw',
      minWidth: '50vw',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      // update the list
      this.getGroupDetails();
    });
  }


  logCashPayment(memberDetails) {
    const dialogRef = this.dialog.open(LogCashPaymentComponent, {
      data: {
        cashPaymentType: 'membershipPayment',
        membershipId: this.membershipId,
        paymentFor: this.groupName, // the membership group name
        amountOwed: this.membershipAmount,
        memberName: memberDetails.first_name,
        memberId: memberDetails.id
      },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      // update the list
      this.getGroupDetails();
    });
  }


  sendReminderEmail() {
    this.reminderBeingSent = true;
    this.membershipService.postMembershipReminder({
      clubId: this.clubService.getClub().id,
      membershipId: this.membershipId,
    }).subscribe( result => {
      this.reminderBeingSent = false;
      this.snackBar.open('Reminder emails sent!', 'OK', {
        duration: 4000,
      });
    });
  }


  sendSingleReminderEmail(memberDetails) {

    this.reminderBeingSent = true;
    this.membershipService.postSingleMembershipReminder({
      clubId: this.clubService.getClub().id,
      membershipId: this.membershipId,
      memberId: memberDetails.id,
    }).subscribe( result => {
      this.reminderBeingSent = false;
      this.snackBar.open('Reminder email sent!', 'OK', {
        duration: 4000,
      });
    });
  }


  uploadUsersToGroup() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      data: {
        currentClub: this.clubService.getClub(),
        createType: 'bulkMemberUpload'
      },
      maxWidth: '95vw',
      minWidth: '50vw',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      // update the list
      this.getGroupDetails();
    });
  }


  addNoteToGroupMember(member) {
    const noteDialog = this.dialog.open(SimpleTextFormComponent, {
      maxWidth: '95vw',
      minWidth: '50vw',
      maxHeight: '95vh',
      data: member.notes
    });

    noteDialog.afterClosed().subscribe(modalCloseResult => {
      if (modalCloseResult !== undefined) {
        // save the note to the group_members table
        this.groupsService.updateGroupMemberNotes(this.groupId, member.id, modalCloseResult).subscribe(savedNoteResult => {
          member.notes = modalCloseResult;
        });
      }
    });
  }
}
