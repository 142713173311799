import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PaymentService } from '../../../_services/payment.service';
import { ClubService } from '../../../_services/club.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountComponent implements OnInit {

  constructor(private clubService: ClubService,
    private paymentService: PaymentService,
    private authService: AuthenticationService,
    private router: Router,
    @Inject(DOCUMENT) private document: any) { }

    stripeRedirectUrl: string;
    stripeClientId: string;
    clubStatus: string;
    currentClub;
    inOnboarding;

    ngOnInit() {
      this.stripeRedirectUrl =  environment.apiUrl + '/setup-stripe-account';
      this.stripeClientId = environment.stripeClientId;
      this.currentClub = this.clubService.getClub();
      this.clubStatus = this.clubService.getClub().clubStatus;

      if (this.router.url === '/onboarding') {
        this.inOnboarding = true;
      } else {
        this.inOnboarding = false;
      }
    }

  addBankAccount() {
    this.paymentService.createStripeState(this.currentClub.id).subscribe(response => {
        const state = response.state;
        this.authService.logout();
        this.document.location.href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id='
        + this.stripeClientId + '&scope=read_write&state=' + state
        + '&redirect_uri=' + this.stripeRedirectUrl;
    });
  }

}
