import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatAutocompleteTrigger, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { Inject } from '@angular/core';
import { MembershipService } from '../../../_services/membership.service';
import { FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-add-edit-membership-discount',
  templateUrl: './add-edit-membership-discount.component.html',
  styleUrls: ['./add-edit-membership-discount.component.scss']
})
export class AddEditMembershipDiscountComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {static: true}) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private clubService: ClubService,
    private membershipsService: MembershipService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AddEditMembershipDiscountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  availableMemberships = [];
  selected_memberships = [];
  errorMessage = '';
  clubCurrency;

  membershipDiscount = this.fb.group({
    id: new FormControl(0),
    amount: new FormControl(),
    name: new FormControl(),
    membership_discount_items: new FormArray([
      this.fb.group({
        membership_id: [''],
        count: ['']
      })
    ])
  });


  ngOnInit() {
    this.clubCurrency = this.clubService.getClubCurrency();
    this.membershipsService.getMemberships().subscribe( result => {
      this.availableMemberships = result.memberships;

      if (this.data.membershipDiscount) {
        const selectedMembershipDiscount = this.data.membershipDiscount;

        const membership_discount_items = this.fb.array(selectedMembershipDiscount.membership_discount_items.map(
          membershipDiscountItem => this.fb.group(membershipDiscountItem)
          ));

        this.membershipDiscount = this.fb.group({
            id: new FormControl(selectedMembershipDiscount.id),
            amount: new FormControl(selectedMembershipDiscount.amount),
            name: new FormControl(selectedMembershipDiscount.name),
            membership_discount_items: membership_discount_items
        });
      }
    });
  }


  onSubmit() {
    const itemId = this.membershipDiscount.value.id;

    const uploadData = new FormData();
    uploadData.append('membership_discount_items', JSON.stringify(this.membershipDiscount.get('membership_discount_items').value));
    uploadData.append('amount', this.membershipDiscount.get('amount').value);
    uploadData.append('name', this.membershipDiscount.get('name').value);

    this.errorMessage = '';
    if (itemId !== 0) {
      this.membershipsService.updateMembershipDiscount(itemId, uploadData).subscribe(result => {
        if (result.status === 'ok') {
          this.dialogRef.close();
        }
      }, response => {
        // this.displayErrorMessages(response);
      });
    } else {
      this.membershipsService.createMembershipDiscount(uploadData).subscribe(result => {
        if (result.status === 'ok') {
          this.dialogRef.close();
        }
      }, response => {
        // this.displayErrorMessages(response);
      });
    }
  }


  addRow() {
    (this.membershipDiscount.get('membership_discount_items') as FormArray).push(this.fb.group({
      membership_id: [''],
      count: ['']
    }));
    this.cdRef.detectChanges();
  }


  removeRow(index) {
    this.errorMessage = '';

    if ((this.membershipDiscount.get('membership_discount_items') as FormArray).length > 1) {
      (this.membershipDiscount.get('membership_discount_items') as FormArray).removeAt(index);

    } else {
      this.errorMessage = 'At least one membership must be selected';
    }
  }
}
