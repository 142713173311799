import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MemberService } from '../../../_services/member.service';
import { ClubService } from '../../../_services/club.service';
import { MiscService } from '../../../_services/misc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../_services/authentication.service';


@Component({
  selector: 'app-add-invite-link',
  templateUrl: './add-invite-link.component.html',
  styleUrls: ['./add-invite-link.component.scss']
})
export class AddInviteLinkComponent implements OnInit {


    // membership link invite
    linkApprovalType = 'auto';
    inviteLink = '';
    currentValidInviteLink = { type: '', expiry: ''};
    inviteLinkExpiryNumWeeks = 4;
    createStep = 1;



  constructor(
    private spinner: NgxSpinnerService,
    private clubService: ClubService,
    private authService: AuthenticationService,
    private miscService: MiscService,
  ) { }

  ngOnInit() {
    this.spinner.show();

    // check if there is a current valid invite link
    const club = this.clubService.getClub();
    this.authService.getLatestInviteLink(club.id).subscribe(result => {
      if (result.status === 'ok') {
        this.currentValidInviteLink = result.inviteLinkDetails;
        this.inviteLink = environment.baseUrl + result.partialLink;
      }
      this.spinner.hide();
    });
  }


  createMembersInviteLink() {
    const club = this.clubService.getClub();
    this.authService.createMembersInviteLink(club.id, this.linkApprovalType, this.inviteLinkExpiryNumWeeks).subscribe(result => {
      this.inviteLink = environment.baseUrl + result.partialLink;
      this.currentValidInviteLink = result.inviteLinkDetails;
    });
  }

  copyToClipboard() {
    this.miscService.copyToClipboard(this.inviteLink, 'Invite link copied!');
  }
}
