import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MiscService } from '../../_services/misc.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  private url: string = environment.apiUrl;
  private headers = new Headers({ 'Content-Type': 'application/json' });

  public sendingMessage = false;
  errorMessage = '';

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<ContactComponent>,
    private miscService: MiscService
  ) { }

  ngOnInit() {
  }

  public onSubmit(values: any) {

    if (values.email === '') {
      this.errorMessage = 'Please add an email address!';
    } else if (values.message === '') {
      this.errorMessage = 'Please add a message for us to read!';
    } else {
      this.sendingMessage = true;

      this.miscService.postContactMessage(values).subscribe(
        res => {
          this.snackBar.open('Your message has been sent to us!', '', {
            duration: 3000,
          });
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.errorMessage = '';
          this.sendingMessage = false;
        },
        error => {
          this.sendingMessage = false;
          this.errorMessage = error._body;
        });
    }
  }

}
