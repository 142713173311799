import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  stripe;
  elements;
  private readonly apiUrl = environment.apiUrl;
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    this.loadStandardStripeDetails();
  }

  loadStandardStripeDetails() {
      // get the connect account id from the current club
      const currentClub = JSON.parse(localStorage.getItem('club'));
      const acct_connectedAcct = currentClub.stripe_user_id !== null ? currentClub.stripe_user_id : '';

      if (environment.production) {
        this.stripe = Stripe('pk_live_TlDAi9lqbwjX6yyvKyub9TlW', {stripeAccount: acct_connectedAcct});
      } else {
        this.stripe = Stripe('pk_test_6SKVL6UqRF1FTlLcJY8i0tnB', {stripeAccount: acct_connectedAcct});
      }
      this.elements = this.stripe.elements();
  }


  logCashPayment(paymentDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/log-cash-payment', {
      cashPaymentType: paymentDetails.cashPaymentType, // either eventPayment or membershipPayment
      paymentFor: paymentDetails.paymentFor,
      memberId: paymentDetails.memberId,
      clubId: paymentDetails.clubId,
      payableItemId: paymentDetails.payableItemId, // either event id or membership id
      cashAmount: paymentDetails.cashAmount,
      metaData: paymentDetails.metaData
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  refundFullPayment(paymentDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/refund-full-payment', {
      clubId: paymentDetails.club_id,
      paymentId: paymentDetails.id,
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  refundPaymentItems(paymentDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/refund-payment-items', {
      memberId: paymentDetails.member_id,
      clubId: paymentDetails.club_id,
      paymentId: paymentDetails.id,
      paymentItems: paymentDetails.payment_items
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  refundSpecificAmount(paymentDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/refund-amount', {
      clubId: paymentDetails.club_id,
      specificRefundAmount: paymentDetails.specificRefundAmount,
      paymentId: paymentDetails.id,
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  refundEventPayment(memberDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/refund-event-payment', {
      memberId: memberDetails.member_id,
      clubId: memberDetails.club_id,
      paymentRequestId: memberDetails.payment_request_id,
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  createStripeState(clubId): Observable<any> {
    return this.http.post(this.apiUrl + '/save-stripe-state', {clubId})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  createPaymentIntentCFSubscription(paymentDetails, metadata): Observable<any> {
    return this.http.post(this.apiUrl + '/create-payment-intent-cf-subscription', {
      paymentDetails,
      metadata
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }

  cancelCFStripeSubscription(clubSubscriptionId): Observable<any> {
    return this.http.delete(this.apiUrl + '/cancel-cf-subscription/' + clubSubscriptionId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getClubCFSubscriptionPayments(clubId): Observable<any> {
    return this.http.get(this.apiUrl + '/cf-subscription-payments/' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }

  getDiscountCodeDetails(discountCode) {
    return this.http.get(this.apiUrl + '/discount-code-details/' + discountCode)
      .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


  getStripeCustomerDetails(clubId) {
    return this.http.get(this.apiUrl + '/get-stripe-customer-details?clubId=' + clubId )
      .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


  createPaymentIntent(paymentDetails, metadata): Observable<any> {
    return this.http.post(this.apiUrl + '/create-payment-intent', {
      paymentDetails,
      metadata
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }

  getStripeSubscriptions(clubId, type: string = 'activeIncompleteOrPaused'): Observable<any> {
    return this.http.get(this.apiUrl + '/stripe-subscriptions/' + clubId + '?type=' + type)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateStripeSubscription(subscriptionId, clubId, command): Observable<any> {
    return this.http.post(this.apiUrl + '/update-stripe-subscription/' + subscriptionId, {clubId, command})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  cancelStripeSubscription(subscriptionId, clubId): Observable<any> {
    return this.http.delete(this.apiUrl + '/stripe-subscriptions/' + subscriptionId + '?clubId= ' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  // used to change between paused/resumed
  updateAllClubSubscriptions(clubId, command): Observable<any> {
    return this.http.post(this.apiUrl + '/update-all-club-subscriptions', {clubId, command})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

}
