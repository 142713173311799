import { Component, OnInit } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialog } from '@angular/material';
import { AddEditMembershipDiscountComponent } from '../add-edit-membership-discount/add-edit-membership-discount.component';
import { MembershipService } from '../../../_services/membership.service';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  selector: 'app-membership-discounts',
  templateUrl: './membership-discounts.component.html',
  styleUrls: ['./membership-discounts.component.scss']
})
export class MembershipDiscountsComponent implements OnInit {


  displayedColumns = ['name', 'membership_discount_items', 'amount', 'actions'];
  tableHeaders = [
    {title: 'Discount Name', getName: 'name'},
    {title: 'Memberships', getName: 'membership_discount_items'},
    {title: 'New Amount To Pay', getName: 'amount'},
    {title: 'Actions', getName: 'actions'}
  ];
  clubCurrency;

  constructor(
    private membershipService: MembershipService,
    private clubService: ClubService,
    private aysService: AreYouSureService,
    private dialog: MatDialog) { }
  membershipDiscounts = [];

  ngOnInit() {
    this.getMembershipDiscounts();
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
          '<h3>Membership Discount Help</h3>' +
          '<ul id="admin-instructions">' +
          '<li>Only one discount will be applied to each cart at a time. If two valid discounts can be applied the system' +
          'will apply the larger discount.</li>' +
          '<li>The discount applied will appear on the email/dashboard receipt, as well as the Payments Made screen.</li>' +
          '</ul>'
      },
      minWidth: '35vw'
    });
  }

  createDiscount() {
    const dialogRef = this.dialog.open(AddEditMembershipDiscountComponent, {
      maxHeight: '95vh',
      maxWidth: '95vh',
      id: 'add-discount-modal',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMembershipDiscounts();
    });
  }


  editDiscount(membershipDiscount) {
    const dialogRef = this.dialog.open(AddEditMembershipDiscountComponent, {
      maxHeight: '95vh',
      maxWidth: '95vh',
      id: 'add-discount-modal',
      data: {
        membershipDiscount
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMembershipDiscounts();
    });
  }


  deleteDiscount(id) {
    this.membershipService.deleteMembershipDiscount(id).subscribe( results => {
      this.getMembershipDiscounts();
    });
  }


  getMembershipDiscounts() {
    this.membershipService.getMembershipDiscounts().subscribe( results => {
      this.membershipDiscounts = results.membershipDiscounts;
    });
  }
}
