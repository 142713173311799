import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { MatAutocompleteTrigger, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { Inject } from '@angular/core';
import { MembershipService } from '../../../_services/membership.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembershipFormOptionsComponent } from '../membership-form-options/membership-form-options.component';
import { AddEditMembershipFormComponent } from '../add-edit-membership-form/add-edit-membership-form.component';

@Component({
  selector: 'app-add-membership',
  templateUrl: './add-membership.component.html',
  styleUrls: ['./add-membership.component.scss']
})
export class AddMembershipComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private clubService: ClubService,
    private membershipsService: MembershipService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddMembershipComponent>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  membership = {
    id: 0,
    name: '',
    paymentOption: 'singlePayment',
    maxNumPayments: 1, // 1 is the default
    amount: 0,
    start_date: '',
    end_date: '',
    membership_form: {}
  };
  customQuestion;
  clubCurrency;
  groups = [];
  filteredUsers: Observable<string[]>;
  myControl = new FormControl();
  selectedGroupId = [];
  yearlyOption = false;
  monthlyOption = true;



  selectedMembershipFormName = 'No Form Selected/Created';

  // error messages
  nameErrorMessage = '';
  amountErrorMessage = '';
  startDateErrorMessage = '';
  endDateErrorMessage = '';



  ngOnInit() {
    if (this.data.membership) {
      this.membership = this.data.membership;
    }
    this.clubCurrency = this.clubService.getClubCurrency();
  }


  createMembershipForm() {
    const dialogRef = this.dialog.open(AddEditMembershipFormComponent, {
      data: {
        membership: this.data.membership
      },
      id: 'create-membership-form-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(membershipForm => {
      this.selectedMembershipFormName = membershipForm.name;
      this.membership.membership_form = membershipForm;
    });
  }



  selectExistingMembershipForm() {
    const dialogRef = this.dialog.open(MembershipFormOptionsComponent, {
      data: {
        currentClub: this.clubService.getClub(),
        membership: this.membership
      },
      id: 'membership-form-options-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(membershipForm => {
      this.selectedMembershipFormName = membershipForm.name;
    });
  }



  submitBasicMembership(stepper) {

    if (this.membership.id !== 0) {
      this.membershipsService.updateMembership(this.membership).subscribe(result => {
        stepper.next();
      });

    } else {

      this.membershipsService.createMembership(this.membership).subscribe(result => {

        if (result.errors) {
          if (result.errors.name) {
            this.nameErrorMessage = result.errors.name[0];
          }
          if (result.errors.amount) {
            this.amountErrorMessage = result.errors.amount[0];
          }
          if (result.errors.start_date) {
            this.startDateErrorMessage = result.errors.start_date[0];
          }
          if (result.errors.end_date) {
            this.endDateErrorMessage = result.errors.end_date[0];
          }
        } else {
          this.membership = result.membership;
          stepper.selected.completed = true;
          stepper.next();
        }
      });
    }
  }
}
