import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
