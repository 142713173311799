import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { ClubService } from './club.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class MembershipFormService {

  private readonly apiUrl = environment.apiUrl;

  constructor(
    // private clubService: ClubService,
    private http: HttpClient) {}


  getAllMembershipFormAnswers(clubId, membershipFormId, fromDate, toDate): Observable<any> {
    return this.http.get(
        this.apiUrl + '/all-membership-form-answers/' + membershipFormId +
        '?fromDate=' + fromDate +
        '&toDate=' + toDate +
        '&clubId=' + clubId
      ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  /*
  * Getting both the old (deleted) and current membership forms
  */
  getAllAnsweredMembershipForms(clubId, fromDate, toDate): Observable<any> {
    return this.http.get(this.apiUrl + '/all-answered-membership-forms/' + clubId + '?fromDate=' + fromDate + '&toDate=' + toDate)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  exportAnsweredMembershipForms(clubId, membershipFormId, fromDate, toDate): Observable<any> {
    return this.http.get(
        this.apiUrl + '/export-membership-form-answers/' + membershipFormId +
        '?fromDate=' + fromDate +
        '&toDate=' + toDate +
        '&clubId=' + clubId
      ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
