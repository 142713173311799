import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ClubAssetService {

  constructor(private http: HttpClient) { }

  private readonly apiUrl = environment.apiUrl;


  createClubAsset(assetDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/club-assets', assetDetails)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateClubAssets(assetDetails): Observable<any> {
    return this.http.put(this.apiUrl + '/club-assets/' + assetDetails.id, {assetDetails})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteClubAsset(assetDetails): Observable<any> {
    return this.http.delete(this.apiUrl + '/club-assets/' + assetDetails.id + '?clubId=' + assetDetails.club_id)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getClubAssets(clubId, createEditMode): Observable<any> {

    return this.http.get(this.apiUrl + '/club-assets' + '?clubId=' + clubId + '&createEditMode=' + createEditMode)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
