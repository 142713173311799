import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../_services/admin.service';
import { Observable } from 'rxjs';
import { UserService } from '../../../_services/user.service';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.scss']
})
export class AdminRolesComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private adminService: AdminService,
    private aysService: AreYouSureService,
    private snackBar: MatSnackBar,
    private userService: UserService) {}

  admins: Observable<Object>;
  usersList: Observable<Object>;

  ngOnInit(): void {
    // get all current admins
    this.getAdmins();

    // get all the available users
    this.usersList = this.userService.getClubUsers();
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
          '<h3>Admin Roles Help</h3>' +
          '<ul id="instructions">' +
          '<li>Super Admin - Has access to do everything on the system.</li>' +
          '<li>General Admin - Can access everything except: Admin Roles & Club Settings.</li>' +
          '<li>Membership Only Admin - Can only access: Members, Payments Made, Memberships, Member Groups, Membership' +
          'Reports.</li>' +
          '<li>Shop Only Admin - Can only access: Shop Items, Shop Orders, Shop Settings, Payments Made.</li>' +
          '<li>Payment Request Only Admin - Can only access: Members, Payment Requests, Payment Request Reports, Payments Made.</li>' +
          '</ul>' +
          '<p>Please Note - The member who\'s role has changed must log out and log back in for new role to take effect.</p>'
      },
      minWidth: '35vw'
    });
  }

  getAdmins() {
    this.admins = this.adminService.getClubAdmins();
  }

  addUserAsAdmin(user) {
    this.adminService.updateAdminRole(user.id, 'admin').subscribe(result => {
      this.getAdmins();
    });
  }

  removeUserAsAdmin(user) {
    this.adminService.updateAdminRole(user.id, 'user').subscribe(result => {
      this.checkForError(result);
      this.getAdmins();
    });
  }

  updateUserRole(user, role) {
    this.adminService.updateAdminRole(user.user_id, role).subscribe(result => {
      this.checkForError(result);
      this.getAdmins();
    });
  }

  checkForError(result) {
    if (result.status === 'error') {
      this.snackBar.open(result.message, 'OK', {
        duration: 5000,
      });
    }
  }
}
