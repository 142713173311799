import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Inject } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { debounceTime } from 'rxjs/operators';
import { ViewCartComponent } from '../view-cart/view-cart.component';
@Component({
  selector: 'app-view-shop-item',
  templateUrl: './view-shop-item.component.html',
  styleUrls: ['./view-shop-item.component.scss']
})
export class ViewShopItemComponent implements OnInit {

  shopItem = {
    id: 0,
    name: '',
    price: 0,
    images: {},
    description: '',
    quantitySelected: 0,
    stock_sizes: [],
    value: {},
    type: '',
    limited_stock: 0,
    stock_count: 0,
    total: 0,
    selectedSize: { id: 0 },
  };

  clubCurrency;

  constructor(
    private clubService: ClubService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ViewShopItemComponent>,
    public cartDialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}


  ngOnInit() {
    this.shopItem = this.data.itemDetails;
    if (this.shopItem.limited_stock === 1) {
      this.shopItem.stock_sizes = this.filterStockSizes();
    }
    this.shopItem.selectedSize = this.shopItem.stock_sizes ? this.shopItem.stock_sizes[0] : {};

    this.clubCurrency = this.clubService.getClubCurrency(this.data.clubDetails.country);
  }

  addToCart(nextStep?) {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];

    const existingItemIndex = cart.findIndex( item =>
      item.id === this.shopItem.id && (item.selectedSize ? item.selectedSize.id === this.shopItem.selectedSize.id : true)
      );

    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantitySelected += 1;
      cart[existingItemIndex].total +=  this.shopItem.price;
    } else {
      this.shopItem.quantitySelected = 1;
      this.shopItem.total =  this.shopItem.price;
      cart.push(this.shopItem);
    }
    localStorage.setItem('cart', JSON.stringify(cart));

    if (nextStep === 'buyNow') {
      this.dialogRef.close(nextStep);
    } else {
      this.snackBar.open('Item added to cart!', 'OK', {duration: 2000});
    }
  }

  filterStockSizes() {
    return this.shopItem.stock_sizes.filter( stockSize => {
      return stockSize.stock_count > 0;
    });
  }
}
