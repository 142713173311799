import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ClubService } from './club.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private clubService: ClubService) { }

  completeGroupUpload(clubId, newGroupDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/groups/complete-group-upload', {
        clubId,
        newGroupDetails,
    }).pipe(
            map((response: Response) => {
                return response;
            })
        );
}

  getGroupMembers(groupId, type = '', memberType = ''): Observable<any> {
    return this.http.get(this.apiUrl + '/groups/' + groupId + '?type=' + type + '&memberType=' + memberType)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  addGroupMember(groupId, memberId, memberType): Observable<any> {
    return this.http.post(this.apiUrl + '/groups/' + groupId  + '/member', {memberId, memberType})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteGroupMember(groupId, groupMemberId): Observable<any> {
    return this.http.delete(this.apiUrl + '/groups/' + groupId + '/member/' + groupMemberId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateGroupMemberNotes(groupId, memberId, groupMemberNotes): Observable<any> {

    return this.http.post(this.apiUrl + '/groups/' + groupId  + '/member/' + memberId, {groupMemberNotes})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }



  getGroups(clubId, type = ''): Observable<any> {
    return this.http.get(this.apiUrl + '/groups?clubId=' + clubId + '&type=' + type)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  createGroup(group): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.post(this.apiUrl + '/groups', {group, clubId})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteGroup(groupId): Observable<any> {
    return this.http.delete(this.apiUrl + '/groups/' + groupId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateGroup(group): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.put(this.apiUrl + '/groups/' + group.id, {group, clubId})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

}
