import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ClubService {
    public token: string;
    private headers: HttpHeaders;
    private currentClub;
    private readonly apiUrl = environment.apiUrl;
    private readonly baseUrl = environment.baseUrl;


    currencyList = {
        'Ireland': { text: 'euro', symbol: '€' },
        'United Kingdom': { text: 'pound', symbol: '£' },
        'United States': { text: 'dollar', symbol: '$' }
    };

    constructor(private http: HttpClient) {

        const currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentClub = JSON.parse(localStorage.getItem('club'));
        this.token = currentUser ? currentUser.token : '';

    }

    getClubs(): Observable<any> {
        return this.http.get(this.apiUrl + '/clubs')
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    softDeleteClub(clubId): Observable<any> {
        return this.http.delete(this.apiUrl + '/clubs-soft-delete/' + clubId)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    restoreSoftDeleteClub(clubId): Observable<any> {
        return this.http.delete(this.apiUrl + '/clubs-restore-soft-delete/' + clubId)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }


    hardDeleteClub(clubId): Observable<any> {
        return this.http.delete(this.apiUrl + '/clubs/' + clubId)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    getClubBankAccount(): Observable<any> {
        const club = this.getClub();
        return this.http.get(this.apiUrl + '/club-bank-account/' + club.id)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }



    getClubPayments(clubId, sortActive, sortDirection, pageIndex, fromDate, toDate, searchedForUserName = 'none'): Observable<any> {
        return this.http.get(this.apiUrl + '/club-payments/' + clubId
        + '?sortDirection=' + sortDirection
        + '&pageIndex=' + pageIndex
        + '&fromDate=' + fromDate
        + '&toDate=' + toDate 
        + '&searchedForUserName=' + searchedForUserName
    ).pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }


    updateClubDetails(clubDetails): Observable<any> {
        return this.http.patch(this.apiUrl + '/clubs/' + clubDetails.id,
            {
                clubDetails
            }).pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }



    payAmount(paymentDetails): Observable<any> {
        return this.http.post(this.apiUrl + '/process-payment', {
            paymentDetails,
        }).pipe(
            map((response: Response) => {
                return response;
            }));
    }


    getClubCurrency(country?) {
        const currentClub = JSON.parse(localStorage.getItem('club'));
        let clubCountry;
        if (currentClub === null) {
            clubCountry = country;
        } else {
            clubCountry = currentClub.country;
        }

        const currency = this.currencyList[clubCountry].symbol;
        return currency;
    }

    getClub() {
        const currentClub = JSON.parse(localStorage.getItem('club'));
        return currentClub;
    }

    getClubFromApi(clubId): Observable<any> {
        return this.http.get(this.apiUrl + '/clubs/' + clubId)
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    getOnboardingStatus(): Observable<any> {
        const club = this.getClub();
        return this.http.get(this.apiUrl + '/onboarding-status/' + club.id )
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    sendRegistrationReminder(memberId, clubId): Observable<any> {
        return this.http.post(this.apiUrl + '/registration-reminder', {clubId, memberId}).pipe(
            map((response: Response) => {
                return response;
            }));
    }
}
