import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../../_services/shop.service';
import { MatSnackBar } from '@angular/material';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-shop-settings',
  templateUrl: './shop-settings.component.html',
  styleUrls: ['./shop-settings.component.scss']
})
export class ShopSettingsComponent implements OnInit {

  shopSettings = {
    id: 0,
    shipping_fee: 0,
    shipping_enabled: 0
  };

  constructor(private shopService: ShopService, private snackBar: MatSnackBar, private clubService: ClubService) { }

  ngOnInit() {
    const clubId = this.clubService.getClub().id;

    this.shopService.getShopSettings(clubId).subscribe( response => {
      if (response.shopSettings !== null) {
        this.shopSettings = response.shopSettings;
      }
    });
  }

  onSubmit() {
    this.shopService.updateShopSettings(this.shopSettings).subscribe( result => {
      this.snackBar.open('Settings updated!', 'OK', {
        duration: 4000,
      });
    });
  }
}
