import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClubService } from './club.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private readonly apiUrl = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private clubService: ClubService
    ) { }

  getClubAdmins(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(this.apiUrl + '/admins?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }

  updateAdminRole(userId, role): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.patch(this.apiUrl + '/admins/' + userId, {
      clubId,
      userId,
      role
    }).pipe(
        map((response: Response) => {
            return response;
        })
    );
  }

}
