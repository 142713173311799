import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Inject } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { PayAmountComponent } from '../pay-amount/pay-amount.component';
import { ShopService } from '../../../_services/shop.service';
@Component({
  selector: 'app-view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: ['./view-cart.component.scss']
})
export class ViewCartComponent implements OnInit {

  cartItems = [];
  clubCurrency;
  cartTotal = 0;
  currentClub;
  shopSettings = {shipping_enabled: 0, shipping_fee: 0};
  shippingAddress = {
    first_name: '',
    surname: '',
    email: '',
    phone_number: '',
    line_1 : '',
    line_2 : '',
    city : '',
    country : '',
    post_code : '',
    clubName: ''
  };
  shippingSelected = '0';
  errorMessages = [];
  shopType;

  constructor(
    private clubService: ClubService,
    public cartModal: MatDialogRef<ViewCartComponent>,
    private shopService: ShopService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}


  ngOnInit() {
    this.shopType = this.data.shopType;
    this.getLocalCart();

    this.shopService.checkCartItems(this.cartItems, this.shopType).subscribe(result => {
      this.cartItems = result;
      // update the local cart
      localStorage.setItem('cart', JSON.stringify(result));
      this.getLocalCart();
    });

    this.currentClub = this.data.clubDetails;
    this.clubCurrency = this.clubService.getClubCurrency(this.currentClub.country);
    this.shippingAddress.country = this.currentClub.country;
    this.shopService.getShopSettings(this.currentClub.id).subscribe(response => {
      this.shopSettings = response.shopSettings;
    });
  }

  getLocalCart() {
    this.cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    // must double check that the items are still the same price here
    // - in case they were changed in the cache
    this.cartTotal = 0;
    for (let i = 0; i < this.cartItems.length; i++) {
      this.cartTotal += this.cartItems[i].total;
    }
  }

  updateShipping() {
    this.errorMessages = [];
    if (this.shopSettings.shipping_enabled === 1 && this.shippingSelected === '1') {
      this.cartTotal += parseInt(this.shopSettings.shipping_fee.toString(), 10);
    } else {
      this.cartTotal -= parseInt(this.shopSettings.shipping_fee.toString(), 10);
    }
  }

  removeItem(itemIndex) {
    const currentCart = JSON.parse(localStorage.getItem('cart')) || [];
    currentCart.splice(itemIndex, 1);
    localStorage.setItem('cart', JSON.stringify(currentCart));
    this.getLocalCart();
  }

  checkout() {

    if (this.validateForm()) {
      const paymentModal = this.dialog.open(PayAmountComponent, {
        data: {
          currentClub: this.currentClub,
          paymentAmountSelected: this.cartTotal,
          paymentType: 'shop_purchase',
          shopType: this.shopType,
          cartItems: this.cartItems,
          shippingSelected: this.shippingSelected,
          shippingAddress: this.shippingAddress
        },
        id: 'payment-modal',
        maxWidth: '95vw',
        minWidth: '40vw'

      });

      paymentModal.afterClosed().subscribe(result => {
        // remove items from cart, close cart and
        localStorage.setItem('cart', JSON.stringify([]));
        this.cartModal.close('paymentSuccess');
      });
    }
  }


  validateForm() {

    this.errorMessages = [];

    if (this.shopSettings.shipping_enabled === 1) {

      // name is required if we are in the public shop or shipping is selected
      if (this.shopType === 'public' || this.shippingSelected === '1') {
        if (this.shippingAddress.first_name === '') {
          this.errorMessages.push('First name field must be filled.');
        }
        if (this.shippingAddress.surname === '') {
          this.errorMessages.push('Last name field must be filled.');
        }
      }

      if (this.shippingSelected === '1') {
        if (this.shippingAddress.email === '') {
          this.errorMessages.push('Email field must be filled.');
        }
        const regx = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
        if (!this.shippingAddress.email.match(regx)) {
          this.errorMessages.push('Email must be a valid email.');
        }
        if (this.shippingAddress.phone_number === '') {
          this.errorMessages.push('Phone number field must be filled.');
        }
        if (this.shippingAddress.line_1 === '') {
          this.errorMessages.push('Address line one field must be filled.');
        }
        if (this.shippingAddress.line_2 === '') {
          this.errorMessages.push('Address line two field must be filled.');
        }
        if (this.shippingAddress.city === '') {
          this.errorMessages.push('City field must be filled.');
        }
        if (this.shippingAddress.post_code === '') {
          this.errorMessages.push('Post code field field must be filled.');
        }
      }

    } else {
      // if we are in the public shop and there is no shipping we need to get the buyers details for pickup
      if (this.shopType === 'public') {
        if (this.shippingAddress.first_name === '') {
          this.errorMessages.push('First name field must be filled.');
        }
        if (this.shippingAddress.surname === '') {
          this.errorMessages.push('Last name field must be filled.');
        }
        if (this.shippingAddress.email === '') {
          this.errorMessages.push('Email field must be filled.');
        }

        const regx = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
        if (!this.shippingAddress.email.match(regx)) {
          this.errorMessages.push('Email must be a valid email.');
        }
      }
      //no shipping && only for the shandon regatta payments
      if(( this.currentClub.id === 8 || this.currentClub.id === 5) && this.shippingAddress.clubName === ''){
        this.errorMessages.push('Club name field must be filled.');
      }
    }

    if (this.errorMessages.length > 0) {
      return false;
    }

    return true;
  }
}
