import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import * as HttpStatus from 'http-status-codes';
import { AuthenticationService } from './_services/authentication.service';

@Injectable()
export class SimpleHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthentication(req);
    return next.handle(req).pipe(catchError((err) => {
      if (err.status === 401) {
        this.router.navigate(['/login']); // go to login fail on 401
      }
      return observableThrowError(err);
    }));
  }

  // here you add the bearer in every request
  addAuthentication(req: HttpRequest<any>): HttpRequest<any> {
    const headers: any = {};
    const authToken = this.authService.getToken(); // get the token from a service
    if (authToken) {
      // append headers
      headers['authorization'] = 'Bearer ' + authToken; // add it to the header
      req = req.clone({
        setHeaders: headers
      });
    }
    return req;
  }
}
