import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClubService } from './club.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  private url: string = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private clubService: ClubService) { }


  getClubMemberStats(clubId): Observable<any> {
    return this.http.get(`${this.url}/club-members-stats/` +  clubId )
    .pipe(
      map((response: Response) => {
        return response;
      })
    );

  }


  getOverallPlatformStats(): Observable<any> {
    return this.http.get(`${this.url}/overall-platform-stats` )
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


  getPaymentRequestStats(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(`${this.url}/payment-request-stats?clubId=` + clubId )
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


  getPaymentsGraph(fromDate, toDate): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(`${this.url}/payments-graph?clubId=` + clubId + '&fromDate=' + fromDate + '&toDate=' + toDate)
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


  getMembershipStats(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(`${this.url}/membership-stats?clubId=` + clubId )
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getShopStats(dateRange): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(`${this.url}/shop-stats?clubId=` + clubId + '&dateRange=' + dateRange )
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getShopItemStats(shopItemName, dateRange): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(`${this.url}/shop-item-stats?clubId=` + clubId + '&shopItemName=' + shopItemName + '&dateRange=' + dateRange)
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}

