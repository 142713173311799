import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MiscService } from '../../../_services/misc.service';
import { environment } from '../../../../environments/environment';
import { MembershipService } from '../../../_services/membership.service';

@Component({
  selector: 'app-request-cancellation',
  templateUrl: './request-cancellation.component.html',
  styleUrls: ['./request-cancellation.component.scss']
})
export class RequestCancellationComponent implements OnInit {

  private url: string = environment.apiUrl;
  private headers = new Headers({ 'Content-Type': 'application/json' });

  public sendingMessage = false;
  errorMessage = '';
  membersList = [];
  memberMembershipGroups = [];
  selectedMember = '';
  selectedMembership = {};
  message = '';

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<RequestCancellationComponent>,
    private membershipService: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }


  ngOnInit() {
    this.data.memberMembershipsList.forEach(memberMembershipDetails => {
      this.membersList.push(memberMembershipDetails.memberName);
    });

    this.selectedMember = this.membersList[0];

    this.data.memberMembershipsList[0].memberGroups.forEach(group => {
      this.memberMembershipGroups.push(group);
    });

    this.selectedMembership = this.memberMembershipGroups[0].name;
  }


  selectOtherMember($event) {

    this.memberMembershipGroups = [];

    const memberIndex = this.membersList.findIndex( name => {
      return name === $event.value
    });

    this.data.memberMembershipsList[memberIndex].memberGroups.forEach(group => {
      this.memberMembershipGroups.push(group);
    });

    this.selectedMembership = this.memberMembershipGroups[0].name;
  }

  public onSubmit(values: any) {

    if (values.message === '') {
      this.errorMessage = 'Please add a message for us to read!';
    } else {
      this.sendingMessage = true;

      this.membershipService.requestMembershipCancellation(this.selectedMember, this.selectedMembership, this.message).subscribe(
        res => {
          this.snackBar.open('Your cancelation request has been sent!', '', {
            duration: 3000,
          });
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.errorMessage = '';
          this.sendingMessage = false;
        },
        error => {
          this.sendingMessage = false;
          this.errorMessage = error._body;
        });
    }
  }

}
