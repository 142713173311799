import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialog, MatSort, MatPaginator, MatSnackBar } from '@angular/material';
import { AreYouSureService } from '../../../_services/are-you-sure.service';

import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { AddEditPaymentRequestComponent } from '../add-edit-payment-request/add-edit-payment-request.component';
import { PaymentRequestService } from '../../../_services/payment-request.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html',
  styleUrls: ['./payment-requests.component.scss']
})
export class PaymentRequestsComponent implements AfterViewInit {

  club: any = {};
  data = [];
  clubCurrency;
  reminderBeingSent = false;
  displayedColumns = ['name', 'date', 'total_collected', 'total_initial_owed', 'percentage_collected', 'actions'];
  tableHeaders = [
    {title: 'Name', getName: 'name'},
    {title: 'Events', getName: 'races'},
    {title: 'Date', getName: 'date'},
    {title: 'Collected', getName: 'total_collected'},
    {title: 'Total Requested', getName: 'total_initial_owed'},
    {title: 'Percentage Collected', getName: 'percentage_collected'},
    {title: 'Actions', getName: 'actions'}
  ];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  constructor(
    private clubService: ClubService,
    private paymentRequestService: PaymentRequestService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: Router,
    private aysService: AreYouSureService) { }


  ngAfterViewInit() {
    this.club = this.clubService.getClub();
    this.clubCurrency = this.clubService.getClubCurrency();

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.updatePaymentRequestsList();
  }

  updatePaymentRequestsList() {

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;

        return this.paymentRequestService.getPaymentRequests(this.club.id, this.sort.active, this.sort.direction, this.paginator.pageIndex);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.paymentRequestsCount;

        return data;
      }),
      catchError(() => {
        this.isLoadingResults = false;

        this.isRateLimitReached = true;
        return of([]);
      })
    ).subscribe(data => this.data = data.paymentRequests);
  }

  addPaymentRequest() {
    const dialogRef = this.dialog.open(AddEditPaymentRequestComponent, {
      data: {
        currentClub: this.club,
      },
      maxWidth: '95vw',
      minWidth: '35vw'
    });

    dialogRef.afterClosed().subscribe(paymentRequestId => {
      // go into the payment request
      if (paymentRequestId) {
        this.route.navigate(['/outstanding-amounts/' + paymentRequestId]);
      }
    });
  }

  editPaymentRequest(paymentRequestDetails) {

    const dialogRef = this.dialog.open(AddEditPaymentRequestComponent, {
      data: {
        paymentRequestDetails: paymentRequestDetails,
        currentClub: this.club,
        action: 'edit'
      },
      maxWidth: '95vw',
      minWidth: '35vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updatePaymentRequestsList();
    });
  }

  duplicateAndReset(paymentRequestId) {
    this.paymentRequestService.duplicateAndReset(paymentRequestId).subscribe(result => {
      this.updatePaymentRequestsList();
    });
  }

  deletePaymentRequest(paymentRequestId) {
    this.paymentRequestService.deletePaymentRequest(paymentRequestId).subscribe(result => {
      this.updatePaymentRequestsList();
    });
  }


  sendAllOutstandingPaymentReminder() {
    this.paymentRequestService.sendAllOutstandingPaymentReminder(this.club.id).subscribe(result => {
      this.snackBar.open(result['numEmailsSent'] + ' reminder emails sent!', 'OK', {
        duration: 4000,
      });
    });
  }

}
