import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-side-nav',
  templateUrl: './admin-side-nav.component.html',
  styleUrls: ['./admin-side-nav.component.scss']
})
export class AdminSideNavComponent implements OnInit {

  constructor(private authService: AuthenticationService,
    public router: Router) { }

  $userRole: Observable<String>;
  showShopSubNavs = false;
  showMembershipSubNavs = false;

  ngOnInit() {
    this.$userRole = this.authService.loggedInRole;
  }

  toggleSubNav(subMenuGroup) {

    if (subMenuGroup === 'shop') {
      if (this.showShopSubNavs) {
        this.showShopSubNavs = false;
      } else {
        this.showShopSubNavs = true;
      }
    } else if (subMenuGroup === 'memberships') {
      if (this.showMembershipSubNavs) {
        this.showMembershipSubNavs = false;
      } else {
        this.showMembershipSubNavs = true;
      }
    }
  }

  hasRole($userRole, acceptableRolesArray) {
    return acceptableRolesArray.includes($userRole);
  }
}
