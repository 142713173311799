import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentRequestService {

  private readonly apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }


  createPaymentRequest(newPaymentRequest, clubId, fullEventUpload?, clubFeeDetails?): Observable<any> {

    return this.http.post(this.apiUrl + '/payment-requests', {
        data: {
            newPaymentRequest,
            clubId: clubId,
            fullEventUpload,
            clubFeeDetails
        },
    })
        .pipe(
            map((response: Response) => {
                return response;
            })
        );
  }


  updatePaymentRequest(paymentRequestDetails): Observable<any> {
      return this.http.patch(this.apiUrl + '/payment-requests/' + paymentRequestDetails.id, { paymentRequestData: paymentRequestDetails })
          .pipe(
              map((response: Response) => {
                  return response;
              })
          );
  }


  getPaymentRequests(clubId, sortActive, sortDirection, pageIndex): Observable<any> {
    return this.http.get(this.apiUrl + '/payment-requests/club-events/' + clubId +
    '?sortActive=' + sortActive + '&sortDirection=' + sortDirection +
    '&pageIndex=' + pageIndex
    ).pipe(
            map((response: Response) => {
                return response;
            })
        );
  }


  updateAmountOutstanding(amountOutstanding, paymentRequestId, userId): Observable<any> {

      return this.http.post(this.apiUrl + '/payment-requests/update-user-event-amount',
          {
              data: {
                  amountOutstanding: amountOutstanding,
                  paymentRequestId,
                  userId: userId
              },
          }).pipe(
              map((response: Response) => {
                  return response;
              })
          );
  }


    duplicateAndReset(paymentRequestId): Observable<any> {
        return this.http.post(this.apiUrl + '/payment-requests/duplicate-and-reset', {
            paymentRequestId
        });
    }

  deletePaymentRequest(paymentRequestId): Observable<any> {
      return this.http.delete(this.apiUrl + '/payment-requests/' + paymentRequestId);
  }


  getClubMembersAmounts(clubId, paymentRequestId, groupId?): Observable<any> {

    let url;
    if (groupId) {
        url = this.apiUrl + '/clubs/' + clubId + '/payment-requests/' + paymentRequestId + '/amounts?groupId=' + groupId;
    } else {
        url = this.apiUrl + '/clubs/' + clubId + '/payment-requests/' + paymentRequestId + '/amounts';
    }

    return this.http.get(url)
        .pipe(
            map((response: Response) => {
                return response;
            })
        );
    }


    postPaymentReminder(details) {
        return this.http.post(this.apiUrl + '/payment-reminder', {
            clubId: details.clubId,
            memberId: details.memberId
        }).pipe(
            map((response: Response) => {
                return response;
            }));
    }



    postGroupPaymentReminder(details) {
        return this.http.post(this.apiUrl + '/group-payment-reminder', {
            clubId: details.clubId,
            groupId: details.groupId,
            paymentRequestId: details.paymentRequestId
        }).pipe(
            map((response: Response) => {
                return response;
            }));
    }


    sendAllOutstandingPaymentReminder(clubId) {
        return this.http.post(this.apiUrl + '/all-outstanding-payment-reminder', {
            clubId
        }).pipe(
            map((response: Response) => {
                return response;
        }));
    }



  completeEventUpload(clubId, eventDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/payment-requests/', {
        clubId,
        eventDetails
    }).pipe(
            map((response: Response) => {
                return response;
            })
        );
  }

  addMemberToPaymentRequest(paymentRequestId, memberId): Observable<any> {
    return this.http.post(this.apiUrl + '/payment-requests/members', {
        paymentRequestId,
        memberId
    }).pipe(
        map((response: Response) => {
            return response;
        })
    );
  }

  removeMemberFromPaymentRequest(eventMemberId): Observable<any> {
    return this.http.delete(this.apiUrl + '/payment-requests/members/' + eventMemberId).pipe(
        map((response: Response) => {
            return response;
        })
    );
  }
}
