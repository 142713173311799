import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../_services/stats.service';

@Component({
  selector: 'app-platform-dashboard',
  templateUrl: './platform-dashboard.component.html',
  styleUrls: ['./platform-dashboard.component.scss']
})
export class PlatformDashboardComponent implements OnInit {

  overallStats = {
    numClubs: 0,
    numEvents: 0 ,
    numUsers: 0 ,
    numActiveUsers: 0,
    numMembers: 0,
    numMembersPerUser: 0,
    numPaymentsProcessed: 0,
    amoutPaymentsProcessed: 0,
    cashPaymentsProcessed: 0,
    totalRefundedAmount: 0,
    totalCardPayments: 0,
    totalPlatformProfit: 0,
    feesTaken: 0
  };
  constructor(private statsService: StatsService) { }

  ngOnInit() {
    this.statsService.getOverallPlatformStats().subscribe(result => {
      this.overallStats = result;
    });
  }
}
