import { Component, OnInit } from '@angular/core';
import { GroupsService } from '../../../_services/groups.service';
import { ClubService } from '../../../_services/club.service';
import { MatDialog } from '@angular/material';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AddEditGroupComponent } from '../add-edit-group/add-edit-group.component';

@Component({
  selector: 'app-member-groups',
  templateUrl: './member-groups.component.html',
  styleUrls: ['./member-groups.component.scss']
})
export class MemberGroupsComponent implements OnInit {

  groups = [];
  club;
  displayedColumns = ['actions', 'name', 'member_count'];
  tableHeaders = [
    {title: 'Actions', getName: 'actions'},
    {title: 'Name', getName: 'name'},
    {title: 'Member Count', getName: 'member_count'},
  ];

  constructor(private groupsService: GroupsService, private clubService: ClubService,
    private dialog: MatDialog, private aysService: AreYouSureService) { }

  ngOnInit() {
    this.club =  this.clubService.getClub();
    this.getGroups();
  }

  getGroups() {
    this.groupsService.getGroups(this.club.id, 'contact').subscribe(result => {
      this.groups = result.groups;
    });
  }

  createGroup() {
    const dialogRef = this.dialog.open(AddEditGroupComponent, {
      data: {
        currentClub: this.club,
        action: 'create'
      },
      id: 'group-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getGroups();
    });
  }

  editGroup(selectedGroup) {
    const dialogRef = this.dialog.open(AddEditGroupComponent, {
      data: {
        currentClub: this.club,
        currentGroup: selectedGroup,
        action: 'edit'
      },
      id: 'group-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getGroups();
    });
  }

  deleteGroup(groupId) {
    this.groupsService.deleteGroup(groupId).subscribe(response => {
      if (response.status === 'ok')  {
        this.getGroups();
      }
    });
  }
}
