import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    public token: string;
    private headers: HttpHeaders;
    private readonly apiUrl = environment.apiUrl;
    private readonly baseUrl = environment.baseUrl;
    public currentClub;
    public loggedInAdmin = new BehaviorSubject<Boolean>(false);
    public loggedInRole = new BehaviorSubject<String>('');
    public userClubsList = new BehaviorSubject<any>([]);
    public userSelectedClubId = new BehaviorSubject<any>(0);

    constructor(private http: HttpClient) {

        // have this here in case of page refresh
        if (localStorage.getItem('club') && JSON.parse(localStorage.getItem('club')).pivot) {

            this.checkAndUpdateUserRole('\"' + JSON.parse(localStorage.getItem('club')).pivot.role + '\"');
            this.userSelectedClubId.next(JSON.parse(localStorage.getItem('club')).id);
            this.loggedInRole.next(JSON.parse(localStorage.getItem('club')).pivot.role);

            if (localStorage.getItem('userClubs')) {
                this.userClubsList.next(JSON.parse(localStorage.getItem('userClubs')));
            }
        } else {
            // check if there we even have the user details available - if not then logout
            this.logout();
        }
    }

    get isAdmin(): Observable<Boolean> {
        return this.loggedInAdmin.asObservable();
    }

    get userClubs(): Observable<Boolean> {
        return this.userClubsList.asObservable();
    }

    get selectedClubId(): Observable<Boolean> {
        return this.userSelectedClubId.asObservable();
    }

    login(email: string, password: string): Observable<any> {
        return this.http.post(this.apiUrl + '/login', { email: email, password: password }, { headers: this.headers } )
            .pipe(
                map((response: Response) => {
                    this.storeUserAndClub(response);

                    return response;
                })
            );
    }

    registerClub(clubData): Observable<any> {
        return this.http.post(this.apiUrl + '/register', { clubData }, { headers: this.headers } )
            .pipe(
                map((response: Response) => {
                    this.storeUserAndClub(response);

                    return response;
                })
            );
    }


    registerMember(email: String, password: String, terms_and_conditions, privacy_policy): Observable<any> {
        return this.http.post(this.apiUrl + '/member-register',
            { email, password, terms_and_conditions, privacy_policy},
            { headers: this.headers }
        )
            .pipe(
                map((response: Response) => {
                    this.storeUserAndClub(response);
                    return response;
                })
            );
    }

    inviteLinkMemberRegister(formDetails): Observable<any> {
        return this.http.post(this.apiUrl + '/invite-link-member-register',
            {
                first_name: formDetails.first_name,
                surname: formDetails.surname,
                isParent: formDetails.isParent,
                parent_first_name: formDetails.parent_first_name,
                parent_surname: formDetails.parent_surname,
                dob: formDetails.dob,
                email: formDetails.email,
                password: formDetails.password,
                terms_and_conditions: formDetails.termsAndConditions,
                privacy_policy: formDetails.privacyPolicy,
                uuid: formDetails.uuid,
            },
            { headers: this.headers }
        )
            .pipe(
                map((response: Response) => {
                    this.storeUserAndClub(response);
                    return response;
                })
            );
    }

    getInviteLinkStatus(uuid): Observable<any> {
        return this.http.get(this.apiUrl + '/auth/invite-link-status/' + uuid)
            .pipe(
                map((response: Response) => {
                    this.storeUserAndClub(response);
                    return response;
                })
            );
    }

    createMembersInviteLink(clubId, type, expiry): Observable<any> {
        return this.http.post(this.apiUrl + '/auth/create-members-invite-link', {
          clubId,
          type,
          expiry
        })
          .pipe(
            map((response: Response) => {
              return response;
            })
          );
      }


      getLatestInviteLink(clubId): Observable<any> {
        return this.http.get(this.apiUrl + '/clubs/' + clubId + '/get-latest-invite-link')
          .pipe(
            map((response: Response) => {
              return response;
            })
          );
      }


      approveMember(memberId): Observable<any> {
        return this.http.post(this.apiUrl + '/auth/approve-member', {
          memberId
        })
        .pipe(
          map((response: Response) => {
            return response;
          })
        );
      }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem('user');
        // clear the selected club from local storage
        localStorage.removeItem('club');

        // clear the userClubs list from local storage
        localStorage.removeItem('userClubs');

        // reset the observables to default values
        this.loggedInAdmin.next(false);
        this.userClubsList.next([]);
        this.userClubsList.next(0);
    }

    sendPasswordResetEmail(email: string): Observable<any>  {
        const url = this.baseUrl + '/reset-password';
        return this.http.post(this.apiUrl + '/password-reset-email',
                { email: email, url:  url }, { headers: this.headers } )
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    resetPassword(newPassword: string, confirmedPassword: string, token: string): Observable<any> {
        return this.http.post(this.apiUrl + '/reset-password', { password: newPassword,
            confirm_password: confirmedPassword, token: token }, { headers: this.headers } )
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    getToken() {
        const currentUser = JSON.parse(localStorage.getItem('user'));

        if (currentUser) {
            return currentUser.token;
        }
        return false;
    }

    getUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user;
    }



    storeUserAndClub(response) {
        // login successful if there's a jwt token in the response
        this.token = response['token'];
        const email = response['email'];

        if (this.token) {
            // store email and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user',
                JSON.stringify({ email: email, token: this.token }));

            // store the current club in local storage too - pick the first club - user can change after
            localStorage.setItem('club', JSON.stringify(response['clubs'][0]));

            localStorage.setItem('userClubs', JSON.stringify(response['clubs']));

            this.checkAndUpdateUserRole(JSON.stringify(response.clubs[0].pivot.role));
            this.updateUserClubsList(response);
            this.updateSelectedClubId(response);
            this.updateUserRole(response);
        }
    }


  public checkAndUpdateUserRole(role) {

    // set to false first
    this.loggedInAdmin.next(false);

    // check the user role
    const adminArray =
    [
        '"admin"', // access to all areas apart from - adding/removing admins & club setting page
        '"super_admin"', // access to all areas except platform admin stuff
        '"platform_admin"', // super admin access
        '"shop_admin"', // access to all shop pages & payments made
        '"membership_admin"',  // access to all membership pages & payments made
        '"request_funds_admin"' // access to all events pages & payments made
    ];
    if (adminArray.includes(role)) {
      this.loggedInAdmin.next(true);
    }
  }

  private updateUserClubsList(response) {
    this.userClubsList.next(response.clubs);
  }

  private updateSelectedClubId(response) {
    this.userSelectedClubId.next(response.clubs[0].id);
  }

  private updateUserRole(response) {
    this.loggedInRole.next(response.clubs[0].pivot.role);
  }
}
