import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GroupsService } from '../../../_services/groups.service';
import { ClubService } from '../../../_services/club.service';
import { NewsletterService } from '../../../_services/newsletter.service';
import { MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {


  clubId;
  sendingEmail = false;
  errorMessage = '';
  attachmentDetails = [];
  spinnerText = 'Emails Sending...';
  newsletter = '';

  private readonly apiUrl = environment.apiUrl;
  uploader: FileUploader = new FileUploader({
    url : this.apiUrl + '/newsletters/upload-attachment',
    additionalParameter: { 'clubId': this.clubService.getClub().id}
  });

  constructor(
    private spinner: NgxSpinnerService,
    private clubService: ClubService,
    private groupsService: GroupsService,
    private newsletterService: NewsletterService,
    private snackBar: MatSnackBar
  ) { }


  groups = [];
  selectedEmailGroups = new FormControl();

  ngOnInit() {

    this.clubId = this.clubService.getClub().id;
    this.groupsService.getGroups(this.clubId).subscribe(result => {
      this.groups = result.groups;
      // add other options
      this.groups.unshift({ id: -1, club_id: this.clubId, name: 'Members With Outstanding Memberships' });
      this.groups.unshift({ id: 0, club_id: this.clubId, name: 'All Club Members' });
    });


        // file upload
        this.uploader.onAfterAddingFile = (file) => {
          file.withCredentials = false;
          this.uploader.uploadAll();
        };

        this.uploader.onBeforeUploadItem = () => {
          this.spinnerText = 'Uploading attachment';
          this.spinner.show();
        };
        this.uploader.onSuccessItem = (fileItem, response, status) => {
          this.spinner.hide();

          const parsedResponse = JSON.parse(response);


          if (parsedResponse.status === 'error') {
            this.errorMessage = parsedResponse.errorMessage;
          } else {
            this.attachmentDetails.push(parsedResponse.attachmentDetails);
          }
        };
  }


  sendEmail() {
    this.spinnerText = 'Emails Sending...';
    this.errorMessage = '';

    if (!this.newsletter || this.newsletter.length < 50) {
      this.errorMessage = 'Message must be over 50 characters long';

    } else if (!this.selectedEmailGroups.value) {
      this.errorMessage = 'Please select a group to send the email to.';

    } else {
      const newsletterData = {
        selectedGroups: this.selectedEmailGroups.value,
        htmlMessage: this.newsletter,
        clubId: this.clubId,
        attachmentURLs: this.attachmentDetails
      };
      this.sendingEmail = true;
      this.spinner.show();
      this.newsletterService.sendNewsletter(newsletterData).subscribe(result => {
        this.sendingEmail = false;
        this.spinner.hide();
        this.snackBar.open(result['numEmailsSent'] + ' emails sent!', 'OK', {
          duration: 4000,
        });
      });
    }
  }
}
