import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { ShopService } from '../../../_services/shop.service';
import { FormBuilder, Validators, FormControl, FormArray, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-add-edit-shop-item',
  templateUrl: './add-edit-shop-item.component.html',
  styleUrls: ['./add-edit-shop-item.component.scss']
})
export class AddEditShopItemComponent implements OnInit {

  imageShow;
  errorMessage = '';

  shopItem = this.fb.group({
    id: new FormControl(0),
    name: new FormControl(),
    price: new FormControl(),
    type: new FormControl('clothing'),
    description: new FormControl(),
    limitedStock: new FormControl(),
    stockCount: new FormControl(),
    draft: new FormControl(0),
    public: new FormControl('0'), // 0 is private (members only)
    stockSizes: new FormArray([
      this.fb.group({
        size_available: [''],
        stock_count: ['']
      })
    ]),
    image: [null]
  });

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private shopService: ShopService,
    public dialogRef: MatDialogRef<AddEditShopItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}



  ngOnInit() {
    if (this.data.selectedShopItem) {
      const editShopItem = this.data.selectedShopItem;

      const stockSizes = this.fb.array(editShopItem.stock_sizes.map(stockSize => this.fb.group(stockSize)));

      this.shopItem = this.fb.group({
        id: new FormControl(editShopItem.id),
        name: new FormControl(editShopItem.name),
        price: new FormControl(editShopItem.price),
        type: new FormControl(editShopItem.type),
        description: new FormControl(editShopItem.description),
        limitedStock: new FormControl(editShopItem.limited_stock === 1 ? true : false),
        stockCount: new FormControl(editShopItem.stock_count),
        draft: new FormControl(editShopItem.draft),
        public: new FormControl(editShopItem.public),
        stockSizes: stockSizes,
        image: [editShopItem.images[0]]
      });

      if (editShopItem.images[0]) {
        this.imageShow = editShopItem.images[0].thumb_link;
      }
    }
  }

  addStockSize() {
    this.errorMessage = '';
      (this.shopItem.get('stockSizes') as FormArray).push(this.fb.group({
          size_available: [''],
          stock_count: ['']
      }));
      this.cdRef.detectChanges();
  }

  imageUploadChange(fileInputEvent: any) {
    if (fileInputEvent.target.files && fileInputEvent.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.shopItem.get('image').setValue(fileInputEvent.target.files[0]);
        this.imageShow = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(fileInputEvent.target.files[0]);
    }
  }

  onSubmit() {

    const itemId = this.shopItem.value.id;

    const uploadData = new FormData();
    uploadData.append('name', this.shopItem.get('name').value);
    uploadData.append('price', this.shopItem.get('price').value);
    uploadData.append('type', this.shopItem.get('type').value);
    uploadData.append('image', this.shopItem.get('image').value);
    uploadData.append('limitedStock', this.shopItem.get('limitedStock').value);
    uploadData.append('stockCount', this.shopItem.get('stockCount').value);
    uploadData.append('description', this.shopItem.get('description').value);
    uploadData.append('draft', this.shopItem.get('draft').value);
    uploadData.append('public', this.shopItem.get('public').value);
    uploadData.append('stockSizes', JSON.stringify(this.shopItem.get('stockSizes').value));

    this.spinner.show();
    this.errorMessage = '';

    if (itemId !== 0) {
      this.shopService.updateShopItem(itemId, uploadData).subscribe(result => {
        this.spinner.hide();
        if (result.status === 'ok') {
          this.dialogRef.close();
        }
      }, response => {
        this.displayErrorMessages(response);
      });
    } else {
      this.shopService.createShopItem(uploadData).subscribe(result => {
        this.spinner.hide();
        if (result.status === 'ok') {
          this.dialogRef.close();
        }
      }, response => {
        this.displayErrorMessages(response);
      });
    }
  }


  compareFn(x, y): boolean {
      if (x == y) {
        return true;
      } else {
        return false;
      }
  }


  removeStockSize(index) {
    this.errorMessage = '';

    if ((this.shopItem.get('stockSizes') as FormArray).length > 1) {
      (this.shopItem.get('stockSizes') as FormArray).removeAt(index);
    } else {
      this.errorMessage = 'At least one item size must be on clothing';
    }
  }


  displayErrorMessages(response) {
    this.spinner.hide();
    if (response.error.errors.image) {
      this.errorMessage = 'One image must be added to the product.';
    } else if (response.error.errors.price) {
      this.errorMessage = response.error.errors.price;
    }
  }
}
