import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-link-invite-register',
  templateUrl: './link-invite-register.component.html',
  styleUrls: ['./link-invite-register.component.scss']
})
export class LinkInviteRegisterComponent implements OnInit {


  clubName: String = '';
  uuid: String = '';

  first_name: String = '';
  surname: String = '';
  dob: String = '';
  email: String = '';
  password: String = '';
  confirmedPassword: String = '';
  warningMessage: String = '';
  termsAndConditions = false;
  privacyPolicy = false;

  isParent = false;
  parent_first_name = '';
  parent_surname = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.clubName = decodeURIComponent(params['club-name']).replace(/-/g, ' ');
      this.uuid = params['uuid'];

      // check if the invite link is out of date first
      this.authService.getInviteLinkStatus(this.uuid).subscribe(result => {
        if (result.status === 'invalid') {
          this.router.navigate(['invalid-invite']);
        }
      }, error => {
        this.router.navigate(['invalid-invite']);
      });
    });
  }

  updateIsParent(event) {
    this.isParent = event.checked;
  }

  onRegister() {

    this.warningMessage = '';

    if (this.password !== this.confirmedPassword) {
      this.warningMessage = 'Password does not match the confirm password!';
      return;
    } else if (this.password === '') {
      this.warningMessage = 'You can\'t use empty passwords!';
      return;
    }  else if (this.email === '') {
      this.warningMessage = 'You must add an email!';
      return;
    } else if (this.first_name === '') {
        this.warningMessage = 'You must add a first name!';
        return;
    } else if (this.surname === '') {
      this.warningMessage = 'You must add a last name!';
      return;
    } else if (this.dob === '') {
        this.warningMessage = 'You must add a date of birth!';
        return;
    } else {

    const formDetails = {
      uuid: this.uuid,
      first_name: this.first_name,
      surname: this.surname,
      isParent: this.isParent,
      parent_first_name: this.parent_first_name,
      parent_surname: this.parent_surname,
      dob: this.dob,
      email: this.email,
      password: this.password,
      termsAndConditions: this.termsAndConditions,
      privacyPolicy: this.privacyPolicy
    };

    this.authService.inviteLinkMemberRegister(formDetails)
      .subscribe(res => {
        // check for errors
        this.warningMessage = '';
        if (Array.isArray(res.email)) {
          this.warningMessage += res.email[0];
        }
        // if (Array.isArray(res.name)) {
        //   this.warningMessage += res.name[0];
        // }
        if (Array.isArray(res.password)) {
          this.warningMessage += res.password[0];
        }
        if (Array.isArray(res.terms_and_conditions)) {
          this.warningMessage += res.terms_and_conditions[0];
        }
        if (Array.isArray(res.privacy_policy)) {
          this.warningMessage += res.privacy_policy[0];
        }
        if (res.error) {
          this.warningMessage += res.error;
        }

        // if no errors - check if the member is approved or not and then navigate
        if (!this.warningMessage) {
            this.router.navigate(['member-home']);
        }
      }, error => {
        this.warningMessage = 'Invalid Credentials!';
        console.error(error);
      });
    }
  }
}
