import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-simple-text-form',
  templateUrl: './simple-text-form.component.html',
  styleUrls: ['./simple-text-form.component.scss']
})
export class SimpleTextFormComponent implements OnInit {

  simpleTextFormHeader = 'Notes';

  textToSave = '';
  constructor(
    private dialog: MatDialogRef<SimpleTextFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.textToSave = this.data;
  }

  // have a simple area text box
  // once the user clicks save the modal is closed
  // and the text is returned to the calling component
  save() {
    this.dialog.close(this.textToSave);
  }
}
