import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../../_services/shop.service';
import { ActivatedRoute } from '@angular/router';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-shop-order',
  templateUrl: './shop-order.component.html',
  styleUrls: ['./shop-order.component.scss']
})
export class ShopOrderComponent implements OnInit {

  constructor(
    private clubService: ClubService,
    private shopService: ShopService,
    private route: ActivatedRoute) { }
  shopOrder = {
    user_name: '',
    email: '',
    payment_items: [],
    payment_address: {
      address_line_one: '',
      address_line_two: '',
      city: '',
      country: '',
      post_code: ''
    }
  };
  shopOrderId;
  clubCurrency = '';

  ngOnInit() {
    this.clubCurrency = this.clubService.getClubCurrency();

    this.route.params.subscribe((params) => {
      this.shopOrderId = params['orderId'];
      this.shopService.getShopOrder(this.shopOrderId).subscribe(result => {
        this.shopOrder = result.shopOrder;
      });
    });
  }
}
