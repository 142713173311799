import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StatsService } from '../../../_services/stats.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-shop-item-report',
  templateUrl: './shop-item-report.component.html',
  styleUrls: ['./shop-item-report.component.scss']
})
export class ShopItemReportComponent implements OnInit {

  shopItems: Observable<Object>;
  shopItemName;

  // date range measured in days
  dateRange = 90;

  constructor(
    private spinner: NgxSpinnerService,
    private statsService: StatsService,
    private route: ActivatedRoute
    ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.shopItemName = params['shopItemName'];
      this.getReport();
    });
  }

  getReport() {
    this.spinner.show();
    this.statsService.getShopItemStats(this.shopItemName, this.dateRange).subscribe( result => {
      this.shopItems = result;
      this.spinner.hide();
    })
  }
}
