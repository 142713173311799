import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Inject } from '@angular/core';
import { MembershipService } from '../../../_services/membership.service';
import { PayAmountComponent } from '../pay-amount/pay-amount.component';
@Component({
  selector: 'app-membership-forms',
  templateUrl: './membership-forms.component.html',
  styleUrls: ['./membership-forms.component.scss']
})
export class MembershipFormsComponent implements OnInit {

  membershipForm = {
    membership_form_questions: [],
    membership_form_answers: []
  };

  membershipPaymentDetails = [];

  members = [];
  currentMemberName;
  currentMemberIndex;
  currentMemberFormIndex;
  currentMembershipFormId;

  optionalMemberId = 0;

  datePickerText = 'Click calendar icon >';

  constructor(
    private membershipService: MembershipService,
    public dialogRef: MatDialogRef<MembershipFormsComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}


  ngOnInit() {

    // for admins filling out membership forms use the following
    if (this.data.membershipFormFiller === 'admin') {

      this.currentMembershipFormId = this.data.membershipFormId;
      this.optionalMemberId = this.data.memberId;

      this.membershipService.getMembershipForm(this.currentMembershipFormId).subscribe(result => {
        this.membershipForm = result.membershipForm;
        this.membershipForm.membership_form_answers = [];
      });

    } else {
      // for standard membership forms and checkout flow use below
      this.members = this.data.membersGroups;

      // check if there is a form for the first member first
      this.getNextForm(true);
    }
  }


  getMemberForms() {

    this.currentMembershipFormId =
    this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex].membership.membership_form_id;

    const memberId = this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex].pivot.member_id;

    this.membershipService.getMembershipFormWithPrevAnswers(this.currentMembershipFormId, memberId).subscribe(result => {
      this.membershipForm = result.membershipForm;
      if (result.lastAnsweredMembershipForm) {
        this.membershipForm.membership_form_answers = result.lastAnsweredMembershipForm;
      } else {
        this.membershipForm.membership_form_answers = [];
      }
    });
  }



  getNextForm(start = false) {

    // if admin filling in form then skip the rest
    if (this.data.membershipFormFiller === 'admin') {
      // save a single form as an admin
      this.membershipService.saveMembershipForm(
        this.optionalMemberId,
        this.data.membershipGroupName,
        this.membershipForm
      ).subscribe(result => {
        // close the current modal
        this.dialogRef.close();
      });

    } else {
      if (start) {
        this.currentMemberIndex = 0;
        this.currentMemberFormIndex = 0;
      } else {
        // save the current filled out form before moving to the next form
        this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex].membership.membershipForm = this.membershipForm;
        this.currentMemberFormIndex++;
      }

      this.checkIfFormAvailable();
    }
  }


  checkIfFormAvailable() {

    // check if there is an available member
    if (typeof this.members[this.currentMemberIndex] !== 'undefined') {

      // first check if there is a membership without a membershipForm
      if (typeof this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex] !== 'undefined' &&
          this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex].membership.membership_form_id === 0) {
            this.currentMemberFormIndex++;
            this.checkIfFormAvailable();

      // next check if there is a membership with a membershipForm
      } else if (typeof this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex] !== 'undefined' &&
          this.members[this.currentMemberIndex].memberGroups[this.currentMemberFormIndex].membership.membership_form_id !== 0) {

          this.getMemberForms();

      // if no more forms to fill for the current member move onto the next member
      } else {
        this.currentMemberIndex++;
        this.currentMemberFormIndex = 0;
        this.checkIfFormAvailable();
      }
    } else {
      // if no more members then we can pay for memberships
      // if no amount to pay - eg for lifetime members etc then just save the membership form.
      if (this.data.paymentAmount === 0) {
        this.membershipService.saveMembershipForms(this.members).subscribe(result => {
          // close the current modal
          this.dialogRef.close();
        });
      } else {
        this.payForMembership();
      }
    }
  }


  payForMembership() {

    const paymentModal = this.dialog.open(PayAmountComponent, {
      data: {
        currentClub: this.data.currentClub,
        paymentAmountSelected: this.data.paymentAmount,
        paymentType: 'membership',
        membersMemberships: this.members,
        selectedPaymentTimeframe: this.data.selectedPaymentTimeframe,
        maxNumSplitPayments: this.data.maxNumSplitPayments,
      },
      id: 'payment-modal',
      minWidth: '35vw',
      maxWidth: '95vw'
    });

    paymentModal.afterClosed().subscribe( result => {
      // close the current modal
      this.dialogRef.close();
    });
  }
}
