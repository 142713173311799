import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { MembershipService } from '../../../_services/membership.service';

@Component({
  selector: 'app-manage-membership-upload',
  templateUrl: './manage-membership-upload.component.html',
  styleUrls: ['./manage-membership-upload.component.scss']
})
export class ManageMembershipUploadComponent implements OnInit {

  clubId;
  membershipMembersUpload = [];
  membershipDetails;

  constructor(public dialogRef: MatDialogRef<ManageMembershipUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private clubService: ClubService,
    private membershipsService: MembershipService) { }

  displayedColumns = [ 'first_name', 'surname', 'memberEmail'];
  tableHeaders = [
    {title: 'First Name', getName: 'first_name'},
    {title: 'Last Name', getName: 'surname'},
    {title: 'Member Email', getName: 'memberEmail'},
  ];

  ngOnInit() {
    this.clubId = this.clubService.getClub().id;

    this.membershipMembersUpload = this.data.membersData;
    this.membershipDetails = this.data.membershipData;
  }

  completeMembersUpload() {

    this.membershipsService.createMembership(this.membershipDetails, this.membershipMembersUpload).subscribe(result => {
      if (result.status === 'ok') {
        this.snackBar.open('New membership created!', 'OK', {
          duration: 4000,
        });
        this.dialogRef.close();
      }
    });
  }
}
