import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { MatAutocompleteTrigger, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { GroupsService } from '../../../_services/groups.service';
import { Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ManageMemberUploadComponent } from '../manage-member-upload/manage-member-upload.component';
import { ManageGroupUploadComponent } from '../manage-group-upload/manage-group-upload.component';
import { MembershipService } from '../../../_services/membership.service';

@Component({
  selector: 'app-add-edit-group',
  templateUrl: './add-edit-group.component.html',
  styleUrls: ['./add-edit-group.component.scss']
})
export class AddEditGroupComponent implements OnInit {

  private readonly apiUrl = environment.apiUrl;
  uploader: FileUploader = new FileUploader({
    url : this.apiUrl + '/groups/upload-group-spreadsheet',
    additionalParameter: { 'clubId': this.data.currentClub.id}
  });

  constructor(private clubService: ClubService,
    private groupsService: GroupsService,
    public dialogRef: MatDialogRef<AddEditGroupComponent>,
    private dialog: MatDialog,
    private membershipService: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  group = { name: '', id: 0};
  editGroupName;
  action;
  groupId;
  users = [];
  filteredUsers: Observable<string[]>;
  selectedUsers = [];
  addType = 'noneSelected';
  errorMessage = '';
  missingMembersMessage =
  'The following members in the event you uploaded do not exist. ' +
  'Please create these members first with this form and then try to re-upload the event.';


  ngOnInit() {
    this.action = this.data.action ? this.data.action : 'create';
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploader.onSuccessItem = (fileItem, response, status) => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.status === 'error') {
        this.errorMessage = parsedResponse.errorMessage;
      } else {
        // check if we need to create new users first before doing the event creation
        if (JSON.parse(response).membersDetails.length > 0) {
          const dialogRefMemberUpload = this.dialog.open(ManageMemberUploadComponent, {
            data: {
              memberData: parsedResponse,
              missingMembersMessage: this.missingMembersMessage
            },
            id: 'upload-members-modal',
            width: '90vw',
            maxHeight: '95vh'
          });

          dialogRefMemberUpload.afterClosed().subscribe( () => {
            // close the second dialog box
            this.dialogRef.close();
          });

      } else {

          const dialogRefGroupUpload = this.dialog.open(ManageGroupUploadComponent, {
            data: {
              groupData: parsedResponse,
            },
            id: 'upload-event-modal',
            width: '90vw',
            maxHeight: '95vh'
          });

          dialogRefGroupUpload.afterClosed().subscribe( () => {
            // close the second dialog box
            this.dialogRef.close();
          });
        }
      }
    };

    if (this.action === 'edit') {
      this.group = this.data.currentGroup;
    }
  }


  onSubmit() {

    if (this.group.id !== 0) {
      this.groupsService.updateGroup(this.group).subscribe(result => {
        if (result.status === 'ok') {
          this.dialogRef.close();
        }
      });
    } else {
      this.groupsService.createGroup(this.group).subscribe(response => {
        if (response.status === 'ok')  {
          this.dialogRef.close();
        }
      });
    }
  }
}
