import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialog } from '@angular/material';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { ShopService } from '../../../_services/shop.service';
import { ViewShopItemComponent } from '../view-shop-item/view-shop-item.component';
import { ViewCartComponent } from '../view-cart/view-cart.component';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../_services/authentication.service';
import { PlayLottoComponent } from '../play-lotto/play-lotto.component';
import { LottoService } from '../../../_services/lotto.service';

@Component({
  selector: 'app-club-shop',
  templateUrl: './club-shop.component.html',
  styleUrls: ['./club-shop.component.scss']
})
export class ClubShopComponent implements OnInit {

  clubCurrency;
  shopItems = [];
  clubId;
  club = { id: 0, name: '', country: '', pivot: { role: ''}};
  cartItemsCount = 0;
  shopType;

  lottoEnabled = false;

  @Output() shopPaymentSucess = new EventEmitter();

  constructor(
    private shopService: ShopService,
    private clubService: ClubService,
    private lottoService: LottoService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private aysService: AreYouSureService) { }

  ngOnInit() {

    // checking if the shop is members only shop or a public shop
    this.route.params.subscribe((params) => {

      this.clubId = params['club-id'];
      this.shopType = params['shop-type'];
      this.club = this.clubService.getClub();

      if (this.shopType === undefined || this.shopType === 'private') {
        this.shopType = 'private';
        this.loadAuthenticatedShop();
      } else {

        if (this.club && this.club.pivot && this.club.id.toString() === this.clubId) {
          this.loadAuthenticatedShop();
        } else {
          // if this is a public shop & the user is not logged in
          this.clubService.getClubFromApi(this.clubId).subscribe( result => {
            this.club = result.club;
            if (this.club) {
              localStorage.setItem('club', JSON.stringify(this.club));
              this.clubCurrency = this.clubService.getClubCurrency(this.club.country);
              this.getShopItems();
              this.countCartItems();
              this.loadLottoSettings();
            }
          });
        }
      }
    });
  }

  loadAuthenticatedShop() {
    this.clubCurrency = this.clubService.getClubCurrency();
    this.getShopItems();
    this.countCartItems();
    this.loadLottoSettings();
  }

  getShopItems() {
    const shopEditor = false;

    this.shopService.getShopItems(shopEditor, this.shopType, this.club.id).subscribe( result => {
      this.shopItems = result.shopItems;
    });
  }

  playLotto(itemDetails) {
    const viewItemDetails = this.dialog.open(PlayLottoComponent, {
      data: {
        clubId: this.club.id
      },
      minWidth: '50vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }

  viewItemDetails(itemDetails) {
    const viewItemDetails = this.dialog.open(ViewShopItemComponent, {
      data: {
        itemDetails,
        clubDetails: this.club
      },
      minWidth: '50vw',
      maxWidth: '95vw',
      id: 'shopItemModal'
    });
    viewItemDetails.afterClosed().subscribe(result => {
      this.countCartItems();

      if (result === 'buyNow') {
        this.viewCart();
      }
    });
  }

  viewCart() {
    const cart = this.dialog.open(ViewCartComponent, {
      data: {
        shopType: this.shopType,
        clubDetails: this.club
      },
      minWidth: '50vw',
      maxWidth: '98vw',
      maxHeight: '98vh',
      panelClass: 'custom-modalbox'
    });

    cart.afterClosed().subscribe(result => {
      this.getShopItems();

      if (result === 'paymentSuccess') {
        // paymentSuccess
          this.shopPaymentSucess.emit('paymentSuccess');
      }
      this.countCartItems();
    });
  }

  countCartItems() {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    let cartCount = 0;
    cartItems.forEach(element => {
      cartCount += element.quantitySelected;
    });
    this.cartItemsCount = cartCount;
  }

  loadLottoSettings() {
    this.lottoService.getLottoSettings(this.club.id).subscribe(result => {
      this.lottoEnabled = result.enabled;
    });
  }
}
