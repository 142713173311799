import { Component, ViewChild, AfterViewInit, Output, OnInit } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialog, SimpleSnackBar, MatSnackBar, MatPaginator, MatSort } from '@angular/material';
import { AddMemberComponent } from '../add-member/add-member.component';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { StatsService } from '../../../_services/stats.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { MemberService } from '../../../_services/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MemberDetailsComponent } from '../member-details/member-details.component';
import { merge, of as observableOf, Observable} from 'rxjs';
import { switchMap, startWith, map, catchError } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent  implements AfterViewInit, OnInit {

  club: any = {};
  allMembers;
  membersDisplayed;
  searchField;

  clubMemberStats = {
    allClubMembersCount: 0,
    activeClubMembersCount: 0,
    inactiveClubMembersCount: 0,
    activeLast24Hours: 0,
    activeLast7days: 0
  };
  clubCurrency;
  reminderBeingSent = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  resultsLength = 0;


  constructor(
    private clubService: ClubService,
    private memberService: MemberService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private aysService: AreYouSureService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private statsService: StatsService) { }

  ngOnInit() {
    this.searchField = new FormGroup({
      searchText: new FormControl(''),
    });
  }



  ngAfterViewInit() {
    this.club = this.clubService.getClub();
    this.updateMembersList();
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
        '<h3>Member Help</h3>' +
        '<ul id="admin-instructions">' +
        '<li>To add a member just click on the Add Member button below.</li>' +
        '<li>The member will automatically be sent an email informing them that your club is asking them to sign up.</li>' +
        '<li>If they register their account you will be able to see when they last logged in below.</li>' +
        '<li>You can send them a reminder email by clicking the "Signup Reminder" button if they forget/lose' +
        'their registration email.</li>' +
        '</ul>',
      },
      minWidth: '50vw',
      maxHeight: '95vh',
    });
  }

  viewMemberDetails(member) {
    const dialogRef = this.dialog.open(MemberDetailsComponent, {
      data: {
        member
      },
      minWidth: '40vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }

  addMembers() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      data: {
        currentClub: this.club,
      },
      minWidth: '40vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      id: 'add-members-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updateMembersList();
    });
  }


  updateMembersList() {
    this.spinner.show();
    this.statsService.getClubMemberStats(this.club.id).subscribe(response => {
      this.clubMemberStats = response.stats;
    });

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.searchField.valueChanges.subscribe(() => this.paginator.pageIndex = 0);

    // on sort change/ page change or search text change then kick off the following
    this.sort.direction = 'asc';
    merge(this.sort.sortChange, this.paginator.page, this.searchField.valueChanges)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (this.searchField.value.searchText.length > 2) {
            return this.memberService.getAllMembers(this.searchField.value.searchText, this.sort.direction, this.paginator.pageIndex);
          } else {
            return this.memberService.getAllMembers('', this.sort.direction, this.paginator.pageIndex);
          }
        }),
        map(data => {
          this.resultsLength = data.clubMembersCount;
          this.spinner.hide();

          return data;
        }),
        catchError(() => {
          this.spinner.hide();
          return observableOf([]);
        })
      ).subscribe(data => this.membersDisplayed = data.allClubMembers);
  }

  editMember(memberDetails) {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      data: {
        memberDetails: memberDetails,
        currentClub: this.club,
        action: 'edit'
      },
      minWidth: '40vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      id: 'add-members-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updateMembersList();
    });
  }

  deleteMember(row) {
    const userId = row.id;
    this.memberService.deleteMember(userId).subscribe(result => {
      this.updateMembersList();
    });
  }

  sendRegistrationReminder(row) {
    const memberId = row.id;
    const clubId = this.club.id;
    this.reminderBeingSent = true;
    this.clubService.sendRegistrationReminder(memberId, clubId).subscribe(result => {
      this.updateMembersList();
      this.reminderBeingSent = false;
    });
  }

  sendAllSignupReminder() {
    const userId = 0;
    const clubId = this.club.id;
    this.reminderBeingSent = true;
    this.clubService.sendRegistrationReminder(userId, clubId).subscribe(result => {
      this.snackBar.open(result['numEmailsSent'] + ' emails sent!', 'OK', {
        duration: 4000,
      });
      this.updateMembersList();
      this.reminderBeingSent = false;
    });
  }
}
