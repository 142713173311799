import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.css']
})
export class AreYouSureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  buttonClick(answer) {
      return answer;
  }

}
