import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddEditClubPropertyComponent } from '../add-edit-club-property/add-edit-club-property.component';
import { ClubAssetService } from '../../../_services/club-asset.service';
import { ClubService } from '../../../_services/club.service';
import { Observable } from 'rxjs/internal/Observable';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  selector: 'app-club-property',
  templateUrl: './club-property.component.html',
  styleUrls: ['./club-property.component.scss']
})
export class ClubPropertyComponent implements OnInit {

  clubAssets;
  updateTheAssets = 0; //increment by 1 to update the assets
  constructor(
    private dialog: MatDialog,
    private clubAssetService: ClubAssetService,
    private aysService: AreYouSureService,
    private clubService: ClubService) { }

  ngOnInit() {
    this.getClubAssets();
  }

  addClubAssets() {
    const dialogRef = this.dialog.open(AddEditClubPropertyComponent, {
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getClubAssets();
    });
  }


  getClubAssets() {

    this.updateTheAssets++;
    const clubId = this.clubService.getClub().id;
    this.clubAssets = this.clubAssetService.getClubAssets(clubId, true);
  }


  editClubAssets(clubAsset) {
    const dialogRef = this.dialog.open(AddEditClubPropertyComponent, {
      data: { clubAsset },
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getClubAssets();
    });
  }

  deleteClubAsset(clubAsset) {
    this.clubAssetService.deleteClubAsset(clubAsset).subscribe(results => {
      this.getClubAssets();
    });
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
          '<h3>Club Property/Bookings Help</h3>' +
          '<ul id="instructions">' +
          '<li>Step 1 - Add some club property, this can be anything from buildings or pitches to boats or blades.</li>' +
          '<li>Step 2 - Mark the item as "bookable" ' +
          '<li>Step 3 - Either create a specific day of week/time slot for the the item to be booked in ' +
          ' OR mark the item as freely bookable - OR both.</li>' +
          '<li>Once equipment/property has been booked then no one else' +
          ' can book it out for the timeslot that the item has been booked for.</li>' +
          '<li>The booked items will show up in the list of booked items until the day/time is past. Then it will disappear.</li>' +
          '</ul>' +
          '<p>Please Note - Only members who booked the equipment OR administrators can cancel a booking.</p>'
      },
      minWidth: '35vw'
    });
  }
}
