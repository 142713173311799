import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { AddMemberComponent } from '../add-member/add-member.component';
import { ClubService } from '../../../_services/club.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemberService } from '../../../_services/member.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  currentSection = 4;
  totalSections = 4;
  club: any = {};
  onboardingDetails = { numAdmins: 0, numMembers: 0, bankAccountConnected: false };

  clubMembers = [];

  private dialogRef: MatDialogRef<AddMemberComponent>;
  constructor(
    private clubService: ClubService,
    private memberService: MemberService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    // setTimeout(() => this.spinner.show(), 5);
    this.spinner.show();
    this.club = this.clubService.getClub();
    this.checkOnboardingStatus();
    this.getClubMembers();
  }

  changeSection(direction) {
    if (direction === 'next') {
      if (this.currentSection < 4) {
        this.currentSection++;
      }
    } else {
      if (this.currentSection > 1) {
        this.currentSection--;
      }
    }
  }


  addMembers() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      data: {
        currentClub: this.club,
        createType: 'single'
      },
      minWidth: '50vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      id: 'add-members-modal',
    });


    dialogRef.afterClosed().subscribe(result => {
      this.getClubMembers();
    });
  }

  getClubMembers() {
    this.memberService.getAllMembers().subscribe( memberResults => {
      this.clubMembers = memberResults.allClubMembers;
    });
  }

  checkOnboardingStatus() {
    this.clubService.getOnboardingStatus().subscribe(result => {
      this.onboardingDetails = result.onboardingDetails;

      // check if at least one other member has been added
      if (this.onboardingDetails.numMembers < 2) {
        this.currentSection = 1;

        // check if another administrator has been added
      } else if (this.onboardingDetails.numAdmins < 2) {
        this.currentSection = 2;

      // check if the bank account has been connected
      } else if (this.onboardingDetails.bankAccountConnected === false) {
        this.currentSection = 3;
      }

      this.spinner.hide();
    });
  }

}
