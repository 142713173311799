import { Component, OnInit } from '@angular/core';
import { LottoSettingsComponent } from '../lotto-settings/lotto-settings.component';
import { MatDialog } from '@angular/material';
import { CreateLottoComponent } from '../create-lotto/create-lotto.component';
import { LottoService } from '../../../_services/lotto.service';
import { ClubService } from '../../../_services/club.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { MiscService } from '../../../_services/misc.service';
import { environment } from '../../../../environments/environment';
import { PlayLottoComponent } from '../../member-components/play-lotto/play-lotto.component';

@Component({
  selector: 'app-lotto-draws',
  templateUrl: './lotto-draws.component.html',
  styleUrls: ['./lotto-draws.component.scss']
})
export class LottoDrawsComponent implements OnInit {

  parsedClubName = '';
  lottoDraws = [];
  lottoSettings = {id: '', draw_day_of_week : ''};
  private readonly baseUrl = environment.baseUrl;

  constructor(
    private dialog: MatDialog,
    private lottoService: LottoService,
    private miscService: MiscService,
    private clubService: ClubService) { }

  ngOnInit() {

    this.getLottoDraws();
    this.getLottoSettings();
    this.parsedClubName = this.clubService.getClub().name.split(' ').join('-');
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
          '<h3>Lotto Help</h3>' +
          '<p>People can purchase lotto tickets in the club shop once the lotto has been created and is currently enabled.</p>' +
          '<p>Once someone purchases a number of lines for one or multiple draws they will receive an ' +
          'email confirmation with the details of their purchase.</p>' +
          '<p>Their entry/entries will then be viewable to lotto administrators in the Future Entries page.</p>' +
          '<p>A draw will automatically take place on the time/date specified on the lotto settings page.</p>' +
          '<p>Once this happens an email will be sent out to all people who had a valid entry at the time ' +
          'the draw took place notifying them of the winning numbers and if there were any winners of the Jackpot.</p>'
      },
      minWidth: '35vw'
    });
  }

  getLottoDraws() {
    this.lottoService.getLottoDraws(this.clubService.getClub().id).subscribe(result => {
      this.lottoDraws = result;
    });
  }

  getLottoSettings() {
    this.lottoService.getLottoSettings(this.clubService.getClub().id).subscribe(result => {
      this.lottoSettings = result;
    });
  }

  openLottoSettings() {
    const dialogRef = this.dialog.open(LottoSettingsComponent, {
      data: {clubId: this.clubService.getClub().id},
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    }).afterClosed().subscribe(res => {
      this.getLottoSettings();
    });
  }

  createLotto() {
    const dialogRef = this.dialog.open(CreateLottoComponent, {
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getLottoSettings();
    });
  }

  logManualLottoEntry() {
      const viewItemDetails = this.dialog.open(PlayLottoComponent, {
        data: {
          clubId: this.clubService.getClub().id,
          admin: true
        },
        minWidth: '50vw',
        maxWidth: '95vw',
        maxHeight: '95vh',
      });
  }

  copyToClipboard() {

    const lottoURL = this.baseUrl + '/play-lotto/' + this.clubService.getClub().id + '/' + this.parsedClubName;

    this.miscService.copyToClipboard(lottoURL, 'Lotto link copied!');
  }
}
