import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { PaymentService } from '../../../_services/payment.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { MiscService } from '../../../_services/misc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { saveAs } from 'file-saver';
import { PayAmountComponent } from '../../member-components/pay-amount/pay-amount.component';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-club-settings',
  templateUrl: './club-settings.component.html',
  styleUrls: ['./club-settings.component.scss']
})
export class ClubSettingsComponent implements OnInit, OnDestroy {
  bankDetails = {
    business_profile: { name: '', url: ''},
    email: '',
    settings: {payments: { statement_descriptor: ''}},
    statement_descriptor: ''
  };
  clubCurrency = '';

  stripeRedirectUrl: string;
  stripeClientId: string;
  clubStatus: string;
  currentClub: any = {
    id: 0,
    logo_link: '',
    payment_request_fee_added: 1
  };

  // clubflow subscription stuff
  totalPrice = 40;
  hasActiveClubflowSubscription = false;
  pastCFSubscriptionPayments = [];
  isProcessingPayment = false;

  changeBankAccountDetails = false;

  constructor(
    private spinner: NgxSpinnerService,
    private clubService: ClubService,
    private authService: AuthenticationService,
    public aysService: AreYouSureService,
    public miscService: MiscService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private paymentService: PaymentService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {

    // set stripe to pay into the CF stripe account
    if (environment.production) {
      this.paymentService.stripe = Stripe('pk_live_TlDAi9lqbwjX6yyvKyub9TlW');
    } else {
        this.paymentService.stripe = Stripe('pk_test_6SKVL6UqRF1FTlLcJY8i0tnB');
    }
    this.paymentService.elements = this.paymentService.stripe.elements();

    this.clubService.getClubBankAccount().subscribe(result => {
      if (result.clubBankDetails.business_profile) {
        this.bankDetails = result.clubBankDetails;
      }
    });

    this.stripeRedirectUrl =  environment.apiUrl + '/setup-stripe-account';
    this.stripeClientId = environment.stripeClientId;
    this.currentClub = this.clubService.getClub();
    this.clubStatus = this.clubService.getClub().clubStatus;
    this.clubCurrency = this.clubService.getClubCurrency();

    this.getCurrentClubflowSubscriptionDetails();
  }

  ngOnDestroy() {
    this.paymentService.loadStandardStripeDetails();
  }

  // changeBankAccount() {
  //   this.paymentService.createStripeState(this.currentClub.id).subscribe(response => {
  //       const state = response.state;
  //       this.authService.logout();
  //       this.document.location.href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id='
  //       + this.stripeClientId + '&scope=read_write&state=' + state
  //       + '&redirect_uri=' + this.stripeRedirectUrl;
  //   });
  // }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
          '<h2>How To Change Bank Account Details</h2>' +
          '<p>To change your bank account details do the following:</p>' +
          '<ol><li>Login to your Stripe account: <a href="https://dashboard.stripe.com/login"' +
          'target="_blank">https://dashboard.stripe.com/login</a> - (using the "Stripe Account Email" above) </li>' +
          '<li>Click settings - on the left-hand side menu</li>' +
          '<li>Click "bank accounts & scheduling" - under the "payouts and payments" header.</li>' +
          '</ol>' +
          '<p>You should see something similar(with your bank details):</p>' +
          '<img src="https://my-club-admin.s3.eu-west-1.amazonaws.com/images/change-bank-account.png" height="200">' +
          '<p>You can then add a new bank account and remove the old one by clicking edit/add bank account buttons.</p>'
      },
      minWidth: '50vw',
      maxHeight: '95vh',
    });
  }


  imageUploadChange(fileInputEvent: any) {
    if (fileInputEvent.target.files && fileInputEvent.target.files[0]) {
      this.spinner.show();
      const reader = new FileReader();
      reader.onload = (event) => {
        // then upload the file to the server
        const uploadData = new FormData();
        uploadData.append('image', fileInputEvent.target.files[0]);
        uploadData.append('clubId', this.currentClub.id);

        this.miscService.uploadClubLogo(uploadData).subscribe(result => {
          this.spinner.hide();
          this.currentClub.logo_link = result.logoUrl;
        });
      };
      reader.readAsDataURL(fileInputEvent.target.files[0]);
    }
  }

  downloadMemberMembershipsList() {
    this.miscService.downloadMemberMembershipsList(this.currentClub.id).subscribe(response => {

      const data = response.csv;

      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');

      const blob = new Blob([csvArray], {type: 'text/csv' });
      saveAs(blob, 'membersAndMemberships.csv');
    });
  }


  updatePaymentRequestFees(addOrRemove) {

    this.currentClub.payment_request_fee_added = addOrRemove;
    this.clubService.updateClubDetails(this.currentClub)
    .subscribe(res => {
        const currentClubToUpdate = JSON.parse(localStorage.getItem('club'));
        currentClubToUpdate.payment_request_fee_added = addOrRemove;
        localStorage.setItem('club', JSON.stringify(currentClubToUpdate));

        this.matSnackBar.open(
          'Payment request fees updated!',
          'OK',
          {
            duration: 3000
          });
      });
  }


  payMonthly() {
    this.isProcessingPayment = true;
    const dialogRef = this.dialog.open(PayAmountComponent, {
      data: {
        currentClub: this.clubService.getClub(),
        paymentAmountSelected: this.totalPrice,
        paymentType: 'clubflow_subscription',
        selectedPaymentTimeframe: 'monthlyPayment'
      },
      minWidth: '50vw',
      maxHeight: '95vh',
      maxWidth: '95vw'
    });

    dialogRef.afterClosed().subscribe( result => {
      this.getCurrentClubflowSubscriptionDetails();
    });
  }

  cancelMonthlySubscription() {

    const clubSubscriptionId = this.pastCFSubscriptionPayments[0].charge_id;
    this.paymentService.cancelCFStripeSubscription(clubSubscriptionId).subscribe(result => {
      this.getCurrentClubflowSubscriptionDetails();
    });
  }


  getCurrentClubflowSubscriptionDetails() {

    this.paymentService.getClubCFSubscriptionPayments(this.currentClub.id).subscribe(result => {
      this.isProcessingPayment = false;
      this.pastCFSubscriptionPayments = result.payments_made;
      this.hasActiveClubflowSubscription = result.has_active_clubflow_subscription;
    });
  }

}
