import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ClubService } from './club.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  private currentClub;
  private readonly apiUrl = environment.apiUrl;

  constructor(
    private clubService: ClubService,
    private http: HttpClient) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentClub = JSON.parse(localStorage.getItem('club'));
   }


  getAllMembers(filterText?, direction?, pageIndex?): Observable<any> {
    const club = this.clubService.getClub();
    let url = this.apiUrl + '/clubs/' + club.id + '/members';
    if (direction) {
      url += '?sortDirection=' + direction + '&pageIndex=' + pageIndex + '&filterText=' + filterText;
    }

    return this.http.get(url)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getMember(memberId): Observable<any> {
    const club = this.clubService.getClub();
    return this.http.get(this.apiUrl + '/members/' + memberId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getMemberDetailsByCardId(membershipcardId){
    const club = this.clubService.getClub();
    return this.http.get(this.apiUrl + '/member-by-membershipcard-id/' + membershipcardId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  getUserMembersDetails(): Observable<any> {
    const club = this.clubService.getClub();
    return this.http.get(this.apiUrl + '/club-user-details/' + club.id)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  createMember(newMemberDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/members', { newMemberDetails })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  deleteMember(memberId): Observable<any> {
    return this.http.delete(this.apiUrl + '/members/' + memberId,
      {
        params: { clubId: this.currentClub.id }
      });
  }


  updateMember(memberDetails): Observable<any> {

    return this.http.patch(this.apiUrl + '/members/' + memberDetails.id,
      {
        data: {
          first_name: memberDetails.first_name,
          surname: memberDetails.surname,
          dob: memberDetails.dob,
          users: memberDetails.users,
          affiliation_id: memberDetails.affiliation_id,
          clubId: this.currentClub.id
        },
      }).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  completeMembersUpload(clubId, members, groups): Observable<any> {
    return this.http.post(this.apiUrl + '/members/complete-members-upload', {
      clubId,
      members,
      groups
    }).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

}
