import { ClubService } from '../../../_services/club.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { OnInit, Component, ViewChild, AfterViewInit, EventEmitter} from '@angular/core';
import {MatPaginator, MatSort, MatDialog, MatSnackBar} from '@angular/material';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { RefundComponent } from '../refund/refund.component';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { PaymentService } from '../../../_services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { MiscService } from '../../../_services/misc.service';
import { StatsService } from '../../../_services/stats.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements AfterViewInit {

  searchField;

  data = [];
  allPayments = [];
  clubCurrency;
  clubId;
  displayedColumns = ['created_at', 'user_name', 'amount_paid',  'payment_items', 'actions'];
  tableHeaders = [
    {title: 'Date', getName: 'created_at'},
    {title: 'Name', getName: 'user_name'},
    {title: 'Amount Paid', getName: 'amount_paid'},
    {title: 'Member/Payment Items', getName: 'payment_items'},
    {title: 'Actions', getName: 'actions'}
  ];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  currentMonthsPayments;
  lastMonthsPayments;
  toAndFromDates;
  dateChange: EventEmitter<any> = new EventEmitter<any>();
  fromDate = moment('2018-01-01T00:00:00').format('YYYY-MM-DD 00:00:00');
  toDate = moment(new  Date).format('YYYY-MM-DD 23:59:00');


  // chart options
  legend = true;
  legendTitle = '';
  showLabels = false;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = false;
  yAxisLabel = 'Amount';
  timeline = false;
  autoScale = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  multi: any[];


  // subscription details
  subscriptions = [];



  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private clubService: ClubService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private aysService: AreYouSureService,
    private miscService: MiscService,
    private statsService: StatsService,
    private matSnackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService) {}


    ngOnInit() {
      this.searchField = new FormGroup({
        searchText: new FormControl(''),
      });
    }
  

    ngAfterViewInit() {

      this.clubCurrency = this.clubService.getClubCurrency();
      this.clubId =  this.clubService.getClub().id;

      this.dateChange.subscribe(result => {
        this.getPaymentGraph();
      });

      // If the user changes the sort order, reset back to the first page.
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      this.searchField.valueChanges.subscribe(() => this.paginator.pageIndex = 0);

      merge(this.sort.sortChange, this.paginator.page, this.dateChange, this.searchField.valueChanges)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;

            if (this.searchField.value.searchText.length > 2) {
              return this.clubService.getClubPayments(
                this.clubId,
                this.sort.active,
                this.sort.direction,
                this.paginator.pageIndex,
                this.fromDate,
                this.toDate,
                this.searchField.value.searchText
              );
            } else {
              return this.clubService.getClubPayments(
                this.clubId,
                this.sort.active,
                this.sort.direction,
                this.paginator.pageIndex,
                this.fromDate,
                this.toDate
              );            }



          }),
          map(data => {
            // Flip flag to show that loading has finished.
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
            this.resultsLength = data.clubPaymentsCount;
            this.currentMonthsPayments = data.paymentsCurrentMonth;
            this.lastMonthsPayments = data.paymentsLastMonth;

            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            // Catch if the GitHub API has reached its rate limit. Return empty data.
            this.isRateLimitReached = true;
            return observableOf([]);
          })
        ).subscribe(data => this.data = data.clubPayments);

        this.getPaymentGraph();
    }


    getPaymentGraph() {
      this.statsService.getPaymentsGraph(this.fromDate, this.toDate).subscribe(result => {

        this.multi = result.map(group => {
          group.series = group.series.map(dataItem => {
            dataItem.name = new Date(dataItem.name);
            return dataItem;
          });
          return group;
        });
      });
    }


    dateTickFormatting(val: any): String {
      if (val instanceof Date) {
        const options = { month: 'long', year: 'numeric' };
        return (<Date>val).toLocaleString('en-EN', options);
      }
    }

    onChangeDate(event, datePicker) {
      if (datePicker === 'from') {
        this.fromDate = event.format('YYYY-MM-DD 00:00:00');
      } else {
        this.toDate = event.format('YYYY-MM-DD 00:00:00');
      }
      this.dateChange.emit('update');
    }

    downloadPaymentsCSV() {
      this.miscService.downloadPaymentsCSV(this.clubId, this.fromDate, this.toDate).subscribe(response => {
        const data = response.csv;

        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');

        const blob = new Blob([csvArray], {type: 'text/csv' });
        saveAs(blob, 'payments.csv');
      });
    }

    fullRefund(paymentData) {
      this.spinner.show();

      this.paymentService.refundFullPayment(paymentData).subscribe(result => {
        this.spinner.hide();
        this.matSnackbar.open('Refund complete!', 'OK', {
          duration: 3000,
        });
        this.ngAfterViewInit();
      });
    }

    showPartialRefund(paymentData) {
        const dialogRef = this.dialog.open(RefundComponent, {
          data: {
            currentClubId: this.clubId,
            paymentData
          },
          maxWidth: '95vw',
          minWidth: '35vw'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngAfterViewInit();
        });
    }
}
