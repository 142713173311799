import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../_services/stats.service';
import { ClubService } from '../../../_services/club.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-membership-reports',
  templateUrl: './membership-reports.component.html',
  styleUrls: ['./membership-reports.component.scss']
})
export class MembershipReportsComponent implements OnInit {

  constructor(
    private statsService: StatsService,
    private clubService: ClubService,
    private spinner: NgxSpinnerService,
    ) { }
  totalMembershipsAmountOutstanding = 0;
  totalMembershipsAmountPaid = 0;
  totalMembershipsDiscounts = 0;
  memberships = [];
  clubCurrency;

  displayedColumns = [
    'groupName',
    'numMembersPaidUnpaid',
    'amountDue',
    'amountPaidUnpaid',
    'amountPaidCash',
    'amountPaidCard'
  ];

  tableHeaders = [
    {title: 'Membership Name', getName: 'groupName'},
    {title: 'Num Members Paid/Total', getName: 'numMembersPaidUnpaid'},
    {title: 'Cash Paid', getName: 'amountPaidCash'},
    {title: 'Card Paid', getName: 'amountPaidCard'},
    {title: 'Amount Paid/Total', getName: 'amountPaidUnpaid'},
    {title: 'Amount Due', getName: 'amountDue'},
  ];

  ngOnInit() {

  this.spinner.show();
    this.statsService.getMembershipStats().subscribe( result => {
      this.memberships = result.memberships;
      this.totalMembershipsAmountOutstanding = result.totalMembershipsAmountOutstanding;
      this.totalMembershipsAmountPaid = result.totalMembershipsAmountPaid;
      this.totalMembershipsDiscounts = result.totalMembershipsDiscounts;
      this.spinner.hide();
    });
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  printDebtors() {
    window.print();
  }

}
