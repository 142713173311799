import { Component, OnInit } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddMemberComponent } from '../add-member/add-member.component';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { environment } from '../../../../environments/environment';
import { LogCashPaymentComponent } from '../log-cash-payment/log-cash-payment.component';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../_services/payment.service';
import { GroupsService } from '../../../_services/groups.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { PaymentRequestService } from '../../../_services/payment-request.service';
import { MemberService } from '../../../_services/member.service';

@Component({
  selector: 'app-outstanding-amounts',
  templateUrl: './outstanding-amounts.component.html',
  styleUrls: ['./outstanding-amounts.component.scss']
})
export class OutstandingAmountsComponent implements OnInit {

  paymentRequestAndAmounts = [];
  currentClub = { id: '', name: '', currency: ''};
  completedTableRows = [];
  pendingTableRows = [];
  displayedTableRows = [];
  totalPaid = 0;
  totalRefunded = 0;
  totalOutstanding = 0;
  currentTabIndex = 0;
  paymentRequest = { name: '', date: ''};
  clubCurrency;
  paymentRequestId;
  stripeRedirectUrl: string;
  stripeClientId: string;
  clubGroups;
  selectedGroup = '';
  amountsOutsandingLoaded = false;

  myControl = new FormControl();
  users;
  filteredMembers;

  displayedColumns = ['member_name', 'total_refunded', 'total_paid', 'amount_outstanding', 'actions'];
  tableHeaders = [
    {title: 'Member Name', getName: 'member_name'},
    {title: 'Refunded', getName: 'total_refunded'},
    {title: 'Paid', getName: 'total_paid'},
    {title: 'Amount Outstanding', getName: 'amount_outstanding'},
    {title: 'Actions', getName: 'actions'},
  ];


  selectedEvent;
  selectedMember;
  amountOwed = 0;

  constructor(private clubService: ClubService,
    private dialog: MatDialog,
    private aysService: AreYouSureService,
    private authService: AuthenticationService,
    private memberService: MemberService,
    private paymentRequestService: PaymentRequestService,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private groupsService: GroupsService) { }

  ngOnInit() {
    this.currentClub = JSON.parse(localStorage.getItem('club'));
    this.clubCurrency = this.clubService.getClubCurrency();
    this.stripeRedirectUrl =  environment.apiUrl + '/setup-stripe-account';
    this.stripeClientId = environment.stripeClientId;

    this.route.params.subscribe((params) => {
      this.paymentRequestId = params['paymentRequestId'];
    });

    this.updatePaymentRequestAndAmounts();
    this.getAllMembers();
  }

  showHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: {
        helpText:
        '<h3>Help</h3>' +
        '<ul id="admin-instructions">' +
        '<li>To add an amount outstanding just fill in the number in the amount outstanding column opposite the ' +
        'relevant member.</li>' +
        '<li>If that member is active, ie. they have logged in at least once, then you can click the "Send Payment ' +
        'Reminder" button which will send them an email informing them that they owe X amount and they can pay by ' +
        'logging in.</li>' +
        '<li>Once a user makes a payment then the value opposite their name will update automatically.</li>' +
        '<li>All payments are recorded on the "Payments Made" page.</li>' +
        '</ul>'
      },
      minWidth: '50vw',
      maxHeight: '95vh',
    });
  }

  getGroups() {
    this.groupsService.getGroups(this.currentClub.id).subscribe(result => {
      this.clubGroups = result.groups;
    });
  }

  haveFullyPaid(amountPaid, amountOutstanding) {
    return (amountPaid > 0 && amountOutstanding === 0);
  }

  haveOutstandingOrNothing(amountPaid, amountOutstanding) {
    return (amountPaid >= 0 && amountOutstanding !== 0) || (amountOutstanding === 0 && amountPaid === 0);
  }

  changeTab(index) {
    this.currentTabIndex = index;

    if (this.currentTabIndex === 1) {
      this.displayedTableRows = this.completedTableRows;
    } else {
      this.displayedTableRows = this.pendingTableRows;
    }

    // updating the totals
    this.totalPaid = this.displayedTableRows.reduce(function(sum, item) {
      return sum = sum + item.amount_paid;
    }, 0);
    this.totalOutstanding = this.displayedTableRows.reduce(function(sum, item) {
      return sum = sum + item.amount_outstanding;
    }, 0);
    this.totalRefunded = this.displayedTableRows.reduce(function(sum, item) {
      return sum = sum + item.amount_refunded;
    }, 0);
  }


  refundAmountPaid(memberDetails) {

    memberDetails.club_id = this.currentClub.id;
    memberDetails.payment_request_id = this.paymentRequestId;

    memberDetails.processing_refund = true;
    this.paymentService.refundEventPayment(memberDetails).subscribe( result => {
      this.snackBar.open('Refund Processed!', 'OK', {
        duration: 4000,
      });
      this.updatePaymentRequestAndAmounts();
    }, errorMessage => {
      this.snackBar.open('Error Processing Refund: Please contact support!', 'OK', {
        duration: 4000,
      });
      memberDetails.processing_refund = false;
    });
  }

  logCashPayment(memberDetails) {
    const dialogRef = this.dialog.open(LogCashPaymentComponent, {
      data: {
        cashPaymentType: 'paymentRequest',
        paymentRequestId: this.paymentRequestId,
        paymentFor: this.paymentRequest.name,
        amountOwed: memberDetails.amount_outstanding,
        memberName: memberDetails.member_name,
        memberId: memberDetails.member_id
      },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updatePaymentRequestAndAmounts();
    });
  }

  updatePaymentRequestAndAmounts(groupId?) {

    this.paymentRequestService.getClubMembersAmounts(this.currentClub.id, this.paymentRequestId, groupId).subscribe(res => {
      this.completedTableRows = res.tableRows.filter(
        tableRow => {
          return this.haveFullyPaid(tableRow.amount_paid, tableRow.amount_outstanding);
        }
      );
      this.pendingTableRows = res.tableRows.filter(
        tableRow => {
          return this.haveOutstandingOrNothing(tableRow.amount_paid, tableRow.amount_outstanding);
      });
      this.paymentRequest = res.paymentRequest;
      this.amountsOutsandingLoaded = true;
      this.changeTab(this.currentTabIndex);
    });
  }


  updateField(updatedValue, row) {
    // front end validation: make sure entered amount not less than 0
    if (parseInt(updatedValue, 10) < 0) {
      this.updatePaymentRequestAndAmounts();
      this.snackBar.open('Validation Error: Amount cannot be less than 0', 'OK', {
        duration: 4000,
      });
    } else {
      const userId = row.member_id;
      const paymentRequestId = this.paymentRequestId;
      // update the amount outstanding for a payment request
      this.paymentRequestService.updateAmountOutstanding(updatedValue, paymentRequestId, userId).subscribe(response => {
        // TODO - update the totals seperatly so we don't have to reload the whole page...
        this.updatePaymentRequestAndAmounts();
      }, errorMessage => {
        // display error message & reload page - TODO: MAKE NICER LATER!
        this.snackBar.open('Error Updating Amounts: Please contact support!', 'OK', {
          duration: 4000,
        });
        this.updatePaymentRequestAndAmounts();
      });
    }
  }

  sendPaymentReminder(memberDetails) {
    memberDetails.reminder_being_sent = true;
    this.paymentRequestService.postPaymentReminder({
      clubId: this.currentClub.id,
      memberId: memberDetails.member_id
    }).subscribe( result => {
      this.updatePaymentRequestAndAmounts();
    });
  }

  sendAllPaymentReminder() {
    this.paymentRequestService.postGroupPaymentReminder({
      clubId: this.currentClub.id,
      groupId: this.selectedGroup,
      paymentRequestId: this.paymentRequestId
    }).subscribe( result => {

      this.snackBar.open(result['numEmailsSent'] + ' emails sent!', 'OK', {
        duration: 4000,
      });
      this.updatePaymentRequestAndAmounts();
    });
  }


  getAllMembers() {
    this.memberService.getAllMembers().subscribe(results => {
      this.users = results.allClubMembers;

      this.filteredMembers = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.first_name),
        map(first_name => first_name ? this._filter(first_name) : this.users.slice())
      );
    });
  }

  private _filter(first_name: String): string[] {

    let filterValue;
    if (first_name === '') {
      filterValue = '';
    } else {
      filterValue = first_name.toLowerCase();
    }
    return this.users.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }

  displayFn(user?): string | undefined {
    return user ? user.first_name : undefined;
  }

  addMemberToSelection() {
    this.paymentRequestService.addMemberToPaymentRequest(this.paymentRequestId, this.myControl.value.id).subscribe(result => {
      if (result.status === 'exists') {
        this.snackBar.open('Member already has a payment request - either completed or pending', 'OK', {
          duration: 4000,
        });
      }
      // Reset the value of the input
      this.myControl.setValue('');
      this.updatePaymentRequestAndAmounts();
    });
  }

  removeMemberFromSelection(eventMemberId) {
    this.paymentRequestService.removeMemberFromPaymentRequest(eventMemberId).subscribe(result => {

      // Reset the value of the input
      this.myControl.setValue('');
      this.updatePaymentRequestAndAmounts();
    });
  }
}
