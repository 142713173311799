import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LottoService } from '../../../_services/lotto.service';

@Component({
  selector: 'app-lotto-settings',
  templateUrl: './lotto-settings.component.html',
  styleUrls: ['./lotto-settings.component.scss']
})
export class LottoSettingsComponent implements OnInit {


  lottoSettings = {
    enabled: false,
    draw_day_of_week: '',
    draw_time_of_day: '',
    num_pairs_of_numbers: '',
    line_price: '',
    prize_amount: '',
    prize_increase_per_draw: '',
    max_prize_amount: '',
    max_num_draws: '',
    num_lucky_dips: '',
    lucky_dip_prize_amount: '',
  };
  errorMessage = [];

  constructor(
    private dialog: MatDialog,
    private lottoService: LottoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.lottoService.getLottoSettings(this.data.clubId).subscribe(result => {
      this.lottoSettings = result;
    });
  }

  getLottoSettings() {
    this.lottoService.getLottoSettings(this.data.clubId).subscribe(result => {
      this.lottoSettings = result;
    });
  }

  updateLottoSettings() {
    this.errorMessage = [];
    this.lottoService.updateLottoSettings(this.data.clubId, this.lottoSettings).subscribe(result => {

      if (result.errors) {
        Object.values(result.errors).forEach(value => {
          this.errorMessage.push(value[0]);
        });
      } else {
        this.lottoSettings = result;
        this.dialog.closeAll();
      }

    });
  }
}
