import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { PaymentRequestService } from '../../../_services/payment-request.service';

@Component({
  selector: 'app-payment-request-member-upload',
  templateUrl: './manage-payment-request-upload.component.html',
  styleUrls: ['./manage-payment-request-upload.component.scss']
})
export class ManagePaymentRequestUploadComponent implements OnInit {

  paymentRequestUpload = [];
  clubId;
  paymentRequestName;
  paymentRequestDate;
  clubCurrency = '';

  constructor(public dialogRef: MatDialogRef<ManagePaymentRequestUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentRequestService: PaymentRequestService,
    private clubService: ClubService) { }

  groupsDisplayedColumns = [ 'memberName'];
  groupsTableHeaders = [
      {title: 'Name', getName: 'memberName'},
    ];

  displayedColumns = [ 'paymentRequestName', 'paymentRequestDate', 'firstName', 'lastName', 'memberEmail', 'memberDOB', 'amountOwed'];
  tableHeaders = [
    {title: 'Request Name', getName: 'paymentRequestName'},
    {title: 'Request Date', getName: 'paymentRequestDate'},
    {title: 'First Name', getName: 'firstName'},
    {title: 'Last Name', getName: 'lastName'},
    {title: 'Email', getName: 'memberEmail'},
    {title: 'Date Of Birth', getName: 'memberDOB'},
    {title: 'Amount Owed', getName: 'amountOwed'},
  ];

  ngOnInit() {
    this.paymentRequestUpload = this.data.paymentRequestData.paymentRequestDetails;
    this.paymentRequestDate = this.data.paymentRequestData.paymentRequestDate;
    this.paymentRequestName = this.data.paymentRequestData.paymentRequestName;
    this.clubId = this.clubService.getClub().id;
    this.clubCurrency = this.clubService.getClubCurrency();
  }

  completePaymentRequestUpload() {
    const paymentRequestDetails = { 
      name: this.paymentRequestName,
      paymentRequestDate: this.paymentRequestDate,
      paymentRequestType: 'clubflow-form' 
    };
    this.paymentRequestService.createPaymentRequest(paymentRequestDetails, this.clubId, this.paymentRequestUpload).subscribe( result => {
      if (result.status === 'ok') {
        this.dialogRef.close(result.paymentRequest.id);
      }
    });
  }
}
