import { Component, OnInit, Inject } from '@angular/core';
import { GroupsService } from '../../../_services/groups.service';
import { ClubService } from '../../../_services/club.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AddEditGroupComponent } from '../add-edit-group/add-edit-group.component';
import { AddEditShopItemComponent } from '../add-edit-shop-item/add-edit-shop-item.component';
import { ShopService } from '../../../_services/shop.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MiscService } from '../../../_services/misc.service';


@Component({
  selector: 'app-shop-items',
  templateUrl: './shop-items.component.html',
  styleUrls: ['./shop-items.component.scss']
})
export class ShopItemsComponent implements OnInit {

  private readonly baseUrl = environment.baseUrl;
  groups = [];
  club;
  clubCurrency;
  shopItems = [];
  retrievedShopItems = false;
  displayedHeaders = ['actions', 'name', 'member_count'];
  displayedColumns = ['actions', 'name', 'member_count'];
  tableHeaders = [
    {title: 'Actions', getName: 'actions'},
    {title: 'Name', getName: 'name'},
    {title: 'Member Count', getName: 'member_count'},
  ];

  constructor(
    private shopService: ShopService,
    private clubService: ClubService,
    private miscService: MiscService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private aysService: AreYouSureService) {}

  ngOnInit() {
    this.clubCurrency =  this.clubService.getClubCurrency();
    this.club =  this.clubService.getClub();
    this.getShopItems();
  }

  copyToClipboard(shopTypeSelected) {

    const shopType = shopTypeSelected === 'public' ? 'public' : 'private';
    const shopURL = this.baseUrl + '/' + this.club.id + '/shop/' + shopType + '/'  + this.parseClubName(this.club.name);

    this.miscService.copyToClipboard(shopURL, 'Shop url copied!');
  }

  createShopItem() {
    const dialogRef = this.dialog.open(AddEditShopItemComponent, {
      data: {
        action: 'create'
      },
      id: 'group-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getShopItems();
    });
  }



  getShopItems() {
    const shopEditor = true;
    this.shopService.getShopItems(shopEditor, 'private').subscribe( result => {
      this.shopItems = result.shopItems;
      this.retrievedShopItems = true;
    });
  }


  editShopItem(selectedShopItem) {
    const dialogRef = this.dialog.open(AddEditShopItemComponent, {
      data: {
        selectedShopItem: selectedShopItem,
      },
      id: 'group-modal',
      minWidth: '45vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getShopItems();
    });
  }

  deleteShopItem(selectedShopItem) {
    this.shopService.deleteShopItem(selectedShopItem.id).subscribe(response => {
      if (response.status === 'ok')  {
        this.getShopItems();
      }
    });
  }


  parseClubName(clubName) {

    const fixedClubName = clubName.split(' ').join('-');
    return fixedClubName;
  }
}
