import { Component, OnInit } from '@angular/core';
import { LottoService } from '../../../_services/lotto.service';
import { ActivatedRoute } from '@angular/router';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-lotto-draw-entries',
  templateUrl: './lotto-draw-entries.component.html',
  styleUrls: ['./lotto-draw-entries.component.scss']
})
export class LottoDrawEntriesComponent implements OnInit {

  constructor(
    private lottoService: LottoService,
    private clubService: ClubService,
    private route: ActivatedRoute) { }

  lottoDrawDetails;
  lottoDraw = { winning_numbers: '', draw_date_time: ''};
  drawId;
  winners;
  luckyDipWinners;
  clubCurrency;

  ngOnInit() {

    this.clubCurrency = this.clubService.getClubCurrency();

    this.route.params.subscribe((params) => {
      this.drawId = params['lottoDrawId'];

      // load the lotto entries for that lottow draw
      this.getLottoDrawEntries(this.drawId);

      this.lottoService.getLottoDraw(this.drawId).subscribe(result => {
        this.lottoDraw = result;
      });
    });
  }

  getLottoDrawEntries(drawId) {
    this.lottoService.getLottoDrawEntries(drawId).subscribe(result => {
      this.winners = result.winners;
      this.lottoDrawDetails = result.entries;
      this.luckyDipWinners = result.luckyDipWinners;
    });
  }


}
