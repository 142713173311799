import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../_services/payment.service';
import { ClubService } from '../../../_services/club.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AreYouSureService } from '../../../_services/are-you-sure.service';

@Component({
  selector: 'app-ongoing-payments',
  templateUrl: './ongoing-payments.component.html',
  styleUrls: ['./ongoing-payments.component.scss']
})
export class OngoingPaymentsComponent implements OnInit {


  clubId;
  clubCurrency;
  totalMMR = 0;
  currentSubscriptions = [];
  pausedSubscriptions = [];
  inTableSubscriptions = [];
  incompleteOrPastDue = [];
  canceledSubscriptions = [];
  viewingNow = 'current';

  constructor(
    private paymentService: PaymentService,
    private clubService: ClubService,
    private spinner: NgxSpinnerService,
    public aysService: AreYouSureService
  ) { }

  ngOnInit() {
    this.clubId =  this.clubService.getClub().id;
    this.clubCurrency = this.clubService.getClubCurrency();
    this.getStripeSubscriptions();
  }


  searchSubscriptions(name: any) {
    if (name.length > 1) {
      this.inTableSubscriptions = this.currentSubscriptions.filter(item => {
        const upperCaseMemberName = item.metadata.customer_name.toUpperCase();
        const upperCaseSearchName = name.toUpperCase();
        return upperCaseMemberName.indexOf(upperCaseSearchName) !== -1;
      });
    } else {
      this.inTableSubscriptions =  this.currentSubscriptions;
    }
  }

  getStripeSubscriptions() {
    this.viewingNow = 'current';
    this.spinner.show();
    this.paymentService.getStripeSubscriptions(this.clubId).subscribe(result => {

      this.currentSubscriptions = result.subscriptionData.currentSubscriptions;
      this.pausedSubscriptions = result.subscriptionData.pausedSubscriptions;
      this.incompleteOrPastDue = result.subscriptionData.incompleteOrPastDue;
      this.totalMMR = result.totalMMR / 100;

      this.inTableSubscriptions = this.currentSubscriptions;
      this.spinner.hide();
    });

    
    this.paymentService.getStripeSubscriptions(this.clubId, 'cancelled').subscribe(result => {
      this.canceledSubscriptions = result.subscriptionData.canceledSubscriptions.data;
    });
  }

  updateStripeSubscriptions(subscriptionId, command) {
    this.paymentService.updateStripeSubscription(subscriptionId, this.clubId, command).subscribe(result => {
      this.getStripeSubscriptions();
    });
  }

  cancelStripeSubscriptions(subscriptionId) {
    this.paymentService.cancelStripeSubscription(subscriptionId, this.clubId).subscribe(result => {
      this.getStripeSubscriptions();
    });
  }


  updateAllClubSubscriptions(command) {
    this.spinner.show();
    this.paymentService.updateAllClubSubscriptions(this.clubId, command).subscribe(result => {
      this.getStripeSubscriptions();
    });
  }

}
