import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ClubService } from '../../../_services/club.service';
import { MemberService } from '../../../_services/member.service';

@Component({
  selector: 'app-manage-member-upload',
  templateUrl: './manage-member-upload.component.html',
  styleUrls: ['./manage-member-upload.component.scss']
})
export class ManageMemberUploadComponent implements OnInit {

  membersDetails = [];
  groupsToCreate = [];
  clubId;

  constructor(
    public dialogRef: MatDialogRef<ManageMemberUploadComponent>,
    private memberService: MemberService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private clubService: ClubService) { }


  displayedColumns = [ 'first_name', 'surname', 'memberEmail', 'memberSecondEmail', 'memberDOB'];
  tableHeaders = [
    {title: 'First Name', getName: 'first_name'},
    {title: 'Last Name', getName: 'surname'},
    {title: 'Email', getName: 'memberEmail'},
    {title: 'Secondary Email (Optional)', getName: 'memberSecondEmail'},
    {title: 'Date Of Birth', getName: 'memberDOB'}
  ];

  ngOnInit() {
    this.membersDetails = this.data.memberData.membersDetails;
    this.clubId = this.clubService.getClub().id;
  }

  completeMembersUpload() {

    this.memberService.completeMembersUpload(this.clubId, this.membersDetails, this.groupsToCreate).subscribe( result => {
      if (result.status === 'ok') {
        this.snackBar.open(result.numMembersCreated + ' new members created!', 'OK', {
          duration: 4000,
        });
        this.dialogRef.close();
      }
    });
  }
}
