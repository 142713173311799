import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ClubService } from './club.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private clubService: ClubService) { }

  getShopItems(shopEditor, shopType, clubId?): Observable<any> {

    if (typeof clubId === 'undefined') {
      clubId = this.clubService.getClub().id;
    }

    return this.http.get(this.apiUrl + '/clubs/' + clubId + '/shop-items?shopEditor=' + shopEditor + '&shopType=' + shopType)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  checkCartItems(cartItems, shopType): Observable<any> {

    return this.http.post(this.apiUrl + '/check-cart-items',
      {
        'cartItems': cartItems,
        'shopType': shopType
      }
    )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  createShopItem(formData): Observable<any> {
    const clubId = this.clubService.getClub().id;
    formData.append('clubId', clubId);

    return this.http.post(this.apiUrl + '/shop-items', formData ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteShopItem(shopItemId): Observable<any> {
    return this.http.delete(this.apiUrl + '/shop-items/' + shopItemId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateShopItem(shopItemId, shopItemFormData): Observable<any> {
    const clubId = this.clubService.getClub().id;
    shopItemFormData.append('clubId', clubId);

    return this.http.post(this.apiUrl + '/shop-items/' + shopItemId, shopItemFormData)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getShopOrder(shopOrderId): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.get(this.apiUrl + '/shop-order/' + shopOrderId, { params: { clubId } })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getShopOrders(dateRange): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.get(this.apiUrl + '/shop-orders?clubId=' + clubId + '&dateRange=' + dateRange)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getShopSettings(clubId): Observable<any> {

    return this.http.get(this.apiUrl + '/shop-settings?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  updateShopSettings(shopSettingsData) {
    const clubId = this.clubService.getClub().id;
    shopSettingsData.clubId = clubId;

    return this.http.post(this.apiUrl + '/shop-settings', shopSettingsData)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
