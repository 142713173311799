import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

constructor(private http: HttpClient) { }

private readonly apiUrl = environment.apiUrl;

  createBooking(bookingDetails): Observable<any> {
    return this.http.post(this.apiUrl + '/bookings', bookingDetails)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  getBookings(clubId): Observable<any> {
    return this.http.get(this.apiUrl + '/bookings' + '?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  editBooking(bookingDetails): Observable<any> {
    return this.http.put(this.apiUrl + '/bookings', bookingDetails)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  deleteBooking(clubId, bookingId): Observable<any> {
    return this.http.delete(this.apiUrl + '/bookings/' + bookingId + '?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  getFreeTimeSlots(clubId, clubAssetId, selectedDate): Observable<any> {
    return this.http.get(
        this.apiUrl + '/booking-free-time-slots' +
        '?clubId=' + clubId +
        '&clubAssetId=' + clubAssetId +
        '&selectedDate=' + selectedDate
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  getFreeDurationSlots(clubId, clubAssetId, selectedDate, startTime): Observable<any> {
    return this.http.get(
        this.apiUrl + '/booking-free-duration-slots' +
        '?clubId=' + clubId +
        '&clubAssetId=' + clubAssetId +
        '&selectedDate=' + selectedDate +
        '&startTime=' + startTime
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
