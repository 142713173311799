import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  private url: string = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar) { }


  copyToClipboard(copyToClipboardText, successMessage) {

    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', copyToClipboardText);

      e.preventDefault();
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);

    this.snackBar.open(successMessage, 'OK', {
      duration: 3000,
    });
  }

  postContactMessage(values): Observable<any> {

    return this.http.post(`${this.url}/contact`, { 'email': values.email, 'message': values.message })
    .pipe(
      map((response: Response) => {
        return response;
      })
    );

  }

  postSetupMessage(values): Observable<any> {

    return this.http.post(`${this.url}/setup-message`,
      { 'email': values.email, 'clubName': values.clubName, 'numMembers': values.numMembers, 'phoneNumber': values.phoneNumber }
    ).pipe(
      map((response: Response) => {
        return response;
      })
    );

  }

  toggleEmailNotifications(club_id) {

    return this.http.post(`${this.url}/toggle-email-notifications`, {club_id})
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  uploadClubLogo(uploadData) {

    return this.http.post(`${this.url}/upload-club-logo`, uploadData)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }


  downloadMemberMembershipsList(clubId) {

    return this.http.post(`${this.url}/generate-club-members-membership-list`, {clubId})
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  downloadPaymentsCSV(clubId, fromDate, toDate) {

    return this.http.post(`${this.url}/generate-club-payments-csv`, {clubId, fromDate, toDate})
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}

