import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClubService } from './club.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiUrl = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private clubService: ClubService
    ) { }

  getClubUsers(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(this.apiUrl + '/user?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }
}
