import { Component, Input, OnInit } from '@angular/core';
import { BookingService } from '../../../_services/booking.service';
import { ClubService } from '../../../_services/club.service';
import { ClubAssetService } from '../../../_services/club-asset.service';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatDialog } from '@angular/material';
import { PayAmountComponent } from '../pay-amount/pay-amount.component';

@Component({
  selector: 'app-club-asset-bookings',
  templateUrl: './club-asset-bookings.component.html',
  styleUrls: ['./club-asset-bookings.component.scss']
})
export class ClubAssetBookingsComponent implements OnInit {

  bookingType = 'premadeSlot';
  bookingDays = [];
  bookableSlots;
  addNewBooking = false;
  clubAssets;

  adhocBookableAssets = [];
  premadeBookableAssets = [];
  isInitialized = false;

  clubCurrency;
  clubAssetId;
  startTime;
  timeBooked;
  note;
  selectedDate;
  clubId;
  freeTimeSlots;
  durationsAvailable;
  currentUserId;
  isAdmin;

  @Input() updateAssets: string;


  constructor(
    private bookingService: BookingService,
    private clubService: ClubService,
    private aysService: AreYouSureService,
    private dialog: MatDialog,
    private authService: AuthenticationService,
    private clubAssetService: ClubAssetService) { }

  ngOnInit() {
    this.clubCurrency = this.clubService.getClubCurrency();
    this.clubId = this.clubService.getClub().id;
    this.getBookings();
    this.getClubAssets();
    this.currentUserId = this.clubService.getClub().pivot.user_id;
    this.authService.isAdmin.subscribe( result => {
      this.isAdmin = result;
    });
    this.isInitialized = true;
  }


  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'updateAssets'**************/
      if(this.isInitialized === true){
        this.getClubAssets();
        this.getBookings();
      } 
    }   

  getClubAssets() {
    this.clubAssetService.getClubAssets(this.clubId, false).subscribe(result => {
      this.clubAssets = result;

      this.adhocBookableAssets = this.clubAssets.filter( asset => {
        if(asset.booking_option === 'adhoc' || asset.booking_option === 'premade_and_adhoc'){
          return true;
        }
        return false;
      });

    });
  }

  addABooking() {
    this.getClubAssets();
    this.addNewBooking = true;
  }

  getBookings() {
    this.bookingService.getBookings(this.clubId).subscribe(result => {
      this.bookingDays = result.bookingsSoFar;
      this.bookableSlots = result.bookableSlots;
    });
  }

  getFreeTimeSlots() {
    this.bookingService.getFreeTimeSlots(this.clubId, this.clubAssetId, this.selectedDate).subscribe(result => {
      this.freeTimeSlots = result;
    });
  }

  getFreeDurationSlots() {
    this.bookingService.getFreeDurationSlots(this.clubId, this.clubAssetId, this.selectedDate, this.startTime).subscribe(result => {
      this.durationsAvailable = result;
    });
  }


  savePremadeSlotBooking(slot) {

    const selectedClubAsset = this.clubAssets.find(asset => {
      return asset.id === slot.club_asset_id;
    });
 
    const booking = {
      clubAssetId: slot.club_asset_id,
      clubAssetName: selectedClubAsset.name,
      startTime: slot.start_time,
      timeBooked: slot.duration,
      date: slot.day_of_week,
      note: '',
      clubId: this.clubId,
      type: this.bookingType,
      price: slot.price
    };

    // check if payment is required
    if (slot.price > 0) {
      const paymentModal = this.dialog.open(PayAmountComponent, {
        data: {
          currentClub: this.clubService.getClub(),
          paymentAmountSelected: booking.price,
          paymentType: 'booking',
          selectedPaymentTimeframe: 'singlePayment',
          bookingPaymentDetails : booking
        },
        id: 'payment-modal',
        maxWidth: '95vw',
        minWidth: '40vw'
      }).afterClosed().subscribe(res => {
        this.getBookings();
      });
    } else {

      this.bookingService.createBooking(booking).subscribe(result => {
        this.getBookings();
      });
    }
    this.addNewBooking = false;
  }


  saveBooking() {

    const selectedClubAsset = this.clubAssets.find(asset => {
      return asset.id === this.clubAssetId;
    });

    this.addNewBooking = false;
    const booking = {
      userId: this.currentUserId,
      clubAssetId: this.clubAssetId,
      clubAssetName: selectedClubAsset.name,
      startTime: this.startTime,
      timeBooked: this.timeBooked,
      date: this.selectedDate.toISOString(),
      note: this.note,
      clubId: this.clubId,
      type: this.bookingType
    };
    this.bookingService.createBooking(booking).subscribe(result => {
      this.getBookings();
    });
  }



  deleteBooking(booking_id) {

    this.bookingService.deleteBooking(this.clubId, booking_id).subscribe(result => {
      this.getBookings();
    });
  }
}
