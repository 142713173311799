import { Component, OnInit, Inject } from '@angular/core';
import { MemberService } from '../../../_services/member.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClubService } from '../../../_services/club.service';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit {

  memberDetails = {
    'first_name' : '',
    'surname' : '',
    'users' : [],
    'amountOustanding' : [],
    'currentMemberships' : {'membersMembershipGroups': []},
    'payments': []
  };

  constructor(
    private memberService: MemberService,
    private clubService: ClubService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    this.memberService.getMember(this.data.member.id).subscribe(result => {
      this.memberDetails = result;
    });
  }

}
