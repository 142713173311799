import { Component, OnInit } from '@angular/core';
import { LottoService } from '../../../_services/lotto.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lotto-entries',
  templateUrl: './lotto-entries.component.html',
  styleUrls: ['./lotto-entries.component.scss']
})
export class LottoEntriesComponent implements OnInit {

  constructor(
    private lottoService: LottoService,
    private route: ActivatedRoute) { }

  lottoDrawEntries;
  lottoId;

  ngOnInit() {
    // checking if the shop is members only shop or a public shop
    this.route.params.subscribe((params) => {
      this.lottoId = params['lottoId'];

      // load the lotto entries for that lottow draw
      this.getLottoEntries(this.lottoId);
    });
  }

  getLottoEntries(lottoId) {
    this.lottoDrawEntries = this.lottoService.getLottoFutureEntries(lottoId);
  }
}
