import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ContactComponent } from './contact/contact.component';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isHomepage;
  isOnboarding;
  isAdmin$: Observable<Boolean>;
  userRole$: BehaviorSubject<String>;
  selectedClubId$: Observable<any>;
  userClubs$: Observable<any>;


  constructor(private router: Router,
    public dialog: MatDialog,
    private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.isAdmin$ = this.authService.isAdmin;
    this.userRole$ = this.authService.loggedInRole;
    this.selectedClubId$ = this.authService.selectedClubId;
    this.userClubs$ = this.authService.userClubs;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        if (this.router.url === '/' ||
        this.router.url === '/#top-feature' ||
        this.router.url === '/#pricing-section' ||
        this.router.url === '/#landing-goals') {
          this.isHomepage = true;
        } else {
          this.isHomepage = false;
        }

        this.isOnboarding =  this.router.url === '/onboarding' ? true : false;
      }
    });
  }

  openFeedback() {
    const dialogRef = this.dialog.open(ContactComponent, {
      width: '450px',
      maxWidth: '95vw',
      id: 'feedback-modal'
    });
  }


  changeClub(selectedClubId) {

    const newSelectedClubId = selectedClubId.value;
    this.authService.userSelectedClubId.next(newSelectedClubId);

    // update the selected details in local storage
    this.userClubs$.subscribe(clubsList => {

      clubsList.forEach(club => {
        if (parseInt(newSelectedClubId, 10) === club.id) {
          localStorage.setItem('club', JSON.stringify(club));

          // set if admin/super admin or not
          this.authService.checkAndUpdateUserRole(JSON.parse(localStorage.getItem('club')).pivot.role);

          // refresh current page
          location.reload();
        }
      });
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
