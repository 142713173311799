import { Component, OnInit } from '@angular/core';
import { ClubService } from '../../../_services/club.service';
import { AreYouSureService } from '../../../_services/are-you-sure.service';
import { MatDialog } from '@angular/material';
import { ClubOnboardingDetailsComponent } from '../club-onboarding-details/club-onboarding-details.component';
import { MdcTabActivatedEvent } from '@angular-mdc/web';

@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.scss']
})
export class ClubsComponent implements OnInit {
  fullClubsList = [];
  activeClubsList;
  pendingClubsList;
  hiddenClubsList;
  softDeletedClubsList;
  currentlyViewingClubsList;
  currentTabIndex = 0;

  constructor(
    private clubsService: ClubService,
    private aysService: AreYouSureService,
    private matDialog: MatDialog,
    ) {
  }

  ngOnInit() {
    this.getClubs();
    this.currentlyViewingClubsList = this.activeClubsList;
  }

  getClubs() {
    this.clubsService.getClubs().subscribe(result => {
      this.fullClubsList = result.clubs;
      this.updateCurrentlyViewingList();
    });
  }

  logTab(event: MdcTabActivatedEvent): void {
    this.currentTabIndex = event.index;
    this.updateCurrentlyViewingList();
  }


  updateCurrentlyViewingList() {
    this.activeClubsList = this.fullClubsList.filter( club => !club.deleted_at && club.sales_status === 'active');
    this.pendingClubsList = this.fullClubsList.filter( club => !club.deleted_at && club.sales_status === 'pending');
    this.hiddenClubsList = this.fullClubsList.filter( club => !club.deleted_at && club.sales_status === 'hidden');
    this.softDeletedClubsList = this.fullClubsList.filter( club => club.deleted_at);

    if (this.currentTabIndex === 0) {
      this.currentlyViewingClubsList = this.activeClubsList;
    } else if (this.currentTabIndex === 1) {
      this.currentlyViewingClubsList = this.pendingClubsList;
    } else if (this.currentTabIndex === 2) {
      this.currentlyViewingClubsList = this.hiddenClubsList;
    } else {
      this.currentlyViewingClubsList = this.softDeletedClubsList;
    }
  }


  viewClubOnboardingDetails(clubUserDetails) {

    // get the user details and club admins in a modal
    this.matDialog.open(ClubOnboardingDetailsComponent, {
      data: {
        clubUserDetails
      },
      minWidth: '40vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });

  }

  restoreSoftDeleteClub(clubId) {
    this.clubsService.restoreSoftDeleteClub(clubId).subscribe(result => {
      this.getClubs();
    });
  }

  softDeleteClub(clubId) {
    this.clubsService.softDeleteClub(clubId).subscribe(result => {
      this.getClubs();
    });
  }

  hardDeleteClub(clubId) {
    this.clubsService.hardDeleteClub(clubId).subscribe(result => {
      this.getClubs();
    });
  }

  updateSalesDetails(clubDetails) {
    this.clubsService.updateClubDetails(clubDetails).subscribe(result => {
      this.updateCurrentlyViewingList();
    });
  }
}
