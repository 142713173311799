import { Component, OnInit, Inject, Optional } from '@angular/core';
import { LottoService } from '../../../_services/lotto.service';
import { ClubService } from '../../../_services/club.service';
import { PayAmountComponent } from '../pay-amount/pay-amount.component';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { MemberService } from '../../../_services/member.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentService } from '../../../_services/payment.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-play-lotto',
  templateUrl: './play-lotto.component.html',
  styleUrls: ['./play-lotto.component.scss']
})
export class PlayLottoComponent implements OnInit {

  arrayOfDrawNumbers = [];
  numDrawNumbers = 24;
  maxNumDrawNumber = 25;
  drawDayOfWeek;
  drawTimeOfDay;
  listOfNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  numDraws = 1;
  lines = [{ number1: 0, number2: 0, number3: 0, number4: 0 }];
  pricePerLine = 0;
  totalPrice = 0;
  memberDetails;
  stepper;
  validationMessage = '';
  userDetails = {firstName: '', surname: '', email: '', phoneNumber: '' };
  userDetailsError = '';
  clubCurrency = '';

  // lotto/club details
  club = { id: 0, name: '', country: ''};
  lottoEnabled;
  prizeAmount = '';
  maxNumDraws = 0;


  constructor(
    private spinner: NgxSpinnerService,
    private lottoService: LottoService,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clubService: ClubService,
    private httpClient: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }



  ngOnInit() {

    // check the route param is available first then check the injected data(for modal option)
    this.route.params.subscribe((params) => {
      let clubId;
      if (params['clubId']) {
        clubId = params['clubId'];
      } else {
        clubId = this.data.clubId;
      }

      // Check if we have a clubs details and then
      // check if the current club details are the same as the club whos lotto we are about to play
      if (this.clubService.getClub() === null || parseInt(clubId, 10) !== this.clubService.getClub().id) {

        this.clubService.getClubFromApi(clubId).subscribe(result => {
          this.club = result.club;

          if (this.club) {
            // need to set the following for stripe to work when a user is not logged in
            localStorage.setItem('club', JSON.stringify(this.club));
            this.clubCurrency = this.clubService.getClubCurrency(this.club.country);
          }
        });
      } else {

        this.club = this.clubService.getClub();
        this.clubCurrency = this.clubService.getClubCurrency(this.club.country);
      }

      // check if the club lotto is enabled
      this.lottoService.getLottoSettings(clubId).subscribe(result => {

        if (result !== {}) {
          this.lottoEnabled = result.enabled;

          this.numDrawNumbers = result.num_draw_numbers;
          this.maxNumDrawNumber = this.numDrawNumbers + 1;

          for (let i = 1; i < this.maxNumDrawNumber; i++) {
            this.arrayOfDrawNumbers.push(i);
          }

          // used for the quickpick
          this.listOfNumbers = this.arrayOfDrawNumbers.slice(0);

          this.prizeAmount = result.prize_amount;
          this.maxNumDraws = result.max_num_draws;
          this.pricePerLine = result.line_price;
          this.drawDayOfWeek = result.draw_day_of_week;
          this.drawTimeOfDay = result.draw_time_of_day;


          this.totalPrice = this.numDraws * this.pricePerLine;
        }
      });
    });
  }

  changeNumDraws() {
    this.totalPrice = this.numDraws * this.pricePerLine * this.lines.length;

    this.checkSpecialOffer();
  }

  addLine() {
    this.lines.push({ number1: 0, number2: 0, number3: 0, number4: 0 });
    this.totalPrice = this.numDraws * this.pricePerLine * this.lines.length;

    this.checkSpecialOffer();
  }

  deleteLine(index) {
    this.lines.splice(index, 1);
    this.totalPrice = this.numDraws * this.pricePerLine * this.lines.length;

    this.checkSpecialOffer();
  }

  quickPick(line) {
    // shuffle the list of lotto numbers
    this.shuffle(this.listOfNumbers);

    // take 4 numbers from the shuffled array
    const arrayOfNumbers = this.listOfNumbers.slice(0, 4);

    // sort them smallest to largest
    arrayOfNumbers.sort(function(a, b) {
      return a - b;
    });

    line.number1 = arrayOfNumbers[0];
    line.number2 = arrayOfNumbers[1];
    line.number3 = arrayOfNumbers[2];
    line.number4 = arrayOfNumbers[3];
  }

  shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }


  selectBall(event) {
    // check if there is any free spaces in the current lines
    // if so then fill the first available selected free space

    // parse the selected ball first
    const selectedBall = parseInt(event.target.textContent.trim(), 10);
    if (selectedBall > 0 && selectedBall < this.maxNumDrawNumber) {
      this.findNextFreeInputField(selectedBall);
    }
  }

  findNextFreeInputField(selectedBall) {
    for (let currentLineNum = 0; currentLineNum < this.lines.length; currentLineNum++) {

        let lineUpdated = false;
        // find the next free slot
        if (this.lines[currentLineNum].number1 === 0) {
          lineUpdated = this.updateInputField(this.lines[currentLineNum], currentLineNum, selectedBall);
        } else if (this.lines[currentLineNum].number2 === 0) {
          lineUpdated = this.updateInputField(this.lines[currentLineNum], currentLineNum, selectedBall);
        } else if (this.lines[currentLineNum].number3 === 0) {
          lineUpdated = this.updateInputField(this.lines[currentLineNum], currentLineNum, selectedBall);
        } else if (this.lines[currentLineNum].number4 === 0) {
          lineUpdated = this.updateInputField(this.lines[currentLineNum], currentLineNum, selectedBall);
        }

        // if we updated an input field then we should not update another line
        if (lineUpdated === true) {
          break;
        }

      }
  }

  updateInputField(line, currentLineNum, selectedBall) {

    // check that the number isn't already used on this line
    if ( !Object.values(line).includes(selectedBall) ) {

      if (line.number1 === 0) {
        line.number1 = selectedBall;
        this.sortLineInCorrectOrder(line, currentLineNum);
        return true;
      } else if (line.number2 === 0) {
        line.number2 = selectedBall;
        this.sortLineInCorrectOrder(line, currentLineNum);
        return true;
      } else if (line.number3 === 0) {
        line.number3 = selectedBall;
        this.sortLineInCorrectOrder(line, currentLineNum);
        return true;
      } else if (line.number4 === 0) {
        line.number4 = selectedBall;
        this.sortLineInCorrectOrder(line, currentLineNum);
        return true;
      }

    }
  }

  // sort the line in the correct order (smallest to largest)
  sortLineInCorrectOrder(unorderedLine, currentLineNum) {
    const line = { number1: 0, number2: 0, number3: 0, number4: 0 };
    const orderedArray = [];
    Object.values(unorderedLine).forEach(item => {
      orderedArray.push(item);
    });

    orderedArray.sort(function(a, b) {
      return a - b;
    });

    this.lines[currentLineNum].number1 = orderedArray[0];
    this.lines[currentLineNum].number2 = orderedArray[1];
    this.lines[currentLineNum].number3 = orderedArray[2];
    this.lines[currentLineNum].number4 = orderedArray[3];
  }


  validateAndContinue(stepper) {
    this.validationMessage = '';

    // check all positions & all lines are filled with a valid number
    this.lines.forEach( line => {
      Object.values(line).forEach( number => {
        if (number < 1) {
          this.validationMessage = 'You cannot have a number less than 1 in a lotto line!';
        } else if (number > this.numDrawNumbers) {
          this.validationMessage = 'You cannot have a number greater than ' + this.numDrawNumbers + ' in a lotto line!';
        }
      });

      if (this.hasDuplicates(line)) {
        this.validationMessage = 'You cannot have the same number more than once in a lotto line!';
      }
    });

    if (this.validationMessage === '') {
      stepper.selected.completed = true;
      stepper.next();
    }
  }

  hasDuplicates<T>(line): boolean {

    const arr = [];
    arr.push(line.number1);
    arr.push(line.number2);
    arr.push(line.number3);
    arr.push(line.number4);

    return new Set(arr).size < arr.length;
  }

  // called each time a number in a line changes manually
  validateLottoNumberField(event, line, lineNum) {
    if (Number(event.target.value) === NaN  || event.target.value === 'e') {
      event.target.value = 0;
    }
    this.sortLineInCorrectOrder(line, lineNum);
  }

  payForLotto(stepper) {

    // first check that the user name/email/phone is recorded
    const regx = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
    this.userDetailsError = '';
    if (this.userDetails.firstName === '') {
      this.userDetailsError = 'The first name field cannot be empty.';
    } else if (this.userDetails.surname === '') {
      this.userDetailsError = 'The surname field cannot be empty.';
    } else if (this.userDetails.email === '') {
      this.userDetailsError = 'The email field cannot be empty.';
    } else if (!this.userDetails.email.match(regx)){
      this.userDetailsError = 'Email must be a valid email.';
    }else if (this.userDetails.phoneNumber === '') {
      this.userDetailsError = 'The phone number field cannot be empty.';
    } else {

      if (this.data && this.data.admin) {
        this.logCashPayment(stepper);
      } else {
        this.payWithCard(stepper);
      }

    }
  }

  logCashPayment(stepper) {
    const cashPaymentDetails = {
      cashPaymentType: 'lottoPayment',
      clubId: this.club.id,
      cashAmount: this.totalPrice,
      metaData: {
        lottoPaymentDetails: {
          userDetails: this.userDetails,
          numDraws: this.numDraws,
          lottoLines: this.lines
        }
      }
    };

    const paymentService = new PaymentService(this.httpClient);
    paymentService.logCashPayment(cashPaymentDetails).subscribe(result => {
      if (result.status === 'OK') {
        stepper.selected.completed = true;
        stepper.next();
      }
    });
  }


  payWithCard(stepper) {
    const paymentModal = this.dialog.open(PayAmountComponent, {
      data: {
        currentClub: this.clubService.getClub(),
        paymentAmountSelected: this.totalPrice,
        paymentType: 'lotto',
        selectedPaymentTimeframe: 'singlePayment',
        lottoPaymentDetails: {
          userDetails: this.userDetails,
          numDraws: this.numDraws,
          lottoLines: this.lines
        }
      },
      id: 'payment-modal',
      maxWidth: '95vw',
      minWidth: '40vw'

    });

    paymentModal.afterClosed().subscribe(result => {

      if (result === 'paymentSuccess') {
        // go to payment confirmation and disable the ability to pay again.
        stepper.selected.completed = true;
        stepper.next();
      }
    });
  }

  checkSpecialOffer() {
    if (this.club.id === 2 && this.lines.length % 3 === 0) {
      const multiples = this.lines.length / 3;
      this.totalPrice = this.numDraws * (this.pricePerLine * 2.5 * multiples);
    }
  }
}
