import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ClubService } from './club.service';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private clubService: ClubService) { }

  getMemberships(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(this.apiUrl + '/memberships?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getMembership(membershipId): Observable<any> {

    return this.http.get(this.apiUrl + '/memberships/' + membershipId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  createMembership(membershipDetails,  uploadedMembers?): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.post(this.apiUrl + '/memberships',
      {
        membershipDetails,
        uploadedMembers,
        clubId
      }
      ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteMembership(membershipId): Observable<any> {
    return this.http.delete(this.apiUrl + '/memberships/' + membershipId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateMembership(membershipDetails): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.put(this.apiUrl + '/memberships/' + membershipDetails.id, {clubId, membershipDetails})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  sendAllMembershipReminder(clubId) {
    return this.http.post(this.apiUrl + '/all-membership-reminders', {
      clubId
    }).pipe(
        map((response: Response) => {
            return response;
    }));
  }

  postMembershipReminder(details) {
    return this.http.post(this.apiUrl + '/membership-reminder', {
        clubId: details.clubId,
        membershipId: details.membershipId
    }).pipe(
        map((response: Response) => {
            return response;
        }));
  }

  postSingleMembershipReminder(details) {
    return this.http.post(this.apiUrl + '/single-membership-reminder', {
        clubId: details.clubId,
        membershipId: details.membershipId,
        memberId: details.memberId
    }).pipe(
        map((response: Response) => {
            return response;
        }));
  }

  getMembershipForms(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(this.apiUrl + '/membership-forms?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getMembershipForm(membershipFormId): Observable<any> {
    return this.http.get(this.apiUrl + '/membership-forms/' + membershipFormId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getMembershipFormWithPrevAnswers(membershipFormId, memberId): Observable<any> {
    return this.http.get(this.apiUrl + '/membership-form-with-any-answers/' + membershipFormId + '?memberId=' + memberId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  createMembershipForm(membershipFormDetails): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.post(this.apiUrl + '/membership-forms',
      {
        membershipFormDetails,
        clubId
      }
      ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateMembershipForm(membershipFormDetails): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.put(this.apiUrl + '/membership-forms/' + membershipFormDetails.id, {clubId, membershipFormDetails})
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteMembershipForm(membershipId): Observable<any> {
    return this.http.delete(this.apiUrl + '/membership-forms/' + membershipId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getAnsweredMembershipForms(memberId): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.get(
        this.apiUrl + '/answered-membership-forms?clubId=' + clubId + '&memberId=' + memberId
      ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getAnsweredMembershipForm(answeredMembershipFormId): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.get(this.apiUrl + '/answered-membership-forms/' + answeredMembershipFormId + '?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  saveMembershipForms(membersMemberships): Observable<any> {
    const clubId = this.clubService.getClub().id;

    return this.http.post(this.apiUrl + '/answered-membership-forms', {
      membersMemberships,
      clubId
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }

  saveMembershipForm(memberId, membershipGroupName, membershipForm): Observable<any> {
    const clubId = this.clubService.getClub().id;
    const adminFlow = true;

    return this.http.post(this.apiUrl + '/answered-membership-forms', {
      adminFlow,
      memberId,
      membershipGroupName,
      membershipForm,
      clubId
    })
      .pipe(
        map((response: Response) => {
          return response;
        })
    );
  }





  /****************************   Membership Discounts  *********************/
  getMembershipDiscounts(): Observable<any> {
    const clubId = this.clubService.getClub().id;
    return this.http.get(this.apiUrl + '/membership-discounts?clubId=' + clubId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getMembershipDiscount(membershipDiscountId): Observable<any> {

    return this.http.get(this.apiUrl + '/memberships/' + membershipDiscountId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  createMembershipDiscount(membershipDiscountDetails): Observable<any> {
    const clubId = this.clubService.getClub().id;
    membershipDiscountDetails.append('clubId', clubId);

    return this.http.post(this.apiUrl + '/membership-discounts', membershipDiscountDetails
      ).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  deleteMembershipDiscount(membershipDiscountId): Observable<any> {
    return this.http.delete(this.apiUrl + '/membership-discounts/' + membershipDiscountId)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  updateMembershipDiscount(itemId, membershipDiscountDetails): Observable<any> {
    const clubId = this.clubService.getClub().id;

    membershipDiscountDetails.append('clubId', clubId);

    return this.http.post(this.apiUrl + '/membership-discounts/' + itemId, membershipDiscountDetails
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  /******************** Membership Payment Options ***********/
  getMembershipPaymentOptions(clubId) {
    return this.http.get(this.apiUrl + '/outstanding-membership-payment-options/' + clubId)
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


  requestMembershipCancellation(selectedMember, selectedMembership, message) {
    const clubId = this.clubService.getClub().id;

    const membershipDiscountDetails = {
      clubId,
      memberName: selectedMember,
      membershipName: selectedMembership,
      memberMessage: message
    }
    return this.http.post(this.apiUrl + '/membership-cancellation-request', membershipDiscountDetails
    )
    .pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}
